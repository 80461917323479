import axios from "axios";

export const handleSaveImage = async ({ url, file, onSuccess = () => null, onFailed = () => null }) => {

    const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url,
        headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': 'image/png'
        },
        data: file
    };

    try {
        const response = await axios(config)
        onSuccess(response)
        return response
    } catch (error) {
        onFailed(error)
        return error
    }


}