const locationDetail = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_location($locationId: String!) {
      organizer_location(locationId: $locationId) {
        _id
        city
        district
        country
        address {
          description
          title
        }
        lat
        long
        parking
        status
        createdBy {
          _id
          description
          displayImageUrl
          displayName
          role
        }
        updatedBy {
          _id
          description
          displayImageUrl
          displayName
          role
        }
        rejectionDetail
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "locationId": locationId
    }
  })

  return data

}

const allLocations = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_locations($filter: FilterLocationByOrganizerInput, $pagination: Pagination) {
      organizer_locations(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          city
          district
          country
          address {
            title
            description
          }
          lat
          long
          parking
          status
          # createdBy {
          #   _id
          #   displayName
          #   displayImageUrl
          #   description
          #   role
          # }
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          rejectionDetail
          createdAt
          updatedAt
        }
      }
    }
              `,
    variables: {
      "filter": {
        "byCoordinates": {
          "lat": null,
          "long": null,
          "radius": null
        },
        "city": null,
        "createdByCurrentUser": true,
        "district": null,
        "parking": null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const isUpdatable = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    query Query($locationId: String!) {
      user_isLocationUpdatable(locationId: $locationId)
    }
    `,
    variables: {
      "locationId": locationId
    }
  })

  return data
}

export const locationsForOrganizer = {
  locationDetail,
  allLocations,
  isUpdatable
}