/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import { reduxFunc } from 'src/hophop/redux';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';


// ----------------------------------------------------------------------

// navConfig={navConfig}
export default function DashboardLayout({ children, navConfig }) {

  const dispatch = useDispatch()
  const { accessToken, userRole } = useSelector(state => state.auth)

  useEffect(() => {
    if (userRole === "organizer") dispatch(reduxFunc.paymentFunc.fetchPaymentUserInfoFunc({ accessToken }))
  }, [accessToken, dispatch, userRole])

  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const nav = useBoolean();
  const isHorizontal = settings.themeLayout === 'horizontal';
  const isMini = settings.themeLayout === 'mini';
  const renderNavMini = <NavMini navConfig={navConfig} />;
  const renderHorizontal = <NavHorizontal />;
  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} navConfig={navConfig} />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} navConfig={navConfig} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main navConfig={navConfig}>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} navConfig={navConfig} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main navConfig={navConfig}>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} navConfig={navConfig} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main navConfig={navConfig}>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
