import PropTypes from 'prop-types';
import { Box, Link, Card, Stack, MenuItem, IconButton, ListItemText } from '@mui/material';

import Image from 'src/components/image';
import { useRouter } from 'src/routes/hook';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { companionshipsForListing, parkingForLocation, timeMapping } from 'src/hophop/utils/_enums';



const detailLength = 50
export default function BlogCard({ post: tour, handleDelete }) {


  const router = useRouter();
  const popover = usePopover();

  const handleUpdate = async () => {
    popover.onClose();
    router.push(`/blog/edit/${tour?._id}`);
  }

  const renderPrice = (
    <Stack
      direction="row"
      gap={1}
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        position: 'absolute',
        backgroundColor: tour?.status === "passive" ? "#ffffff99" : "",
        right: 8,
        p: 1,
        borderRadius: 1,
        flexWrap: 'wrap',
      }}>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          borderRadius: 1,
          bgcolor: 'grey.800',
          p: '2px 6px 2px 4px',
          color: 'common.white',
          typography: 'subtitle2',
        }}
      >
        {tour?.listing?.category.join(', ')}
      </Stack>

    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }} >

        {renderPrice}

        <IconButton
          onClick={popover.onOpen}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 9,
            backgroundColor: '#ffffff93'
          }}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>

        <Image alt={tour?.title} src={tour?.listing?.coverImage} sx={{ borderRadius: 1, height: 164, width: 1 }} />
      </Stack>
      {/* <Stack spacing={0.5}>
        <Image alt={tour?.title} src={tour?.images?.[0]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
        <Image alt={tour?.title} src={tour?.images?.[1]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
      </Stack> */}
    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      primary={`Oluşturulma tarihi : ${tour?.createdAt ? (new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.createdAt))) : ""}`}
      secondary={
        <Link component={RouterLink} href={`/aktiviteler/${tour?._id}`} color="inherit">
          {tour?.listing?.title}
        </Link>
      }
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,
        noWrap: true,
        component: 'span',
        color: 'text.primary',
        typography: 'subtitle1',
      }}
    />
  );



  const renderInfo = (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
        flexWrap: "wrap"
      }}
    >


      {

        [
          {
            label: tour?.listing?.companionship ? companionshipsForListing?.find(item => item?.value === tour?.listing?.companionship)?.name : null,
            icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
          },
          {
            label: `${tour?.schedule?.openHour ? timeMapping?.find(item => item.value === tour?.schedule?.openHour)?.name : ""
              }${(tour?.schedule?.openHour && tour?.schedule?.closeHour) ? " - " : ""
              }${tour?.schedule?.closeHour ? timeMapping?.find(item => item.value === tour?.schedule?.closeHour)?.name : ""
              }`,
            icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
          },
          {
            label: `${tour?.location?.address?.title ?? ""
              }${tour?.location?.city ? " -" : ""
              }${tour?.location?.city ?? ""
              }${tour?.location?.district ? " -" : ""
              }${tour?.location?.district ?? ""
              }`,
            icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
          },
          {
            label: parkingForLocation?.find(item => item.value === tour?.location?.parking)?.name,
            icon: <Iconify icon="mdi:car" sx={{ color: 'info.main' }} />,
          },
          {
            label: tour?.location?.address?.description,
            icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
          },

        ].map((item, index) => {

          if (!item?.label) return null

          return (
            <div key={index}>

              {item?.label && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    typography: 'body2',
                    textTransform: "capitalize",
                    flex: '1 1 200px'
                  }}
                >
                  {item?.label && item?.icon}
                  {item?.label}
                </Stack>)}
            </div>
          )

        })
      }


    </Stack>
  );

  return (
    <>
      <Card
        sx={{
          ...(tour?.status === "passive" && { backgroundColor: '#f1f1f1' }),
          transitionDuration: '0,3',
          boxShadow: '1px 1px 10px 0px #d9d9d9',
          flex: 1,
        }}

      >
        {renderImages}

        {renderTexts}

        {renderInfo}

        <Stack spacing={1} sx={{ p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5), }} >
          {tour?.listing?.description?.length ? (<Box
            sx={{
              fontSize: '12px',
              backgroundColor: '#f1f1f1',
              p: 2,
              borderRadius: 1,
              flex: 1,
            }}
          >
            {tour?.listing?.description?.slice(0, detailLength)}
            {tour?.listing?.description?.length > detailLength && " ..."}
          </Box>) : null}


          {tour?.additionalDetails?.length ? (<Box
            sx={{
              fontSize: '12px',
              backgroundColor: '#f1f1f1',
              p: 2,
              borderRadius: 1,
              flex: 1,
            }}
          >
            {tour?.additionalDetails?.slice(0, detailLength)}
            {tour?.additionalDetails?.length > detailLength && " ..."}
          </Box>) : null}
        </Stack>
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ width: 140 }}
      >
        {/* <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths?.dashboard?.post?.details(post?.title));
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem> */}

        <MenuItem
          onClick={handleUpdate}
        >
          <Iconify icon="solar:pen-bold" />
          Düzenle
        </MenuItem>

        <MenuItem
          // disabled
          onClick={() => {
            handleDelete({ id: tour?._id })
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Sil
        </MenuItem>
      </CustomPopover>
    </>
  );
}

BlogCard.propTypes = {
  handleDelete: PropTypes.func,
  post: PropTypes.object,
};
