const allReservations = ({ variables, activityId }) => {

  const data = JSON.stringify({
    query: `
        query Moderator_reservations($filter: FilterReservationByModeratorInput, $pagination: Pagination) {
            moderator_reservations(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                #cancellationInformation {
                #  _id
                #  description
                #  at
                #  createdAt
                #  updatedAt
                #}
                createdAt
                imageUrl
                parent {
                  _id
                  displayName
                  displayImageUrl
                  description
                  role
                }
                #rejectionInformation {
                #  _id
                #  description
                #  at
                #  createdAt
                #  updatedAt
                #}
                status
                updatedAt
                activity {
                  _id
                }
              }
            }
          }
        `,
    variables: {
      "filter": {
        "activityId": activityId ?? null,
        "dateInterval": {
          "from": null,
          "to": null
        },
        "status": variables?.filter?.status ?? null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
      }
    }
  })

  return data
}

const reservationDetail = ({ reservationId }) => {

  const data = JSON.stringify({
    query: `
        
        `,
    variables: {

    }
  })

  return data
}


const allReservationsId = ({ variables, activityId }) => {

  const data = JSON.stringify({
    query: `
        query Moderator_reservations($filter: FilterReservationByModeratorInput, $pagination: Pagination) {
            moderator_reservations(filter: $filter, pagination: $pagination) {
              count
              data {
                parent {
                  _id
                }
              }
            }
          }
        `,
    variables: {
      "filter": {
        "activityId": activityId ?? null,
        "dateInterval": {
          "from": null,
          "to": null
        },
        "status": null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
      }
    }
  })

  return data
}

export const reservationsForModerator = {
  allReservations,
  reservationDetail,
  allReservationsId,
}