/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useCallback, useState } from 'react'

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import {
    useTable,
    emptyRows,
    TableNoData,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux'
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import SeoIllustration from 'src/assets/illustrations/seo-illustration';
import AppWelcome from 'src/sections/overview/app/app-welcome';

import { Stack, TextField } from '@mui/material';
import { ConfirmDialog } from 'src/components/custom-dialog';
import UserTableRow from './customers-table-row';

const TABLE_HEAD = [
    // { id: '1', label: '' },
    { id: 'name', label: 'İsim', width: 250 },
    { id: 'description', label: 'Açıklama' },
    { id: 'role', label: 'Rolü', width: 120 },
    // { id: 'park', label: 'Park', width: 100 },
    { id: '', width: 88 },
];



const Customers = () => {

    const table = useTable();
    const confirm = useBoolean();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { customersForModerator: list } = useSelector(state => state.customer)
    const [variables, setVariables] = useState({
        "filter": {
            "activityId": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 10
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.customerFunc.fetchCustomersForModeratorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])


    const handleChangePage = (a, b) => {

        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))

        table.onChangePage(a, b)

    }


    const messagePopup = useBoolean();
    const notificationPopup = useBoolean();

    const [message, setMessage] = useState("")
    const [notifcation, setNotification] = useState("")

    const handleSendGroupMessage = useCallback(async () => {

        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
                    activityId: "",
                    attachments: [],
                    content: message,
                    parentIds: table.selected?.map(item => ({ "_id": item }))
                }),
                accessToken,
                url: "/activity/graphql",
            })

            if (response?.data?.data?.moderator_createMessages?.success) {
                enqueueSnackbar('Mesajınız gönderildi!');
                setMessage("")
            } else {
                enqueueSnackbar('Mesaj iletilemedi!', {
                    variant: "error"
                })
            }

            // if (response?.data?.errors) {

            //   response?.data?.errors?.forEach((item, index) => {
            //     if (item?.message === "There are activities are referenced to the location") {
            //       enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
            //         variant: "error"
            //       })
            //     }
            //   })

            // }

        } catch (error) {
            console.error({ error })
        }

    }, [accessToken, handleFetchData, message, table.selected]);



    const handleSendGroupNotification = useCallback(async (id) => {

        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({ locationId: id }),
                accessToken,
                url: "/activity/graphql",
            })

            if (response?.data?.data?.moderator_removeLocation?.success) {
                enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
                dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "There are activities are referenced to the location") {
                        enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
                            variant: "error"
                        })
                    }
                })
            }

        } catch (error) {
            console.error({ error })
        }

    }, [accessToken, dispatch, handleFetchData]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Müşteriler"
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Card>

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>

                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table?.selected?.length}
                        rowCount={list?.data?.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                list?.data.map((row) => row.id)
                            )
                        }
                        action={
                            <>
                                <Tooltip title="Toplu Mesaj">
                                    <IconButton color="primary" onClick={messagePopup.onTrue}>
                                        <Iconify icon="mingcute:send-fill" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Toplu Bildirim">
                                    <IconButton color="secondary" onClick={notificationPopup.onTrue}>
                                        <Iconify icon="ion:notifcations" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    />

                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>

                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={list?.data?.length}
                                numSelected={table?.selected?.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {
                                    list?.data?.map((row) => (
                                        <UserTableRow
                                            key={row?._id}
                                            row={row}
                                            selected={table.selected.includes(row?._id)}
                                            onSelectRow={() => table.onSelectRow(row?._id)}
                                        />
                                    ))
                                }

                                <TableNoData notFound={!list?.count} />
                            </TableBody>
                        </Table>
                    </Scrollbar>

                </TableContainer>

                <TablePaginationCustom
                    count={list?.count ?? 0}
                    page={table.page}
                    rowsPerPage={variables.pagination.pageSize}

                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={table.onChangeRowsPerPage}

                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />

            </Card>


            <>
                <ConfirmDialog
                    open={messagePopup.value}
                    onClose={messagePopup.onFalse}
                    title="Toplu mesaj gönder"
                    // content="Reddetmek sebebi?"
                    noCanselButton
                    action={
                        <Stack direction="column" spacing={3}>
                            <TextField
                                multiline
                                name="message"
                                label="Toplu mesaj"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                sx={{
                                    width: '100%',
                                    mt: 2
                                }}
                            />
                            <Stack direction="row" spacing={1}
                                sx={{
                                    justifyContent: 'end'
                                }}
                            >
                                <Button
                                    disabled={message === ""}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleSendGroupMessage()
                                        messagePopup.onFalse()
                                    }}
                                >
                                    Gönder
                                </Button>

                                <Button variant="outlined" color="inherit" onClick={messagePopup.onFalse}>
                                    iptal
                                </Button>
                            </Stack>
                        </Stack>
                    }
                />

                <ConfirmDialog
                    open={notificationPopup.value}
                    onClose={notificationPopup.onFalse}
                    title="Toplu Bildirim gönder"
                    // content="Reddetmek sebebi?"
                    noCanselButton
                    action={
                        <Stack direction="column" spacing={3}>
                            <TextField
                                name="notification"
                                label="Toplu bildirim"
                                value={notifcation}
                                onChange={(e) => setNotification(e.target.value)}
                                sx={{
                                    width: '100%',
                                    mt: 2
                                }}
                            />
                            <Stack direction="row" spacing={1}
                                sx={{
                                    justifyContent: 'end'
                                }}
                            >
                                <Button
                                    disabled={notifcation === ""}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        handleSendGroupNotification()
                                        notificationPopup.onFalse()
                                    }}
                                >
                                    Gönder
                                </Button>

                                <Button variant="outlined" color="inherit" onClick={notificationPopup.onFalse}>
                                    iptal
                                </Button>
                            </Stack>
                        </Stack>
                    }
                />
            </>

        </Container>
    )
}

export default Customers