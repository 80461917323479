/* eslint-disable arrow-body-style */
import {
    Card,
    Stack,
    Table,
    Button,
    Tooltip,
    Container,
    TableBody,
    IconButton,
    TableContainer,
    InputAdornment,
} from '@mui/material'
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux'

import {
    useTable,
    TableNoData,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { reduxFunc } from 'src/hophop/redux'
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSettingsContext } from 'src/components/settings';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import ModeratorsTableRow from './ModeratorsTableRow';


const TABLE_HEAD = [
    { id: 'name', label: 'İsim' },
    { id: 'time', label: 'Kayıt Tarihi' },
    { id: 'address', label: 'Adres', width: 220 },
    { id: 'phone', label: 'Telefon numarası' },
    { id: 'status', label: "#" },
];


const Moderators = () => {

    const table = useTable();
    const confirm = useBoolean();
    const password = useBoolean();
    const dispatch = useDispatch()
    const newUserPopup = useBoolean();
    const settings = useSettingsContext();
    const { accessToken } = useSelector(state => state.auth)
    const { allUser: list } = useSelector(state => state.user)
    const [variables, setVariables] = useState({
        "filter": {
            "role": "moderator",
        },
        "pagination": {
            "page": 1,
            "pageSize": 5
        }
    })

    useEffect(() => {
        dispatch(reduxFunc.userFunc.fetchAllUserInfoFunc({ accessToken, variables }))
    }, [accessToken, dispatch, variables])


    const handleChangePage = (a, b) => {
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))
        table.onChangePage(a, b)
    }

    const RegisterSchema = Yup.object().shape({
        email: Yup.string("").required('Gerekli').email('Geçerli bir email adresi giriniz'),
        password: Yup.string()
            // .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
            // .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
            // .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
            // .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
            .min(4, "En az 4 karakter içermeli")
            .required('Gerekli'),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Şifreler eşleşmeli!'),
    });

    const defaultValues = {
        email: '',
        password: '',
        passwordConfirm: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const { reset, formState, watch } = methods;

    const { dirtyFields } = formState
    const values = watch()

    const handleCreateModerator = async () => {

        try {

            const axiosConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/auth/auth/registerByAdmin`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`

                    // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NGYzMDcyZjNlZDJlNmFhOTc3MjM3YmEiLCJyb2xlIjoiYWRtaW4iLCJpc3MiOiI8and0LWtleT4iLCJpYXQiOjE2OTQ0MjE4NTYsImV4cCI6MTY5ODAyMTg1Nn0.9k3-6zwSz37MQZtrxzZvtOEtizl6-9HZyeGi-F2QCs8'
                },
                data: {
                    "email": values?.email,
                    "password": values?.password,
                    "role": "moderator"
                }
            };

            const response = await axios.request(axiosConfig)

            if (response?.response?.data?.errors?.length > 0) {
                enqueueSnackbar("Başka bir email deneyiniz ya da daha sonra tekrar deneyiniz!", {
                    variant: 'error'
                })
            }

            if (response?.data?.isSuccess) {
                newUserPopup.onFalse()
                enqueueSnackbar("Kayıt başarılı!")
                dispatch(reduxFunc.userFunc.fetchAllUserInfoFunc({ accessToken, variables }))
                reset();
            } else {
                enqueueSnackbar("Hatalı bilgi, kayıt yapılamadı!", {
                    variant: "error"
                })
            }

        } catch (error) {
            console.error(error);
            if (error?.response?.data?.errors?.length > 0) {
                error?.response?.data?.errors?.forEach((item, index) => {

                    if (item?.msg === "Email already in use") {
                        enqueueSnackbar("Kayıtlı email, başka bir email deneyiniz!", {
                            variant: "error"
                        })
                    } else {
                        enqueueSnackbar("Birşeyler yanlış gitti!", {
                            variant: "error"
                        })
                    }
                })
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", {
                    variant: "error"
                })
            }
            // reset();

        }

    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Moderatörler"
                action={
                    <Button
                        onClick={newUserPopup.onTrue}
                        variant="contained"
                    // startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Yeni Moderator
                    </Button>
                }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Card>

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>

                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table?.selected?.length}
                        rowCount={list?.data?.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                list?.data.map((row) => row.id)
                            )
                        }
                        action={
                            <Tooltip title="Delete">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        }
                    />

                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={list?.data?.length}
                                numSelected={table?.selected?.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {
                                    list?.data?.map((row) => (
                                        <ModeratorsTableRow
                                            row={row}
                                            key={row?._id}
                                            selected={table.selected.includes(row?._id)}
                                            onSelectRow={() => table.onSelectRow(row?._id)}
                                        />
                                    ))
                                }
                                <TableNoData notFound={list?.count === 0} />
                            </TableBody>
                        </Table>
                    </Scrollbar>

                </TableContainer>

                <TablePaginationCustom
                    count={list?.count ?? 0}
                    page={table.page}
                    rowsPerPage={variables.pagination.pageSize}
                    onPageChange={handleChangePage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />

            </Card>


            <ConfirmDialog
                open={newUserPopup.value}
                onClose={newUserPopup.onFalse}
                title="Yeni Moderator"
                noCanselButton
                action={
                    <FormProvider methods={methods}>

                        <Stack direction="column" spacing={3}>

                            <RHFTextField type="email" name="email" label="Email adresi" sx={{ mt: 1 }} />

                            <RHFTextField
                                name="password"
                                label="Şifre"
                                type={password.value ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={password.onToggle} edge="end">
                                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Stack direction="row" spacing={1}
                                sx={{
                                    justifyContent: 'end'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        handleCreateModerator()
                                    }}
                                    disabled={
                                        !dirtyFields.email ||
                                        !dirtyFields.password
                                    }
                                >
                                    Oluştur
                                </Button>

                                <Button variant="outlined" color="inherit" onClick={newUserPopup.onFalse}>
                                    iptal
                                </Button>
                            </Stack>

                        </Stack>
                    </FormProvider>
                }
            />

        </Container>
    )
}

export default Moderators