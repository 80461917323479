const createMessage = ({ content, attachments, activityId, parentIds }) => {

    const data = JSON.stringify({
        query: `
        mutation Organizer_createMessages($activityId: String!, $messageInput: MessageInput!, $parentIds: [EntityInput]!) {
            organizer_createMessages(activityId: $activityId, messageInput: $messageInput, parentIds: $parentIds) {
              success
            }
          }
        `,
        variables: {
            "activityId": activityId,
            "messageInput": {
                "attachments": attachments,
                "content": content
            },
            "parentIds": parentIds
        }


    })

    return data

}

export const messagesForOrganizer = {
    createMessage,
}