/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
// import IconButton from '@mui/material/IconButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
// import { fDate } from 'src/utils/format-time';
// import { fShortenNumber } from 'src/utils/format-number';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ageIntervalsForListing, companionshipsForListing, formatsForListing } from 'src/hophop/utils/_enums';
import { Chip, Skeleton } from '@mui/material';
// import Chip from 'src/theme/overrides/components/chip';
// import moment from 'moment';

// ----------------------------------------------------------------------

const CreateListingCard = ({ post }) => {

  const popover = usePopover();

  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const format = formatsForListing?.find(item => item?.value === post?.format)?.name


  return (
    <>
      <Stack component={Card} direction="row">
        <Stack
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
            flex: 1,
          }}
        >

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            {
              format ? (
                <Label variant="soft" color={(post?.format === 'online' && 'info') || (post?.format === 'prerecorded' && 'warning') || 'primary'}>
                  {format}
                </Label>
              ) : (
                <Skeleton variant="text" width={50} height={30} />
              )
            }

            <Box component="span" sx={{ typography: 'caption', color: 'text.disabled' }}>
              {
                new Intl.DateTimeFormat("tr", {
                  dateStyle: "medium"
                }).format(Date.now())
              }
            </Box>
          </Stack>

          <Stack spacing={1} flexGrow={1}>
            {
              post.title ? (
                <TextMaxLine variant="subtitle2" line={2}>
                  {post?.title}
                </TextMaxLine>
              ) : (
                <Skeleton variant="text" width={100} height={20} />
              )
            }

            {
              post.description ? (
                <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }}>
                  {post?.description}
                </TextMaxLine>
              ) : (
                <Skeleton variant="rounded" width={200} height={60} />
              )
            }

          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {
              post?.category ? (
                <TextMaxLine variant="body2">
                  Kategori : {post?.category}
                </TextMaxLine>
              ) : (
                <Skeleton variant="text" width={200} height={20} />
              )
            }
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {
              post?.ageInterval?.length ? (

                <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                  <TextMaxLine variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    Yaş aralığı :
                  </TextMaxLine>
                  {
                    post?.ageInterval?.map((option, index) => (
                      <Chip
                        // {...getTagProps({ index })}
                        key={option?.value}
                        label={option?.name}
                        size="small"
                        color="info"
                        variant="soft"
                      />
                    ))
                  }
                </Stack>
              ) : (
                <Skeleton variant="text" width={200} height={20} />
              )
            }
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {
              post?.companionship ? (
                <TextMaxLine variant="body2">
                  Aile katılımı : {companionshipsForListing?.find(item => item?.value === post?.companionship)?.name}
                </TextMaxLine>
              ) : (
                <Skeleton variant="text" width={200} height={20} />
              )
            }
          </Stack>

          {/* <Stack direction="row" alignItems="center">
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>

            <Stack
              spacing={1.5}
              flexGrow={1}
              direction="row"
              justifyContent="flex-end"
              sx={{
                typography: 'caption',
                color: 'text.disabled',
              }}
            >
              <Stack direction="row" alignItems="center">
                <Iconify icon="eva:message-circle-fill" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalComments)}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Iconify icon="solar:eye-bold" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalViews)}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalShares)}
              </Stack>
            </Stack>
          </Stack> */}
        </Stack>

        {
          mdUp
          // && (post?.coverImage?.preview ?? post?.coverImage) 
          && (
            <Box sx={{ width: 180, height: 240, position: 'relative', flexShrink: 0, p: 1 }}>
              {/* <Avatar
                alt={post?.author?.name}
                src={post?.author?.avatarUrl}
                sx={{ position: 'absolute', top: 16, right: 16, zIndex: 9 }}
              /> */}
              {
                (post?.coverImage?.preview ?? post?.coverImage) ? (
                  <Image alt={post?.title} src={post?.coverImage?.preview ?? post?.coverImage} sx={{ height: 1, borderRadius: 1.5 }} />
                ) : (
                  <Skeleton variant="rounded" width={160} height={220} />
                )
              }
            </Box>
          )}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths?.dashboard?.post?.details(post?.title));
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths?.dashboard?.post?.edit(post?.title));
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}

CreateListingCard.propTypes = {
  post: PropTypes.shape({
    author: PropTypes.object,
    coverUrl: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    publish: PropTypes.string,
    title: PropTypes.string,
    totalComments: PropTypes.number,
    totalShares: PropTypes.number,
    totalViews: PropTypes.number,
  }),
};


export default CreateListingCard 