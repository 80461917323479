import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography } from '@mui/material';
// routes
import CompactLayout from 'src/layouts/compact/layout';
// import { PATH_AUTH } from '../../../../routes/paths';
// components
import Iconify from '../../../../components/iconify';
// sections
// import AuthVerifyCodeForm from '../../../sections/auth/AuthVerifyCodeForm';
// assets
import { EmailInboxIcon } from '../../../../assets/icons';
// import CompactLayout from '../../../layouts/compact/CompactLayout';
import ClassicVerifyView from './classic-verify-view';

// ----------------------------------------------------------------------

export default function VerifyCodePage() {
  return (
    <CompactLayout>
      <Helmet>
        <title> Verify Code </title>
      </Helmet>

      <EmailInboxIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Lütfen email adresinizi kontrol ediniz!
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        {/* We have emailed a 6-digit confirmation code to acb@domain, please enter the code in below
        box to verify your email. */}

        Email hesabınıza 6 haneli onaylama kodu gönderdik.
        Lütfen email adresinizi onaylamak için,
        gelen kodu giriniz.
      </Typography>

      <ClassicVerifyView />

      <Typography variant="body2" sx={{ my: 3 }}>
        Kod gelmedi mi? &nbsp;
        <Link variant="subtitle2">Tekrar gönder</Link>
      </Typography>

      <Link
        component={RouterLink}
        to="/auth/login"
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Giriş sayfasına git
      </Link>
    </CompactLayout>
  );
}
