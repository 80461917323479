import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { ButtonBase, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import useFetchData from 'src/hophop/hooks/_useFetchData';
//
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function NotificationsPopover() {


  const dispatch = useDispatch()

  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { handleFetchData } = useFetchData()
  const [currentTab, setCurrentTab] = useState('all');
  const { accessToken } = useSelector(state => state.auth)
  const { notifications, loading } = useSelector(state => state.notification)
  const [variables, setVariables] = useState({
    "filter": {
      "isRead": null
    },
    "pagination": {
      "page": null,
      "pageSize": null,
      "sort": null
    }
  })

  useEffect(() => {
    dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables }))

    const newSetInterval = setInterval(() => {
      dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables }))
    }, 30000)

    return () => {
      clearInterval(newSetInterval)
    }

  }, [accessToken, dispatch, variables])


  const handleChangeTab = useCallback(async (event, newValue) => {
    setCurrentTab(newValue);

    // switch (newValue) {
    //   case "all":
    //     dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables: { ...variables, filter: { isRead: null } } }))
    //       .then(res => {

    //         setCurrentTab(newValue);
    //       })
    //     break
    //   case "unread":
    //     dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables: { ...variables, filter: { isRead: false } } }))
    //       .then(res => {

    //         setCurrentTab(newValue);
    //       })
    //     break

    //   default:
    //     dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables: { ...variables, filter: { isRead: null } } }))
    //       .then(res => {

    //         setCurrentTab(newValue);
    //       })
    // }

  }, []);


  const totalUnRead = notifications?.data?.filter((item) => item?.isRead === false)?.length;

  const handleMarkAsRead = async ({ notification }) => {

    if (notification?.isRead) return

    const response = await handleFetchData({
      data: graphqlApi.notifications.mutations.readUserNotification({ notificationId: notification?.uid }),
      accessToken,
      url: "/notification/graphql"
    })

    if (response?.data?.data?.user_readUserNotification?.success) {
      dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables }))
    }

  };

  const handleMarkAllAsRead = async () => {
    const response = await handleFetchData({
      data: graphqlApi.notifications.mutations.readUserAllNotifications(),
      accessToken,
      url: "/notification/graphql"
    })


    if (response?.data?.data?.user_readAllUserNotifications?.success) {
      dispatch(reduxFunc.notificationFunc.fetchNotificationsForOrganizatorFunc({ accessToken, variables }))
    }

  };



  const TABS = [
    {
      value: 'all',
      label: 'Hepsi',
      count: notifications?.data?.length ?? 0,
    },
    {
      value: 'unread',
      label: 'Okunmayanlar',
      count: notifications?.data?.filter((item) => item?.isRead === false).length,
    }
  ];

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Bildirimler
      </Typography>

      {!!totalUnRead && (
        <ButtonBase
          onClick={handleMarkAllAsRead}
          sx={{
            gap: 1,
          }}
        >
          <Typography variant="caption" sx={{}}>
            Hepsini Okundu Yap
          </Typography>
          {/* <IconButton color="primary" onClick={handleMarkAllAsRead}> */}
          <Iconify icon="eva:done-all-fill" sx={{ color: 'primary.main' }} />
          {/* </IconButton> */}
        </ButtonBase>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {[...(currentTab === "all" ? notifications?.data ?? [] : notifications?.data?.filter(item => !item?.isRead) ?? [])]?.map((notification, index) => (
          <NotificationItem
            key={notification?._id ?? index}
            onClick={() => handleMarkAsRead({ notification })}
            notification={notification}
          />
        ))}
      </List>
    </Scrollbar>
  );



  return (
    <>

      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {loading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            paddingTop={10}
          >
            <CircularProgress />
          </Stack>
        ) : renderList}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            Daha fazla
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
