const approveListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_approveListing($listingId: String!) {
      moderator_approveListing(listingId: $listingId) {
        _id
      }
    }
    `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const rejectListing = ({ listingId, rejectionDetail }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_rejectListing($listingId: String!, $rejectionInput: RejectionInput!) {
      moderator_rejectListing(listingId: $listingId, rejectionInput: $rejectionInput) {
        _id
      }
    }
    `,
    variables: {
      "listingId": listingId,
      "rejectionInput": {
        "rejectionDetail": rejectionDetail
      }
    }
  })

  return data

}


const createListing = ({ values, images, coverImage, exteriorOrganizerImageUrl }) => {


  const data = JSON.stringify({
    query: `
    mutation Moderator_createListing($listingInput: ListingByModeratorInput!) {
      moderator_createListing(listingInput: $listingInput) {
        _id
      }
    }
              `,
    variables: {
      "listingInput": {
        // "ageInterval": values?.ageInterval ?? "",
        "ageInterval": (typeof (values?.ageInterval?.[0]) !== "number" ? values?.ageInterval?.[0] : values?.ageInterval),

        "category": values?.category ?? "",
        "companionship": values?.companionship ?? "",
        "coverImage": coverImage ?? "",
        "description": values?.description ?? "",
        "format": values?.format ?? "",
        "images": images ?? [],
        "title": values?.title ?? "",

        "subCategory": values?.subCategory ?? "",
        "type": values?.type ?? "",

        "exteriorOrganizer": {
          "details": values?.exteriorOrganizerDetails ?? "",
          "imageUrl": exteriorOrganizerImageUrl ?? "",
          "name": values?.exteriorOrganizerName ?? "",

          "socialMedia": {
            "facebook": values?.facebook ?? "",
            "instagram": values?.instagram ?? "",
            "twitter": values?.twitter ?? "",
            "website": values?.website ?? "",
            "youtube": values?.youtube ?? "",
          }

        },
      }
    }
  })

  return data

}


const removeListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeListing($listingId: String!) {
      moderator_removeListing(listingId: $listingId) {
        success
      }
    }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const updateModeratorListing = ({ listingId, values, images, coverImage, exteriorOrganizerImageUrl }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateListing($listingId: String!, $updateInput: UpdateModeratorListingByModeratorInput!) {
      moderator_updateListing(listingId: $listingId, updateInput: $updateInput) {
        success
      }
    }
              `,
    variables: {
      "listingId": listingId,
      "updateInput": {
        "ageInterval": (typeof (values?.ageInterval?.[0]) !== "number" ? values?.ageInterval?.[0] : values?.ageInterval),
        "category": values?.category ?? null,
        "companionship": values?.companionship ?? null,
        "coverImage": coverImage ?? null,
        "description": values?.description ?? null,
        "format": values?.format ?? null,
        "images": images ?? [],
        "subCategory": values?.subCategory ?? null,
        "title": values?.title ?? null,
        // "type": values?.type ?? null

        "exteriorOrganizer": {
          "details": values?.exteriorOrganizerDetails ?? "",
          "imageUrl": exteriorOrganizerImageUrl ?? "",
          "name": values?.exteriorOrganizerName ?? "",
          "socialMedia": {
            "facebook": values?.facebook ?? "",
            "instagram": values?.instagram ?? "",
            "twitter": values?.twitter ?? "",
            "website": values?.website ?? "",
            "youtube": values?.youtube ?? "",
          }
        },
      }
    }
  })

  return data

}


const updateOrganizatorListing = ({ listingId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation UpdateOrganizerListingByModerator($listingId: String!, $updateListingInput: UpdateOrganizerListingByModeratorInput!) {
      updateOrganizerListingByModerator(listingId: $listingId, updateListingInput: $updateListingInput) {
        _id
      }
    }
              `,
    variables: {
      "listingId": listingId,
      "updateListingInput": {
        "ageInterval": values?.ageInterval ?? null,
        "category": values?.category ?? null,
        "companionship": values?.companionship ?? null,
        // "coverImage": values?.coverImage ?? null,
        "description": values?.description ?? null,
        "format": values?.format ?? null,
        "images": values?.images ?? null,
        "subCategory": values?.subCategory ?? null,
        "title": values?.title ?? null,
        // "type": values?.type ?? null,

      }
    }
  })

  return data

}


export const listingsForModerator = {
  approveListing,
  rejectListing,
  createListing,
  removeListing,
  updateModeratorListing,
  updateOrganizatorListing,
}