import { listingsForModerator } from "./_listingsForModerator"
import { listingsForOrganizer } from "./_listingsForOrganizer"
import { locationsForModerator } from "./_locationsForModerator"
import { locationsForOrganizer } from "./_locationsForOrganizer"
import { activitiesForModerator } from "./_activitiesForModerator"
import { activitiesForOrganizer } from "./_activitiesForOrganizer"
import { blogsForModerator } from "./_blogsForModerator"
import { messagesForModerator } from "./_messagesForModerator"

import { messagesForOrganizer } from './_messagesForOrganizer'

export const mutations = {
    activitiesForModerator,
    activitiesForOrganizer,

    listingsForModerator,
    listingsForOrganizer,

    locationsForModerator,
    locationsForOrganizer,

    messagesForModerator,
    messagesForOrganizer,

    blogsForModerator,
}