/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Container, Pagination, paginationClasses } from '@mui/material'

import { graphqlApi } from 'src/hophop/api'
import { reduxFunc } from 'src/hophop/redux'
import { RouterLink } from 'src/routes/components'
import Iconify from 'src/components/iconify/iconify'
import useFetchData from 'src/hophop/hooks/_useFetchData'
import { useSettingsContext } from 'src/components/settings'
import { PostItemSkeleton } from 'src/sections/blog/post-skeleton'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'

import ListingCard from './_BlogCard'


const Blog = () => {

    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { blogsForModerator, loading } = useSelector(state => state.blog)

    const [variables, setVariables] = useState({
        "filter": {
            "additionalDetails": null,
            "plus": false,
            "ageInterval": null,
            "category": null,
            "companionship": null,
            "coverImage": null,
            "description": null,
            "duration": {
                "from": null,
                "to": null
            },
            "format": null,
            "images": null,
            "location": {
                "byCoordinates": {
                    "lat": null,
                    "long": null,
                    "radius": null
                },
                "city": null,
                "district": null,
                "parking": null
            },
            "schedule": {
                "dateInterval": {
                    "from": null,
                    "to": null
                },
                "days": null
            },
            "subCategory": null,
            "title": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 6
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.blogFunc.fetchBlogsForModeratorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])



    const handleDelete = async ({ id }) => {

        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.blogsForModerator.removeBlog({ BlogId: id }),
                url: "/activity/graphql",
                accessToken,
            });

            if (response?.data?.data?.moderator_removeActivityBlog?.success) {
                enqueueSnackbar('Başarılı bir şekilde silindi!');
                dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
            }
            if (response?.data?.data?.moderator_removeActivityBlog?.success === false) {
                enqueueSnackbar('Blog silinemedi!', {
                    variant: "error"
                });
                dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
            }
            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "There are activities are referenced to the listing") {
                        enqueueSnackbar('Aktiviteye bağlı bir listeleme olduğu için silinemez!', {
                            variant: "error",
                            transitionDuration: 5
                        })
                    }
                })
            }

        } catch (error) {
            console.error(error);
        }

    };

    const handlePagination = (event, value) => {
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    };

    const renderSkeleton = (
        <>
            {[...Array(10)].map((_, index) => (
                <PostItemSkeleton key={index} variant="horizontal" />
            ))}
        </>
    );

    const renderList = (
        <>
            {
                blogsForModerator?.count === 0 ?
                    "Herhangi bir blog bulunamadı!"
                    :
                    blogsForModerator?.data?.map((post, index) => (
                        <ListingCard key={post?._id ?? index} post={post} handleDelete={handleDelete} />
                    ))

            }
        </>
    );

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Blog"
                action={
                    <Button
                        component={RouterLink}
                        href="/blog/olustur"
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Yeni Blog
                    </Button>
                }
                sx={{ mb: { xs: 3, md: 5 }, }}
            />

            <Box
                gap={3}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                }}
            >
                {loading ? renderSkeleton : renderList}
            </Box>

            {blogsForModerator?.count > variables.pagination.pageSize && (
                <Pagination
                    onChange={handlePagination}
                    count={Math.ceil(blogsForModerator?.count / variables.pagination.pageSize)}
                    sx={{
                        mt: 8,
                        [`& .${paginationClasses.ul}`]: {
                            justifyContent: 'center',
                        },
                    }}
                />
            )}
        </Container>
    )
}

export default Blog