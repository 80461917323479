/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { useResponsive } from 'src/hooks/use-responsive';
import Label from 'src/components/label';
import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import { companionshipsForListing, formatsForListing } from 'src/hophop/utils/_enums';
import { Avatar, Chip, Skeleton } from '@mui/material';

const CreateListingCard = ({ post }) => {
  const mdUp = useResponsive('up', 'md');
  const format = formatsForListing?.find((item) => item?.value === post?.format)?.name;

  return (
    <Stack component={Card} direction="row" alignItems="stretch" minWidth={300}>
      <Stack
        sx={{
          p: (theme) => theme.spacing(3, 1, 2, 1),
          flex: 1,

          '& *': {
            fontSize: 12,
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          {format ? (
            <Label
              variant="soft"
              color={
                (post?.format === 'online' && 'info') ||
                (post?.format === 'prerecorded' && 'warning') ||
                'primary'
              }
            >
              {format}
            </Label>
          ) : (
            <Skeleton variant="text" width={50} height={30} />
          )}

          <Box component="span" sx={{ typography: 'caption', color: 'text.disabled' }}>
            {new Intl.DateTimeFormat('tr', {
              dateStyle: 'medium',
            }).format(Date.now())}
          </Box>
        </Stack>

        <Stack spacing={1} flexGrow={1}>
          {post.title ? (
            <TextMaxLine variant="subtitle2" line={2}>
              {post?.title}
            </TextMaxLine>
          ) : (
            <Skeleton variant="text" width={100} height={20} />
          )}

          {post.description ? (
            <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }}>
              {post?.description}
            </TextMaxLine>
          ) : (
            <Skeleton variant="rounded" width={200} height={60} />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {post?.category ? (
            <TextMaxLine variant="body2">Kategori : {post?.category?.join(', ')}</TextMaxLine>
          ) : (
            <Skeleton variant="text" width={200} height={20} />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {post?.ageInterval?.length ? (
            <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
              <TextMaxLine variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                Yaş aralığı :
              </TextMaxLine>
              {post?.ageInterval?.map((option, index) => (
                <Chip
                  // {...getTagProps({ index })}
                  key={option?.value ?? index}
                  label={option?.name}
                  size="small"
                  color="info"
                  variant="soft"
                  sx={{
                    ml: 1,
                    '& *': {
                      p: '2px 2px',
                      fontSize: 8,
                    },
                  }}
                />
              ))}
            </Stack>
          ) : (
            <Skeleton variant="text" width={200} height={20} />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {post?.companionship ? (
            <TextMaxLine variant="body2">
              Aile katılımı :{' '}
              {companionshipsForListing?.find((item) => item?.value === post?.companionship)?.name}
            </TextMaxLine>
          ) : (
            <Skeleton variant="text" width={200} height={20} />
          )}
        </Stack>
      </Stack>

      {mdUp && (
        <Box
          sx={{
            width: 180,
            height: { xs: 260 },
            position: 'relative',
            // flexShrink: 0,
            p: 1,
            flex: 1,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              left: 16,
              zIndex: 9,
              borderRadius: 1,
              p: 1,
              color: '#fff',
              backgroundColor: '#ffffff99',
            }}
          >
            <Avatar
              src={post?.exteriorOrganizerImageUrl?.preview ?? post?.exteriorOrganizerImageUrl}
              alt={post?.exteriorOrganizerName}
            // sx={{ position: 'absolute', top: 16, right: 16, zIndex: 9 }}
            >
              {post?.exteriorOrganizerName?.slice(0, 1).toUpperCase()}
            </Avatar>
            <Stack>
              <Box>{post?.exteriorOrganizerName?.slice(0, 7)}</Box>
              <Box sx={{ fontSize: 10 }}>{post?.exteriorOrganizerDetails?.slice(0, 20)}</Box>
            </Stack>
          </Stack>
          {post?.coverImage?.preview ?? post?.coverImage ? (
            <Image
              alt={post?.title}
              src={post?.coverImage?.preview ?? post?.coverImage}
              sx={{ height: 1, borderRadius: 1.5 }}
            />
          ) : (
            <Skeleton variant="rounded" width={160} height={220} />
          )}
        </Box>
      )}
    </Stack>
  );
};

CreateListingCard.propTypes = {
  post: PropTypes.shape({
    author: PropTypes.object,
    coverUrl: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    publish: PropTypes.string,
    title: PropTypes.string,
    totalComments: PropTypes.number,
    totalShares: PropTypes.number,
    totalViews: PropTypes.number,
  }),
};

export default CreateListingCard;
