/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    moderatorConversations: null,
    selectedUserMessages: null,
    selectedConversation: null
}

// moderator conservations
export const fetchConservationsForModeratorFunc = createAsyncThunk('message/fetchConservationsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.messagesForModerator.conservations({ variables })
    }))
    return response?.data
})
// organizer conservations
export const fetchConservationsForOrganizerFunc = createAsyncThunk('message/fetchConservationsForOrganizerFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.messagesForOrganizer.conservations({ variables })
    }))
    return response?.data
})

// moderator messages
export const fetchSelectedUserMessagesForModeratorFunc = createAsyncThunk('message/fetchSelectedUserMessagesForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.messagesForModerator.selectedUserMessages({ variables })
    }))
    return response?.data
})

// organizer messages
export const fetchSelectedUserMessagesForOrganizerFunc = createAsyncThunk('message/fetchSelectedUserMessagesForOrganizerFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.messagesForOrganizer.selectedUserMessages({ variables })
    }))
    return response?.data
})




const { reducer: messageReducer, actions } = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setSelectedConversation: (state, actions) => {
            state.selectedConversation = actions.payload
        }
    },
    extraReducers: (builder) => {
        // ################# moderator conversations ######
        builder.addCase(fetchConservationsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchConservationsForModeratorFunc.fulfilled, (state, action) => {

            state.moderatorConversations = {
                data: [
                    // ...action.payload?.data?.user_activityConversations?.data ?? [],
                    ...action.payload?.data?.moderator_activityConversations?.data ?? [],
                ],
                count:
                    // (action.payload?.data?.user_activityConversations?.count ?? 0) + 
                    (action?.payload?.data?.moderator_activityConversations?.count ?? 0),
            }
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchConservationsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })




        // ################# organizer conversations ######
        builder.addCase(fetchConservationsForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchConservationsForOrganizerFunc.fulfilled, (state, action) => {

            state.moderatorConversations = {
                data: [
                    ...action.payload?.data?.user_activityConversations?.data ?? [],
                    // ...action.payload?.data?.moderator_activityConversations?.data ?? [],
                ],
                count:
                    (action.payload?.data?.user_activityConversations?.count ?? 0)
                // (action?.payload?.data?.moderator_activityConversations?.count ?? 0),
            }
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchConservationsForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })






        // #################  selected moderator messages ######
        builder.addCase(fetchSelectedUserMessagesForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedUserMessagesForModeratorFunc.fulfilled, (state, action) => {

            state.selectedUserMessages = action.payload?.data?.moderator_moderatorMessages

            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedUserMessagesForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // #################  selected organizer messages ######
        builder.addCase(fetchSelectedUserMessagesForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedUserMessagesForOrganizerFunc.fulfilled, (state, action) => {

            state.selectedUserMessages = action.payload?.data?.user_messages

            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedUserMessagesForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


    }
})

const { setSelectedConversation } = actions

export default messageReducer

export const messageFunc = {
    fetchConservationsForModeratorFunc,
    fetchConservationsForOrganizerFunc,
    fetchSelectedUserMessagesForModeratorFunc,
    fetchSelectedUserMessagesForOrganizerFunc,
    setSelectedConversation,
}