/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    customersForModerator: null,
    customersForOrganizer: null,
    // blogsForOrganizer: [],
    selectedCustomerForModerator: null,
    // selectedListingForOrganizer: null,
    loading: false,
    error: false,
}

// moderator customers
export const fetchCustomersForModeratorFunc = createAsyncThunk('customer/fetchCustomersForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.customersForModerator.allCustomers({ variables })
    }))
    return response?.data
})

// organizer customers
export const fetchCustomersForOrganizerFunc = createAsyncThunk('customer/fetchCustomersForOrganizerFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.customersForOrganizer.allCustomers({ variables })
    }))
    return response?.data
})


// organizer blogs
// export const fetchBlogsForOrganizatorFunc = createAsyncThunk('listing/fetchBlogsForOrganizatorFunc', async ({ accessToken, variables }) => {
//     // const response = await axios(configFunc({
//     //     accessToken,
//     //     data: graphqlApi.activity.queries.blogsorOrganizer.allBlogs({ variables })
//     // }))
//     return null // response?.data
// })

// organizer selected blog
// export const fetchSelectedListingForOrganizerFunc = createAsyncThunk('blog/fetchSelectedListingForOrganizerFunc', async ({ accessToken, listingId }) => {
//     // const response = await axios(configFunc({
//     //     accessToken,
//     //     data: graphqlApi.activity.queries.blogsorOrganizer.allBlogs({ variables })
//     // }))
//     return null // response?.data
// })



const { reducer: customerReducer, actions } = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // resetSelectedBlog: (state, action) => {
        //     state.selectedBlogForModerator = null
        //     // state.selectedListingForOrganizer = null
        // }
    },
    extraReducers: (builder) => {
        // ################# moderator all list ######
        builder.addCase(fetchCustomersForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCustomersForModeratorFunc.fulfilled, (state, action) => {

            state.customersForModerator = action.payload?.data?.moderator_customers
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchCustomersForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })



        // ################# organizer customers all list ######
        builder.addCase(fetchCustomersForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCustomersForOrganizerFunc.fulfilled, (state, action) => {

            state.customersForOrganizer = action.payload?.data?.organizer_customers
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchCustomersForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // // ################# organizator all list ######
        // builder.addCase(fetchBlogsForOrganizatorFunc.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(fetchBlogsForOrganizatorFunc.fulfilled, (state, action) => {
        //     state.blogsForOrganizer = action.payload?.data?.organizer_listings
        //     state.loading = false
        //     state.error = false
        // })
        // builder.addCase(fetchBlogsForOrganizatorFunc.rejected, (state, action) => {

        //     state.loading = false
        //     state.error = action.error
        // })

        // // ################# organizator item detail ######
        // builder.addCase(fetchSelectedListingForOrganizerFunc.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(fetchSelectedListingForOrganizerFunc.fulfilled, (state, action) => {

        //     state.selectedListingForOrganizer = action.payload?.data?.organizer_listing
        //     state.loading = false
        //     state.error = false
        // })
        // builder.addCase(fetchSelectedListingForOrganizerFunc.rejected, (state, action) => {

        //     state.loading = false
        //     state.error = action.error
        // })

    }
})


export default customerReducer;

export const customerFunc = {
    fetchCustomersForModeratorFunc,
    fetchCustomersForOrganizerFunc,
    // fetchBlogsForOrganizatorFunc,
    // fetchSelectedBlogForModeratorFunc,
    // fetchSelectedListingForOrganizerFunc,

    // resetSelectedBlog: actions.resetSelectedBlog
}