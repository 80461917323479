// ############################  personal  #############################
const personalRegister = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_registerAsIyzicoPersonalSubMerchant($input: RegisterAsPersonalSubMerchantInput) {
            self_registerAsIyzicoPersonalSubMerchant(input: $input) {
              subMerchantKey
              subMerchantType
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address ?? null,
                "email": values?.email ?? null,
                "gsmNumber": values?.gsmNumber ?? null,
                "iban": values?.iban ?? null,
                "identityNumber": values?.identityNumber?.toString() ?? null,
                "name": values?.name ?? null,
                "swiftCode": values?.swiftCode ?? null,
                "contactName": values?.contactName ?? null,
                "contactSurname": values?.contactSurname ?? null,
                // "description": values?.description ?? null,
                // "contactName": "PRIVATE_COMPANY", // burası apolloda yok ama istiyor
            }
        }
    })

    return data
}

const personalUpdate = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_updateIyzicoPersonalSubMerchantInfo($input: UpdatePersonalSubMerchantInput) {
            self_updateIyzicoPersonalSubMerchantInfo(input: $input) {
              success
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address ?? null,
                "email": values?.email ?? null,
                "gsmNumber": values?.gsmNumber ?? null,
                "iban": values?.iban ?? null,
                "identityNumber": values?.identityNumber?.toString() ?? null,
                "name": values?.name ?? null,
                "swiftCode": values?.swiftCode ?? null,

                "contactName": values?.contactName ?? null,
                "contactSurname": values?.contactSurname ?? null,
                // "description": values?.description ?? null,

            }
        }
    })

    return data
}
// ################################################################################


// ############################  private company  #############################
const privateCompanyRegister = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_registerAsIyzicoPrivateCompanySubMerchant($input: RegisterAsPrivateCompanySubMerchantInput) {
            self_registerAsIyzicoPrivateCompanySubMerchant(input: $input) {
              subMerchantKey
              subMerchantType
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.gsmNumber,
                "iban": values?.iban,
                "identityNumber": values?.identityNumber?.toString(),
                "name": values?.name,
                // "contactName": values?.contactName,
                "swiftCode": values?.swiftCode,
                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.legalCompanyTitle,
            }
        }
    })

    return data
}

const privateCompanyUpdate = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_updateIyzicoPrivateCompanySubMerchantInfo($input: UpdatePrivateCompanySubMerchantInput) {
            self_updateIyzicoPrivateCompanySubMerchantInfo(input: $input) {
              success
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.gsmNumber,
                "iban": values?.iban,
                "identityNumber": values?.identityNumber,
                "name": values?.name,
                // "contactName": values?.contactName,
                "swiftCode": values?.swiftCode,
                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.legalCompanyTitle,
            }
        }

    })

    return data
}
// ################################################################################


// ############################  limited company  #############################
const limitedCompanyRegister = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_registerAsIyzicoLimitedCompanySubMerchant($input: RegisterAsLimitedCompanySubMerchantInput) {
            self_registerAsIyzicoLimitedCompanySubMerchant(input: $input) {
              subMerchantType
              subMerchantKey
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.gsmNumber,
                "iban": values?.iban,
                "name": values?.name,
                // "contactName": values?.contactName,
                "swiftCode": values?.swiftCode,
                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.legalCompanyTitle,
                "taxNumber": values?.taxNumber,
            }
        }
    })

    return data
}

const limitedCompanyUpdate = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Self_updateIyzicoLimitedCompanySubMerchantInfo($input: UpdateLimitedCompanySubMerchantInput) {
            self_updateIyzicoLimitedCompanySubMerchantInfo(input: $input) {
              success
            }
          }
        `,

        variables: {
            "input": {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.gsmNumber,
                "iban": values?.iban,
                "name": values?.name,
                // "contactName": values?.contactName,
                "swiftCode": values?.swiftCode,
                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.legalCompanyTitle,

                // "taxNumber": values?.taxNumber, // burası apolloda var ama hata veriyor
            }
        }

    })

    return data
}
// ################################################################################


export const mutations = {
    personalRegister,
    personalUpdate,

    privateCompanyRegister,
    privateCompanyUpdate,

    limitedCompanyRegister,
    limitedCompanyUpdate
}