/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/notification/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    notifications: null,
    loading: false,
    error: false,
}




// organizer activities
export const fetchNotificationsForOrganizatorFunc = createAsyncThunk('activity/fetchNotificationsForOrganizatorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.notifications.queries.userNotifications({ variables })
    }))
    return response?.data
})




const { reducer: notificationReducer, actions } = createSlice({
    name: 'activitie',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# organizator all activities ######
        builder.addCase(fetchNotificationsForOrganizatorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchNotificationsForOrganizatorFunc.fulfilled, (state, action) => {

            state.notifications = action.payload?.data?.user_userNotifications
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchNotificationsForOrganizatorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


    }
})


export default notificationReducer

export const notificationFunc = {
    fetchNotificationsForOrganizatorFunc
}