/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Chip, Grid, MenuItem } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form';
import { RHFAutocomplete, RHFCheckbox, RHFSelect } from 'src/components/hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dayMapping, repeatIntervalTypes, timeMapping } from 'src/hophop/utils/_enums';


const FormFour = ({ values, control }) => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <RHFCheckbox name="schedule.isRepetetive" label="Çok günlü bir aktivite mi?" />
            </Grid>

            {!values?.schedule?.isRepetetive ? (
                <>
                    <Grid item xs={12}>
                        <Controller
                            name="schedule.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    className="date"
                                    label="Aktivite tarihi"
                                    minDate={new Date()}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <RHFSelect name="schedule.startTime" label="Başlama saati">
                            {timeMapping
                                .slice(0, timeMapping.length - Math.ceil(Number(values.duration / 30)))
                                .map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <RHFSelect name="schedule.endTime" label="Bitiş saati">
                            {timeMapping
                                .slice(
                                    timeMapping
                                        .map((item) => item.value)
                                        .indexOf(
                                            Number(values?.schedule?.startTime) + Math.ceil(Number(values.duration / 30))
                                        )
                                )
                                .map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <RHFSelect name="schedule.repeatedIntervalType" label="Aktivite aralığı">
                            {repeatIntervalTypes.map((status, index) => (
                                <MenuItem
                                    key={status?.value}
                                    value={status?.value}
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    {status?.name?.toLocaleLowerCase()}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Grid>

                    {(values?.schedule?.repeatedIntervalType === 'weekly' ||
                        values?.schedule?.repeatedIntervalType === 'monthly') && (
                            <Grid item xs={12}>
                                {/* <RHFSelect name="schedule.day" label="Aktivite günü">
                                        {dayMapping.map((status) => (
                                            <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                                {status?.name?.toLocaleLowerCase()}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect> */}

                                <RHFAutocomplete
                                    name="schedule.day"
                                    placeholder="Haftanın hangi günleri?"
                                    multiple
                                    disableCloseOnSelect
                                    options={dayMapping}
                                    getOptionLabel={(option) => option?.value?.toString()}
                                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?.value}>
                                            {option?.name}
                                        </li>
                                    )}
                                    renderTags={(selected, getTagProps) =>
                                        selected.map((option, index) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value}
                                                label={option?.name}
                                                size="small"
                                                color="info"
                                                variant="soft"
                                            />
                                        ))
                                    }
                                />
                            </Grid>
                        )}

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="schedule.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    className="date"
                                    label="Başlangıç tarihi"
                                    minDate={new Date()}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="schedule.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    className="date"
                                    label="Bitiş tarihi"
                                    minDate={new Date()}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <RHFSelect name="schedule.startTime" label="Başlama saati">
                            {timeMapping
                                .slice(0, timeMapping.length - Math.ceil(Number(values.duration / 30)))
                                .map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <RHFSelect name="schedule.endTime" label="Bitiş saati">
                            {timeMapping
                                .slice(
                                    timeMapping
                                        .map((item) => item.value)
                                        .indexOf(
                                            values.schedule.startTime + Math.ceil(Number(values.duration / 30))
                                        )
                                )
                                .map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                    </Grid>
                </>
            )}

            {/* <Grid item xs={12} md={6}>
                <Controller
                    name="excludedDates"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DateRangePickerDay
                            {...field}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
            </Grid> */}

            {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">monthlyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}
                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}

            {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">weeklyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}

                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    );
};

export default FormFour