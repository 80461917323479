import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { ageIntervalsForListing, companionshipsForListing, formatsForListing, parkingForLocation, timeMapping } from 'src/hophop/utils/_enums';

// ----------------------------------------------------------------------

export default function CreateBlogCard({ post: tour }) {

  // const { locations } = useSelector(state => state.parameter)

  const renderPrice = (

    <Stack
      direction="row"
      gap={1}
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        position: 'absolute',
        backgroundColor: tour?.status === "passive" ? "#ffffff99" : "",

        right: 8,
        p: 1,
        borderRadius: 1,
        flexWrap: 'wrap'

      }}
    >

      {
        tour?.category && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{

              borderRadius: 1,
              bgcolor: 'grey.800',
              p: '2px 6px 2px 4px',
              color: 'common.white',
              typography: 'subtitle2',
            }}
          >
            {tour?.category}
          </Stack>
        )
      }

      {
        tour?.ageInterval
          ?.map(item => ageIntervalsForListing?.find(age => age?.value === item?.value)?.name)
          ?.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: 1,
                p: '2px 6px 2px 4px',
                typography: 'subtitle2',
                bgcolor: 'error.main',
                color: 'common.white',
              }}
            >
              {item}
            </Stack>
          ))
      }
    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }} >
        {renderPrice}
        <Image alt={tour?.title} src={tour?.coverImage?.preview ?? tour?.coverImage} sx={{ borderRadius: 1, height: 164, width: 1 }} />
      </Stack>
    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      primary={tour?.createdAt && `Oluşturulma tarihi: ${tour?.createdAt ? (new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.createdAt))) : ""}`}
      secondary={
        <Link component={RouterLink} href={`/aktiviteler/${tour?._id}`} color="inherit">
          {tour?.listing?.title}
        </Link>
      }
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,
        noWrap: true,
        component: 'span',
        color: 'text.primary',
        typography: 'subtitle1',
      }}
    />
  );

  const renderInfo = (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
        flexWrap: "wrap"
      }}
    >


      {

        [

          {
            label: tour?.title,
            icon: <Iconify icon="ph:read-cv-logo-fill" sx={{ color: 'secondary.main' }} />,
          },
          {
            label: companionshipsForListing?.find(item => item?.value === tour?.companionship)?.name,
            icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
          },

        ].map((item, index) => {

          return (
            <div key={index}>

              {item?.label && (<Stack

                // key={item.label}
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{
                  typography: 'body2',
                  textTransform: "capitalize",
                  flex: '1 1 200px'
                }}
              >
                {item?.label && item?.icon}
                {item?.label}
              </Stack>)}
            </div>
          )

        })
      }


    </Stack>
  );


  return (
    <Card
      sx={{
        ...(tour?.status === "passive" && { backgroundColor: '#f1f1f1' }),
        transitionDuration: '0,3',
        boxShadow: '1px 1px 10px 0px #d9d9d9',
      }}

    >
      {renderImages}

      {renderTexts}

      {renderInfo}

      <Stack
        spacing={1}
        sx={{
          p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),

        }}
      >
        <Box
          sx={{
            fontSize: '12px',
            backgroundColor: '#f1f1f1',
            p: 2,
            borderRadius: 1,
            flex: 1,
          }}
        >
          {tour?.description}
        </Box>
      </Stack>

    </Card>

  );
}

CreateBlogCard.propTypes = {
  post: PropTypes.object,
};