import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from "react-hook-form";

import { graphqlApi } from 'src/hophop/api';
import { compressImage } from 'src/hophop/utils';
import { useSnackbar } from 'src/components/snackbar';
import { RHFTextField } from 'src/components/hook-form';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { RHFUpload } from 'src/components/hook-form/rhf-upload';
import FormProvider from 'src/components/hook-form/form-provider';



const PersonalOrganization = ({ handleNext, handleBack, activeStep }) => {

    const { enqueueSnackbar } = useSnackbar()
    const { handleFetchData } = useFetchData()
    const [loading, setLoading] = useState(false)
    const { accessToken } = useSelector(state => state.auth)
    const { user: userInfo } = useSelector(state => state.user)


    const NewBlogSchema = Yup.object().shape({

        fullName: Yup.string()
            .required('Zorunlu alan')
            .test('len', 'En az 2 kelime olmalı', (val) => val?.trim().split(" ").length > 1),
        identificationNumber: Yup.string()
            .required('Zorunlu alan')
            .max(11, "Geçerli bir TC kimlik numarası giriniz")
            .min(11, "Geçerli bir TC kimlik numarası giriniz"),
        organization: Yup.string()
            .required("Zorunlu alan!"),
        phoneNumber: Yup.string()
            .required('Zorunlu alan')
            .min(10, "Geçerli bir telefon numarası giriniz! (xxxxxxxxxx)")
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{4,4}?$/,
                "Geçerli bir telefon numarası giriniz! (xxx xxxxxxx)"
            ),
        email: Yup.string()
            .email("Geçerli bir email giriniz")
            .required('Zorunlu alan')
            .test('len', 'xxxx@xxxxx.xxx şeklinde yazılması gerekiyor', (val) => val?.trim().split(/[@.]+/).length > 2),
        address: Yup.string()
            .min(5, "Geçerli bir adres giriniz")
            .required('Zorunlu alan'),
        logo: Yup.mixed()
            .nullable(false),
        // swiftCode: Yup.string()
        //     .trim()
        //     .required('Zorunlu alan!')
        //     .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'Geçersiz code')
        //     .min(11, 'Geçersiz code')
        //     .max(11, 'Geçersiz code')
        //     .test('valid-code', 'Geçersiz code', (value) => {
        //         // This function performs the IBAN validation
        //         return () => null
        //     }),
        iban: Yup.string()
            .trim()
            // .required('Zorunlu alan!')
            // .matches(/^[A-Z]{2}\d{2}[A-Z0-9]+$/, 'Geçersiz iban')
            // .min(26, 'Geçersiz iban')
            .max(26, 'Geçersiz iban')
            .test('valid-iban', 'Geçersiz iban', (value) => {
                // This function performs the IBAN validation
                if (value.length === 0) return true
                if (!(/^[A-Z]{2}\d{2}[A-Z0-9]+$/.test(value))) {
                    return false
                }
                if (value.length < 26) return false
                return () => null
            }),
        description: Yup.string().required('Zorunlu alan!'),

        website: Yup.string(),
        youtube: Yup.string(),
        twitter: Yup.string(),
        instagram: Yup.string(),
        facebook: Yup.string(),

    });


    const defaultValues = {
        fullName: '',
        identificationNumber: "",
        organization: "",
        logo: null,
        phoneNumber: "",
        email: "",
        address: "",
        // swiftCode: "TCZBTR2AXXX",
        swiftCode: "",
        iban: "",
        description: "",

        website: "",
        youtube: "",
        twitter: "",
        instagram: "",
        facebook: "",
    };

    const methods = useForm({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
        mode: "all",
    });

    const {
        watch,
        handleSubmit,
        formState,
    } = methods;

    const { errors, dirtyFields } = formState

    const values = watch();

    const onSubmit = async () => {



        try {
            setLoading(true)

            const uploadUrl = await graphqlApi.dms.organizationPresignedUrls.logoImageOrganizationPresignedUrls({
                organizationId: userInfo?._id,
                file: values.logo,
                accessToken
            })


            const compressedImage = await compressImage(values.logo, 0.4)

            const isUpload = await graphqlApi.dms.handleSaveImage({
                file: compressedImage,
                url: uploadUrl?.presignedUploadUrl
            })

            const response = await handleFetchData({
                data: graphqlApi.user.mutations.userLMutations.userUpdate({
                    values,
                    profileImageUrl: uploadUrl?.url,
                    // userId: user?.userId
                }),
                url: "/user/graphql",
                accessToken,

            });


            if (response?.data?.data?.updateUser?._id) {

                const contactName = values?.fullName?.split(" ")?.[0]
                const contacSurname = values?.fullName?.split(" ")?.[1]
                const responseIyzico = await handleFetchData({
                    data: graphqlApi.payment.mutations.personalRegister({
                        values: {
                            "address": values?.address,
                            "email": values?.email,
                            "gsmNumber": values?.phoneNumber,
                            "iban": values?.iban,
                            "identityNumber": values?.identificationNumber,
                            "name": values?.fullName,
                            "swiftCode": values?.swiftCode,
                            "contactName": contactName,
                            "contactSurname": contacSurname ?? values?.fullName,
                        }
                    }),
                    url: "/payment/graphql",
                    accessToken,

                });



                if (responseIyzico?.data?.data?.self_registerAsIyzicoPersonalSubMerchant) {
                    enqueueSnackbar('Kayıt başarılı!');
                    // setOrganizerOnboardingPageState("last")
                    handleNext()
                    setLoading(false)
                } else {
                    // enqueueSnackbar('Kayıt yapılamadı!', { variant: 'error' });
                    setLoading(false)
                    if (values.iban) {

                        responseIyzico?.data?.errors?.forEach((item) => {
                            if (item.message === `API: createPersonalSubMerchantAPI failed. error: {"code":"5","message":"email hatalı format ile gönderilmiştir"}`) {
                                enqueueSnackbar('Hatalı email!', { variant: "error" });
                            } else if (item.message === `API: createPersonalSubMerchantAPI failed. error: {"code":"2030","message":"Gönderilen iban hatalı formattadır"}`) {
                                enqueueSnackbar('Hatalı veya kullanılan iban!', { variant: "error" });
                            }
                        })
                    } else {
                        enqueueSnackbar('Kayıt başarılı!');
                        handleNext()
                    }
                }

            }

        } catch (error) {
            console.error(error);
            setLoading(false)

        }



    };


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    px: { sm: '10%' },
                }}
            >

                <Box>
                    {activeStep === 0 && <FormOne />}
                    {activeStep === 1 && <FormTwo />}
                    {activeStep === 2 && <FormThree />}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: { xs: '100px' },
                    }}
                >


                    <LoadingButton
                        loading={loading}
                        variant='contained'
                        onClick={() => {
                            handleBack()
                        }}
                    >
                        Geri
                    </LoadingButton>

                    <LoadingButton
                        loading={loading}
                        disabled={!!(
                            activeStep === 0 && (
                                !dirtyFields.fullName || errors.fullName
                                || !dirtyFields.logo || errors.logo

                                || !dirtyFields.description || errors.description
                                || !dirtyFields.organization || errors.organization
                                || !dirtyFields.identificationNumber || errors.identificationNumber
                            )

                            ||

                            activeStep === 1 && (
                                !dirtyFields.address || errors.address
                                || !dirtyFields.email || errors.email
                                || !dirtyFields.phoneNumber || errors.phoneNumber
                            )
                        )}
                        variant='contained'
                        onClick={
                            activeStep === 0 && handleNext ||
                            activeStep === 1 && handleNext ||
                            activeStep === 2 && (() => handleSubmit(onSubmit()))
                        }
                    >
                        {
                            activeStep === 0 && "İleri" ||
                            activeStep === 1 && "İleri" ||
                            activeStep === 2 && "Kaydet"
                        }
                    </LoadingButton>

                </Box>

            </Box>
        </FormProvider>
    )
}

const FormOne = () => {


    const { setValue } = useFormContext()

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('logo', newFile, { shouldValidate: true, shouldDirty: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = () => {
        setValue('logo', null);
    };
    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <RHFTextField name="fullName" label="*Ad Soyad" />
                    </Grid>

                    <Grid item xs={12} >
                        <RHFTextField name="organization" label="*Organizasyon Adı" />
                    </Grid>

                    <Grid item xs={12} >
                        <RHFTextField max={11} type="number" name="identificationNumber" label="*TC Kimlik No (Fatura işlemleri için gerekli.)" />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>

                <RHFUpload
                    name="logo"
                    // helperText="sdfd"
                    text1=""
                    text2="*Logo"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                    sx={{
                        height: '100%',
                        '& > div:not(.MuiFormHelperText-root)': {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },

                        '& svg': {
                            width: '90px',
                        },

                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="description" label="*Organizatör Hakkında" multiline rows={3} inputProps={{ maxLength: 300 }} />
            </Grid>

        </Grid>

    )
}


const FormTwo = () => {



    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
                <RHFTextField name="email" label="*Email" />
            </Grid>

            <Grid item xs={12} sm={6}>
                <RHFTextField name="phoneNumber" label="*Telefon No" placeholder="--- -------" />
            </Grid>


            <Grid item xs={12}>
                <RHFTextField name="address" label="*Adres" multiline rows={3} />
            </Grid>


            <Grid item xs={12} >
                <RHFTextField name="iban" label="IBAN"
                    helperText="Zorunlu değil, rezervasyon alınması durumunda gereklidir."
                    placeholder="TR-- ---- ---- ---- ---- ---- --"
                />
            </Grid>

        </Grid>
    )
}

const FormThree = () => {


    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <RHFTextField name="website" label="Web Sitesi" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="youtube" label="Youtube" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="twitter" label="Twitter" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="instagram" label="Instagram" />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="facebook" label="Facebook" />
            </Grid>

        </Grid>
    )
}


export default PersonalOrganization