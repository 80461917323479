import { reduxFunc } from 'src/hophop/redux'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Container, Grid, IconButton, Table, TableBody, TableContainer, Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material'
import {
    useTable,
    TableNoData,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import FormProvider, {
    RHFSelect,
    RHFTextField,
    RHFUploadCategoryImage,
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { useBoolean } from 'src/hooks/use-boolean';
import { fetchParametersFunc } from 'src/hophop/redux/slices/_parameterSlice';
import { fetchSettingsFunc } from 'src/hophop/redux/slices/_activitySlice';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { graphqlApi } from 'src/hophop/api';
import { compressImage } from 'src/hophop/utils';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { enqueueSnackbar } from 'notistack';

import UserTableRow from './listing-table-row';


const TABLE_HEAD = [
    { id: 'index', label: '#' },
    { id: 'categoryName', label: 'Kategori Adı', width: 20 },
    { id: 'description', label: 'Açıklama' },
    { id: 'image', label: 'Resim', width: 300 },
    { id: 'defaultSorting', label: 'Sıralama Türü' },
    { id: 'action', label: 'İşlem' },
];


export const Categories = () => {

    const dispatch = useDispatch()
    const { handleFetchData } = useFetchData()
    const table = useTable();
    const confirm = useBoolean();

    const [createOpen, setCreateOpen] = useState(false)
    const [updateOpen, setUpdateOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const { allOrganizerListingsForModerator: list } = useSelector(state => state.listing)
    const { categories } = useSelector(state => state.parameter)
    const { user: userInfo } = useSelector(state => state.user)
    const { accessToken, user: authUser } = useSelector(state => state.auth)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedPrewCategoryId, setSelectedPrewCategoryId] = useState(null)

    const CreateCategorySchema = Yup.object().shape({
        categoryId: Yup.string().required('Kategori adı zorunludur'),
        description: Yup.string().required('Açıklama zorunludur'),
        imageUrl: Yup.string().required('Resim zorunludur'),
        defaultSorting: Yup.string().required('Sıralama türü zorunludur'),

    });

    const defaultValues = useMemo(() => ({
        categoryId: '',
        description: '',
        imageUrl: '',
        defaultSorting: 'createdAtDesc',
    }), []);

    const methods = useForm({
        resolver: yupResolver(CreateCategorySchema),
        defaultValues,
    });

    const {
        setValue,
        handleSubmit,
        formState: { isSubmitting },
        reset,
        watch
    } = methods;

    useEffect(() => {
        reset({ ...defaultValues });
    }, [defaultValues, reset]);

    const onSubmit = async () => {

        const data = watch()
        let uploadUrl;

        try {
            if (typeof data?.imageUrl !== "string") {
                uploadUrl = await graphqlApi.dms.organizationPresignedUrls.logoImageOrganizationPresignedUrls({
                    organizationId: userInfo?._id,
                    file: data.logo,
                    accessToken
                })

                const compressedImage = await compressImage(data?.imageUrl, 0.4)

                const imageUrll = await graphqlApi.dms.handleSaveImage({
                    file: compressedImage,
                    url: uploadUrl?.presignedUploadUrl
                })
            }



            if (!selectedRow) {
                const createCategory = await handleFetchData({
                    data: graphqlApi.activity.mutations.activitiesForModerator.createCategory({
                        categoryId: data?.categoryId,
                        defaultSorting: data?.defaultSorting,
                        description: data?.description,
                        imageUrl: (typeof data?.imageUrl !== "string") ? uploadUrl?.url : data?.imageUrl,
                    }),
                    url: "/activity/graphql",
                    accessToken,
                });
            }

            if (selectedRow) {
                const updateCategory = await handleFetchData({
                    data: graphqlApi.activity.mutations.activitiesForModerator.updateCategory({
                        categoryIdPrew: selectedPrewCategoryId,
                        categoryId: data?.categoryId,
                        defaultSorting: data?.defaultSorting,
                        description: data?.description,
                        imageUrl: (typeof data?.imageUrl !== "string") ? uploadUrl?.url : data?.imageUrl,
                    }),
                    url: "/activity/graphql",
                    accessToken,
                });
            }

            dispatch(reduxFunc.activityFunc.fetchSettingsFunc({ accessToken }))
            dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))

            if (!selectedRow) {
                enqueueSnackbar("Kategori başarıyla oluşturuldu!", { variant: "success" })
            }
            if (selectedRow) {
                enqueueSnackbar("Kategori başarıyla güncellendi!", { variant: "success" })
            }

            setCreateOpen(false)
            setUpdateOpen(false)

        } catch (error) {
            console.error(error);
        }
    };



    const handleCreateCategory = useCallback(() => {
        setCreateOpen(true)
    }, []);

    const handleUpdateCategory = useCallback((item) => {
        setSelectedRow(item)
        setSelectedPrewCategoryId(item?._id)
        setUpdateOpen(true)
    }, []);

    const handleDeleteCategory = useCallback((item) => {
        setSelectedRow(item)
        setDeleteOpen(true)
    }, []);

    const handleDelete = useCallback(async (id) => {
        const deleteCategory = await handleFetchData({
            data: graphqlApi.activity.mutations.activitiesForModerator.removeCategory({ categoryId: id }),
            url: "/activity/graphql",
            accessToken,
        });

        dispatch(reduxFunc.activityFunc.fetchSettingsFunc({ accessToken }))
        dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))

        if (deleteCategory?.data?.data?.moderator_removeCategory?.success) {
            enqueueSnackbar('Başarılı bir şekilde silindi!');
        }

        if (deleteCategory?.data.errors?.[0]?.message) {
            if (deleteCategory?.data.errors?.[0]?.message?.includes("category referencing")) {
                enqueueSnackbar("Bu kategoride etkinlik bulunduğu için silinemedi.", { variant: "warning" })
            } else {
                enqueueSnackbar('Kategori silinemedi!', { variant: "error" })
            }
        }
    }, [handleFetchData, accessToken, dispatch])



    const handleApproveRow = useCallback(async (id) => {

        try {
            console.log({ id })

        } catch (error) {
            console.error({ error })
        }

    }, []);

    const handleRejectRow = useCallback(async (id) => {

        try {
            console.log({ id })

        } catch (error) {
            console.error({ error })
        }

    }, []);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('imageUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );


    useEffect(() => {
        dispatch(fetchParametersFunc({ accessToken }))
        dispatch(fetchSettingsFunc({ accessToken }))
    }, [accessToken, dispatch])

    useEffect(() => {
        if (updateOpen) {
            setValue('categoryId', selectedRow?._id)
            setValue('description', selectedRow?.description)
            setValue('imageUrl', selectedRow?.imageUrl)
            setValue('defaultSorting', selectedRow?.defaultSorting)
        }
    }, [updateOpen, selectedRow, setValue])


    return (
        <Container maxWidth="lg">

            <CustomBreadcrumbs
                heading="Kategoriler"
                action={
                    <Button
                        onClick={handleCreateCategory}
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Kategori Oluştur
                    </Button>
                }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Grid container spacing={3}>

                <Grid item xs={12} sx={{ mt: 8 }}>

                    <Card>
                        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                            <TableSelectedAction
                                dense={table.dense}
                                numSelected={table?.selected?.length}
                                rowCount={list?.data?.length}
                                onSelectAllRows={(checked) =>
                                    table.onSelectAllRows(
                                        checked,
                                        list?.data.map((row) => row.id)
                                    )
                                }
                                action={
                                    <Tooltip title="Delete">
                                        <IconButton color="primary" onClick={confirm.onTrue}>
                                            <Iconify icon="solar:trash-bin-trash-bold" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />

                            <Scrollbar>
                                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                                    <TableHeadCustom
                                        order={table.order}
                                        orderBy={table.orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={list?.data?.length}
                                        numSelected={table?.selected?.length}
                                        onSort={table.onSort}
                                    />

                                    <TableBody>
                                        {categories?.data?.map((row, index) => (
                                            <UserTableRow
                                                key={row?._id}
                                                index={index}
                                                row={row}
                                                onApproveRow={() => handleApproveRow(row?._id)}
                                                onRejectRow={handleRejectRow}
                                                onUpdateRow={() => handleUpdateCategory(row)}
                                                onDeleteRow={() => handleDeleteCategory(row)}
                                            />
                                        ))}

                                        <TableNoData notFound={list?.count === 0} />
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </TableContainer>




                    </Card>
                </Grid>

            </Grid>

            <Dialog open={createOpen} onClose={() => setCreateOpen(false)}>
                <DialogTitle>Yeni Kategori Oluştur</DialogTitle>
                <DialogContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} width={400} mt={2}>
                            <RHFTextField name="categoryId" label="Kategori Adı" />
                            <RHFTextField name="description" multiline rows={4} label="Açıklama" />
                            <RHFUploadCategoryImage
                                name="imageUrl"
                                maxSize={3145728}
                                onDrop={handleDrop}
                            />
                            <RHFSelect native name="defaultSorting" label="Sıralama Türü" placeholder="Sıralama Türü">
                                {[{
                                    value: 'createdAtDesc',
                                    name: 'Oluşturulma Tarihi',
                                },
                                {
                                    value: 'createdAtDescWithPriority',
                                    name: 'Öncelik ve Oluşturulma Tarihi',
                                },
                                {
                                    value: 'ratingDescCreatedAtDesc',
                                    name: 'Puan ve Oluşturulma Tarihi',
                                },
                                {
                                    value: 'ratingDescCreatedAtDescWithPriority',
                                    name: 'Puan, Öncelik ve Oluşturulma Tarihi',
                                }
                                ].map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item?.name}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Stack>

                    </FormProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateOpen(false)}>Vazgeç</Button>
                    <Button
                        loading={isSubmitting}
                        onClick={() => onSubmit()}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={updateOpen} onClose={() => setUpdateOpen(false)}>
                <DialogTitle>Kategori Güncelle</DialogTitle>
                <DialogContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} width={400} mt={2}>
                            <RHFTextField name="categoryId" label="Kategori Adı" />
                            <RHFTextField name="description" multiline rows={4} label="Açıklama" />
                            <RHFUploadCategoryImage
                                name="imageUrl"
                                maxSize={3145728}
                                onDrop={handleDrop}
                            />
                            <RHFSelect native name="defaultSorting" label="Sıralama Türü" placeholder="Sıralama Türü">
                                {[{
                                    value: 'createdAtDesc',
                                    name: 'Oluşturulma Tarihi',
                                },
                                {
                                    value: 'createdAtDescWithPriority',
                                    name: 'Öncelik ve Oluşturulma Tarihi',
                                },
                                {
                                    value: 'ratingDescCreatedAtDesc',
                                    name: 'Puan ve Oluşturulma Tarihi',
                                },
                                {
                                    value: 'ratingDescCreatedAtDescWithPriority',
                                    name: 'Puan, Öncelik ve Oluşturulma Tarihi',
                                }
                                ].map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item?.name}
                                    </option>
                                ))}
                            </RHFSelect>
                        </Stack>

                    </FormProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUpdateOpen(false)}>Vazgeç</Button>
                    <Button
                        loading={isSubmitting}
                        onClick={() => onSubmit()}
                    >
                        Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <DialogTitle>Kalıcı Olarak Sil</DialogTitle>
                <DialogContent>
                    <p>Kategoriyi her yerden silmek üzeresiniz. Kalıcı olarak silmek istediğinize emin misiniz?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>Vazgeç</Button>
                    <Button
                        variant='contained'
                        color='error'
                        onClick={() => {
                            handleDelete(selectedRow?._id)
                            setDeleteOpen(false)
                        }}
                    >
                        Kalıcı Olarak  Sil
                    </Button>
                </DialogActions>
            </Dialog>



        </Container>
    )
}
