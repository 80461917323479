/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { ageIntervalsForListing, companionshipsForListing, formatsForListing, parkingForLocation } from 'src/hophop/utils/_enums';
import { useState } from 'react';
import { Box, Card, Chip, Dialog, Skeleton, Stack, TextField, Typography } from '@mui/material';
import TextMaxLine from 'src/components/text-max-line/text-max-line';
import Image from 'src/components/image/image';
//

// ----------------------------------------------------------------------

export default function LocationTableRow({ row, selected, onApproveRow, onRejectRow }) {

  const approvePopup = useBoolean();
  const rejectPopup = useBoolean();
  const detailPopup = useBoolean();

  const [rejectionText, setRejectionText] = useState("")

  const format = formatsForListing?.find(item => item?.value === row?.format)?.name


  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={row?.title} src={row?.coverImage ?? ""} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.title}
            secondary={row?.description}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.category}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.createdBy?.displayName}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell> */}

        <TableCell>
          <Label
            variant="soft"
            color={
              (row?.status === 'exists' && 'success') ||
              (row?.status === 'limitid' && 'warning') ||
              (row?.status === 'pendingApproval' && 'error') ||
              'default'
            }
          >
            {/* {parkingForLocation?.find(item => item?.value === row?.parking)?.name} */}
            {
              (row?.status === 'exists' && 'success') ||
              (row?.status === 'limitid' && 'warning') ||
              (row?.status === 'pendingApproval' && 'Onay Bekliyor') ||
              'Onay bekliyor'
            }
          </Label>
        </TableCell>

        {
          row?.status !== "approved" && (
            <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

              <Tooltip title="Detay" placement="top" arrow>
                <IconButton color={detailPopup.open ? 'inherit' : 'default'} onClick={detailPopup.onTrue} sx={{ color: 'primary.main' }}>
                  <Iconify icon="typcn:eye" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Onayla" placement="top" arrow>
                <IconButton color={approvePopup.value ? 'inherit' : 'default'} onClick={approvePopup.onTrue}>
                  <Iconify icon="typcn:tick" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reddet" placement="top" arrow>
                <IconButton color={rejectPopup.open ? 'inherit' : 'default'} onClick={rejectPopup.onTrue} sx={{ color: 'error.main' }}>
                  <Iconify icon="entypo:cross" />
                </IconButton>
              </Tooltip>

            </TableCell>
          )
        }

      </TableRow>

      <Dialog
        open={detailPopup.value}
        onClose={detailPopup.onFalse}
        sx={{
          '& > * > *': {
            width: { xs: "90%" },
            borderRadius: 1,
            p: 1
          }
        }}
      >

        <Stack component={Card} direction="row">
          <Stack
            sx={{
              p: (theme) => theme.spacing(3, 3, 2, 3),
              flex: 1,
            }}
          >

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>

              <Stack direction="row" justifyContent="space-between" flexWrap="wrap" width="100%">

                <Stack direction="row" spacing={1}>
                  <Avatar alt={row?.address?.title} src={row?.createdBy?.displayImageUrl ?? ""} sx={{ mr: 1 }} />

                  <ListItemText
                    primary={row?.createdBy?.displayName}
                    // secondary={row?.createdBy?.description ?? "-"}
                    secondary={
                      <Box component="span" sx={{ typography: 'caption', color: 'text.disabled' }}>
                        {
                          new Intl.DateTimeFormat("tr", {
                            dateStyle: "medium"
                          }).format(new Date(row?.createdAt))
                        }
                      </Box>
                    }
                    primaryTypographyProps={{ typography: 'body2' }}
                    secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                  />
                </Stack>

                <Stack direction="row">
                  <Tooltip title="Onayla" placement="top" arrow>
                    <IconButton color={approvePopup.value ? 'inherit' : 'default'} onClick={approvePopup.onTrue}>
                      <Iconify icon="typcn:tick" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Reddet" placement="top" arrow>
                    <IconButton color={rejectPopup.open ? 'inherit' : 'default'} onClick={rejectPopup.onTrue} sx={{ color: 'error.main' }}>
                      <Iconify icon="entypo:cross" />
                    </IconButton>
                  </Tooltip>
                </Stack>

              </Stack>




            </Stack>

            <Stack spacing={1} flexGrow={1}>
              {
                row?.title ? (
                  <TextMaxLine variant="subtitle2" line={2}>
                    {row?.title}
                  </TextMaxLine>
                ) : (
                  <Skeleton variant="text" width={100} height={20} />
                )
              }

              {
                row?.description ? (
                  <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }}>
                    {row?.description}
                  </TextMaxLine>
                ) : (
                  <Skeleton variant="rounded" width={200} height={60} />
                )
              }

            </Stack>


            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {
                row?.category ? (
                  <TextMaxLine variant="body2" sx={{ textTransform: 'capitalize' }}>
                    Kategori : {row?.category}
                  </TextMaxLine>
                ) : (
                  <Skeleton variant="text" width={200} height={20} />
                )
              }
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {
                row?.ageInterval?.length ? (
                  <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                    <TextMaxLine variant="body2" sx={{ whiteSpace: "nowrap" }}>
                      Yaş aralığı :
                    </TextMaxLine>
                    {
                      row?.ageInterval?.map((option, index) => (
                        <Chip
                          // {...getTagProps({ index })}
                          key={option}
                          label={ageIntervalsForListing.find(item => item?.value === option)?.name}
                          size="small"
                          color="info"
                          variant="soft"
                        />
                      ))
                    }
                  </Stack>
                ) : (
                  <Skeleton variant="text" width={200} height={20} />
                )
              }
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {
                row?.companionship ? (
                  <TextMaxLine variant="body2" sx={{ textTransform: 'capitalize' }}>
                    Aile katılımı : {companionshipsForListing?.find(item => item?.value === row?.companionship)?.name}
                  </TextMaxLine>
                ) : (
                  <Skeleton variant="text" width={200} height={20} />
                )
              }
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {
                format ? (
                  <Label variant="soft" color={(row?.format === 'online' && 'info') || (row?.format === 'prerecorded' && 'warning') || 'primary'}>
                    {format}
                  </Label>
                ) : (
                  <Skeleton variant="text" width={50} height={30} />
                )
              }
            </Stack>

          </Stack>

          {
            // mdUp
            true
            && (
              <Box sx={{ width: 180, height: 240, position: 'relative', flexShrink: 0, p: 1 }}>
                {/* <Avatar
                alt={row?.author?.name}
                src={row?.author?.avatarUrl}
                sx={{ position: 'absolute', top: 16, right: 16, zIndex: 9 }}
              /> */}

                {/* <IconButton
                  color={popover.open ? 'inherit' : 'default'}
                  onClick={popover.onOpen}
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 9,
                    backgroundColor: '#ffffff99',
                    '&:hover': {
                      backgroundColor: '#ffffff66',

                    },
                  }}
                >
                  <Iconify icon="eva:more-horizontal-fill" />
                </IconButton> */}

                <Box
                  sx={{
                    height: 1,
                    position: 'relative',
                    borderRadius: 1.5
                  }}
                >
                  {
                    (row?.coverImage?.preview ?? row?.coverImage) ? (
                      <Image alt={row?.title} src={row?.coverImage?.preview ?? row?.coverImage} sx={{ height: 1, borderRadius: 1.5 }} />
                    ) : (
                      <Skeleton variant="rounded" width={160} height={220} />
                    )
                  }

                  {
                    row?.status !== "approved" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 10,
                          backgroundColor: "#ffffff77",
                          left: 10,
                          right: 10,
                          p: 2,
                          borderRadius: 1.5
                        }}
                      >

                        <ListItemText
                          primary={
                            <Label
                              variant="soft"
                              color={
                                (row?.status === 'pendingApproval' && 'warning') ||
                                (row?.status === 'approved' && 'success') ||
                                (row?.status === 'rejected' && 'error') ||
                                'default'
                              }
                            >
                              {
                                row?.status === "pendingApproval"
                                  ? "Onay Bekleniyor"
                                  : row?.status === "approved"
                                    ? "Onaylı"
                                    : "Reddedildi"

                              }
                            </Label>
                          }
                          secondary={row?.rejectionDetail ?? ""}
                          primaryTypographyProps={{
                            typography: 'body2'
                          }}
                          secondaryTypographyProps={{ component: 'span', color: '#fff', mt: 1 }}
                        />

                      </Box>
                    )
                  }
                </Box>



              </Box>
            )
          }
        </Stack>

      </Dialog>


      <ConfirmDialog
        open={approvePopup.value}
        onClose={approvePopup.onFalse}
        title="Listeleme onayla"
        content="Listelemeyi onaylamak istediğinize eminmisiniz?"

        action={
          <Button variant="contained" color="success" onClick={() => {
            onApproveRow(row?._id)
            approvePopup.onFalse()
          }}
          >
            Onayla
          </Button>
        }
      />

      <ConfirmDialog
        open={rejectPopup.value}
        onClose={rejectPopup.onFalse}
        title="Listeleme Reddet"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3}>
            <TextField
              name="rejectionText"
              label="Reddetme sebebi"
              value={rejectionText}
              onChange={(e) => setRejectionText(e.target.value)}
              sx={{
                width: '100%',
                mt: 2
              }}
            />
            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={rejectionText === ""}
                variant="contained"
                color="error"
                onClick={() => {
                  onRejectRow({ id: row?._id, rejectionText })
                  rejectPopup.onFalse()
                }}
              >
                Reddet
              </Button>

              <Button variant="outlined" color="inherit" onClick={rejectPopup.onFalse}>
                iptal
              </Button>
            </Stack>
          </Stack>
        }
      />


    </>
  );
}

LocationTableRow.propTypes = {
  onApproveRow: PropTypes.func,
  onRejectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
