import PropTypes from 'prop-types';
import { Avatar, TableRow, TableCell, ListItemText, Stack, Rating, Button } from '@mui/material';

import { useRouter } from 'src/routes/hook';
import Label from 'src/components/label/label';
import { formatsForListing } from 'src/hophop/utils/_enums';


export default function LocationTableRow({ row, selected }) {

  const router = useRouter()

  return (
    <TableRow hover selected={selected} >

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={row?.title} src={row?.coverImage} sx={{ mr: 2 }} />

        <ListItemText
          primary={row?.title}
          secondary={row?.description?.slice(0, 30)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {

          new Intl.DateTimeFormat("tr", {
            dateStyle: "long"
          }).format(new Date(row?.createdAt))

        }
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label variant="soft" color={(row?.format === 'online' && 'info') || (row?.format === 'prerecorded' && 'warning') || 'primary'}>
          {formatsForListing?.find(item => item?.value === row?.format)?.name}
        </Label>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.category}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', }} >
        <Stack direction="row" gap={1} alignItems="center">
          <Rating name="half-rating" defaultValue={2.5} value={row?.rating?.average ?? 5} precision={0.5} />

          <span>
            ({row?.rating?.numberOfReviews})
          </span>
        </Stack>
      </TableCell>



      <TableCell sx={{ whiteSpace: 'nowrap', }} >
        <Button variant='contained' onClick={() => router.push(`/yorumlar/${row?._id}`)} disabled={!row?.rating?.numberOfReviews}>
          Görüntüle
        </Button>
      </TableCell>



    </TableRow>
  );
}

LocationTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
