/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Tab, Tabs, Box } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';

import { useSettingsContext } from 'src/components/settings'
// import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import AccountChangePassword from './sections/AccountChangePassword';
import PrivacyPolicy from './sections/PrivacyPolicy';
import UserAgreement from './sections/UserAgreement';


const Settings = () => {

    const { themeStretch } = useSettingsContext();
    const [currentTab, setCurrentTab] = useState('change-password');

    const TABS = [
        {
            value: 'change-password',
            label: 'Şifre Değiştir',
            icon: <Iconify icon="ic:round-account-box" />,
            component: <AccountChangePassword />,
        },
        {
            value: 'privacy-policy',
            label: 'Gizlilik Politikası',
            icon: <Iconify icon="codicon:gist-secret" />,
            component: <PrivacyPolicy />,
        },
        {
            value: 'user-agreement',
            label: 'Kullanıcı Sözleşmesi',
            icon: <Iconify icon="ic:round-receipt" />,
            component: <UserAgreement />,
        },

    ];

    return (
        <>
            <Helmet>
                <title> Ayarlar </title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>

                <CustomBreadcrumbs heading="Ayarlar" />

                <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
                    {TABS.map((tab) => (
                        <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                {TABS.map(
                    (tab) =>
                        tab.value === currentTab && (
                            <Box key={tab.value} sx={{ mt: 5 }}>
                                {tab?.component}
                            </Box>
                        )
                )}

            </Container>
        </>
    )
}

export default Settings