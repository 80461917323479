/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    locationsForModerator: [],
    locationsForOrganizer: [],

    allOrganizerLocationsForModerator: [],

    selectedlocationForModerator: null,
    selectedlocationForOrganizer: null,
    loading: false,
    error: false,
}

// moderator locations
export const fetchLocationsForModeratorFunc = createAsyncThunk('location/fetchLocationsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.locationsForModerator.allLocations({ variables })
    }))
    return response?.data
})

// moderator selected location 
export const fetchSelectedlocationForModeratorFunc = createAsyncThunk('location/fetchSelectedlocationForModeratorFunc', async ({ accessToken, locationId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.locationsForModerator.locationDetail({ locationId })
    }))
    return response?.data
})


// organizer locations
export const fetchLocationsForOrganizatorFunc = createAsyncThunk('location/fetchLocationsForOrganizatorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.locationsForOrganizer.allLocations({ variables })
    }))
    return response?.data
})

// organizer selected location
export const fetchSelectedlocationForOrganizerFunc = createAsyncThunk('location/fetchSelectedlocationForOrganizerFunc', async ({ accessToken, locationId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.locationsForOrganizer.locationDetail({ locationId })
    }))

    // 
    return response?.data
})


// all organizer locations for moderator approve
export const fetchAllOrganizerLocationsForModeratorFunc = createAsyncThunk('location/fetchAllOrganizerLocationsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.locationsForModerator.allOrganizerLocationsForModerator({ variables })
    }))
    return response?.data
})



const { reducer: locationReducer } = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# moderator all list ######
        builder.addCase(fetchLocationsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchLocationsForModeratorFunc.fulfilled, (state, action) => {

            state.locationsForModerator = action.payload?.data?.moderator_locationsOfModerators
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchLocationsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# moderator item detail ######
        builder.addCase(fetchSelectedlocationForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedlocationForModeratorFunc.fulfilled, (state, action) => {

            state.selectedlocationForModerator = action.payload?.data?.moderator_location
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedlocationForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // ################# organizator all list ######
        builder.addCase(fetchLocationsForOrganizatorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchLocationsForOrganizatorFunc.fulfilled, (state, action) => {

            state.locationsForOrganizer = action.payload?.data?.organizer_locations
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchLocationsForOrganizatorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# organizator item detail ######
        builder.addCase(fetchSelectedlocationForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedlocationForOrganizerFunc.fulfilled, (state, action) => {

            state.selectedlocationForOrganizer = action.payload?.data?.organizer_location
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedlocationForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })



        // ################# all organizator locations list for moderator ######
        builder.addCase(fetchAllOrganizerLocationsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchAllOrganizerLocationsForModeratorFunc.fulfilled, (state, action) => {

            state.allOrganizerLocationsForModerator = action.payload?.data?.moderator_locationsOfOrganizers
            // {
            //     data: action.payload?.data?.moderator_locationsOfOrganizers?.data?.filter(item => item?.status === "pendingApproval"),
            //     count: action.payload?.data?.moderator_locationsOfOrganizers?.data?.filter(item => item?.status === "pendingApproval")?.length
            // }
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchAllOrganizerLocationsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

    }
})


export default locationReducer

export const locationFunc = {
    fetchLocationsForModeratorFunc,
    fetchLocationsForOrganizatorFunc,
    fetchSelectedlocationForModeratorFunc,
    fetchSelectedlocationForOrganizerFunc,

    fetchAllOrganizerLocationsForModeratorFunc,
}