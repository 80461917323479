import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export default function RHFTextField({ name, helperText, placeholder, type, max, warning, ...other }) {
  const { control } = useFormContext();

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, ''); 
    if (phoneNumber.length <= 3) {
      return phoneNumber;
    }
    if (phoneNumber.length <= 10) {
      return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 10)}`;
  };

  const formatIBAN = (value) => {
    const cleanIban = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(); 
    let formattedIban = '';

    for (let i = 0; i < cleanIban.length; i += 4) {
      formattedIban += `${cleanIban.slice(i, i + 4)} `;
    }

    return formattedIban.trim();
  };

  const removeFormatting = (value) => {
    return value.replace(/[^a-zA-Z0-9]/g, ''); 
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          placeholder={placeholder}
          onWheel={(event) => event.target.blur()}
          value={
            name === 'phoneNumber'
              ? formatPhoneNumber(field.value)
              : name === 'iban'
              ? formatIBAN(field.value)
              : field.value
          }
          onChange={(event) => {
            const value = event.target.value.toUpperCase();

            if (name === 'iban' && removeFormatting(value).length > 26) return;

            if (name === 'phoneNumber') {
              field.onChange(removeFormatting(event.target.value));
            } else if (name === 'iban') {
              field.onChange(removeFormatting(value));
            } else if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  warning: PropTypes.bool,
};
