import Box from '@mui/material/Box';
import Scrollbar from 'src/components/scrollbar';
import { useSelector } from 'react-redux';

import { useMessagesScroll } from './hooks';
import ChatMessageItem from './chat-message-item';


export default function ChatMessageList() {

  const { selectedUserMessages } = useSelector(state => state?.message)
  const messages = [...(selectedUserMessages?.data ?? [])]?.reverse()
  const { messagesEndRef } = useMessagesScroll(messages);

  return (
    <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 5, height: 1 }}>
      <Box>
        {messages.map((message) => (
          <ChatMessageItem
            key={message?._id}
            message={message}
          />
        ))}
      </Box>
    </Scrollbar>
  );
}

ChatMessageList.propTypes = {};
