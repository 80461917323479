/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Avatar,
  // Checkbox,
  Stack,
  TextField,
  Button,
  Tooltip,
  TableRow,
  IconButton,
  ListItemText,
  TableCell,
} from '@mui/material';

import { graphqlApi } from 'src/hophop/api';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { reduxFunc } from 'src/hophop/redux';
import { useCallback, useState } from 'react';
import Image from 'src/components/image/image';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { ConfirmDialog } from 'src/components/custom-dialog';



export default function CustomersTableRow({ row, selected }) {

  const dispatch = useDispatch()
  const messagePopup = useBoolean();
  const notificationPopup = useBoolean();
  const { handleFetchData } = useFetchData()
  const [message, setMessage] = useState('')
  const [notifcation, setNotification] = useState("")
  const { accessToken } = useSelector(state => state.auth)
  const [selectedActivity, setSelectedActivity] = useState(null)


  const handleSendMessage = useCallback(async (id) => {

    try {

      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
          activityId: selectedActivity?._id,
          attachments: [],
          content: message,
          parentIds: [{ "_id": row?._id }]
        }),
        accessToken,
        url: "/activity/graphql",
      })



      if (response?.data?.data?.moderator_createMessages?.success) {
        enqueueSnackbar('Mesajınız gönderildi!');
        setMessage("")
      } else {
        enqueueSnackbar('Mesaj iletilemedi!', {
          variant: "error"
        })
      }

    } catch (error) {


      enqueueSnackbar('Mesaj iletilemedi!', {
        variant: "error"
      })

    }

  }, [accessToken, handleFetchData, message, row?._id, selectedActivity?._id]);


  const handleSendNotification = useCallback(async (id) => {

    try {

      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({ locationId: id }),
        accessToken,
        url: "/activity/graphql",
      })



      if (response?.data?.data?.moderator_removeLocation?.success) {
        enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
        dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }))
      } else {
        enqueueSnackbar('Bildirim gönderilemedi!', {
          variant: "error"
        })
      }


    } catch (error) {


      enqueueSnackbar('Bildirim gönderilemedi!', {
        variant: "error"
      })

    }

  }, [accessToken, dispatch, handleFetchData]);


  return (
    <>
      <TableRow hover selected={selected}  >

        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {
            row?.displayImageUrl?.startsWith("https://")
              ? (<Avatar alt={row?.displayName} src={row?.displayImageUrl} sx={{ mr: 2 }} />)
              : (<Avatar sx={{ mr: 2 }}>{row?.displayName?.slice(0, 2)?.toUpperCase()}</Avatar>)
          }

          <ListItemText
            primary={row?.displayName}
            // secondary={row?.description ?? "-"}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.description ?? "Açıklama yok."}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.role === "parent" ? "Aile" : "Organizator"}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

          <Tooltip title="Mesaj" placement="top" arrow>
            <IconButton color={messagePopup.value ? 'default' : 'primary'} onClick={messagePopup.onTrue}>
              <Iconify icon="mingcute:send-fill" />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Bildirim" placement="top" arrow>
            <IconButton color={notificationPopup.open ? 'inherit' : 'secondary'} onClick={notificationPopup.onTrue} >
              <Iconify icon="ion:notifcations" />
            </IconButton>
          </Tooltip> */}

        </TableCell>

      </TableRow>


      <ConfirmDialog
        open={messagePopup.value}
        onClose={messagePopup.onFalse}
        title="Mesaj gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3} sx={{ pt: 2 }}>

            <Autocomplete
              name="country"
              label="Country"
              value={selectedActivity}
              onChange={(e, onChangeOption) => {
                setSelectedActivity(onChangeOption)
              }}
              options={row?.activities?.map((country) => country)}
              getOptionLabel={(option) => option?.listing?.title}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {

                return (
                  <li {...props} key={props?.id}>
                    <Image src="" />
                    {option?.listing?.title}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  label="Aktivite"
                  placeholder="Aktivite seç..."
                  error={false}
                  // helperText={false ? "error?.message" : "helperText"}
                  {...params}
                />
              )}
            />

            <TextField
              multiline
              name="message"
              label="Mesaj"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                width: '100%',
              }}
            />

            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={message === "" || !selectedActivity}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSendMessage()
                  messagePopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={messagePopup.onFalse}>
                iptal
              </Button>
            </Stack>

          </Stack>
        }
      />

      <ConfirmDialog
        open={notificationPopup.value}
        onClose={notificationPopup.onFalse}
        title="Bildirim gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3} sx={{ pt: 2 }}>

            <Autocomplete
              name="country"
              label="Country"
              value={selectedActivity}
              onChange={(e, onChangeOption) => {
                setSelectedActivity(onChangeOption)
              }}
              options={row?.activities?.map((country) => country)}
              getOptionLabel={(option) => option?.listing?.title}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props?.id}>
                    <Image src="" />
                    {option?.listing?.title}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  label="Aktivite"
                  placeholder="Aktivite seç..."
                  error={false}
                  // helperText={false ? "error?.message" : "helperText"}
                  {...params}
                />
              )}
            />

            <TextField
              name="notification"
              label="Bildirim"
              value={notifcation}
              onChange={(e) => setNotification(e.target.value)}
              sx={{
                width: '100%',
              }}
            />

            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={notifcation === ""}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleSendNotification()
                  notificationPopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={notificationPopup.onFalse}>
                iptal
              </Button>
            </Stack>

          </Stack>
        }
      />

    </>
  );
}

CustomersTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
