/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    activitiesForModerator: null,
    activitiesForOrganizer: null,
    selectedActivityForModerator: null,
    selectedActivityForOrganizer: null,
    loading: false,
    error: false,
    settings: null
    
}

// moderator activities
export const fetchActivitiesForModeratorFunc = createAsyncThunk('activity/fetchActivitiesForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.activitiesForModerator.allActivities({ variables })
    }))
    return response?.data
})

// moderator selected activity
export const fetchSelectedActivityForModeratorFunc = createAsyncThunk('activity/fetchSelectedActivityForModeratorFunc', async ({ accessToken, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.activitiesForModerator.activityDetail({ activityId })
    }))
    return response?.data
})


// organizer activities
export const fetchActivitiesForOrganizatorFunc = createAsyncThunk('activity/fetchActivitiesForOrganizatorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.activitiesForOrganizer.allActivities({ variables })
    }))
    console.log({ response })
    return response?.data
})

// organizer selected activitie
export const fetchSelectedActivityForOrganizerFunc = createAsyncThunk('activity/fetchSelectedActivityForOrganizerFunc', async ({ accessToken, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.activitiesForOrganizer.activityDetail({ activityId })
    }))

    // 
    return response?.data
})


// anonymous sett'ngs
export const fetchSettingsFunc = createAsyncThunk('activity/fetchSettingsFunc', async ({ accessToken }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.activitiesForModerator.settings()
    }))

    return response?.data
})



const { reducer: activityReducer, actions } = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        resetSelectedActivity: (state, action) => {
            state.selectedActivityForModerator = null
            state.selectedActivityForOrganizer = null
        }
    },
    extraReducers: (builder) => {
        // ################# moderator all activities ######
        builder.addCase(fetchActivitiesForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchActivitiesForModeratorFunc.fulfilled, (state, action) => {

            state.activitiesForModerator = action.payload?.data?.moderator_activities
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchActivitiesForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# moderator item detail ######
        builder.addCase(fetchSelectedActivityForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedActivityForModeratorFunc.fulfilled, (state, action) => {

            state.selectedActivityForModerator = action.payload?.data?.moderator_activity
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedActivityForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // ################# organizator all activities ######
        builder.addCase(fetchActivitiesForOrganizatorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchActivitiesForOrganizatorFunc.fulfilled, (state, action) => {

            state.activitiesForOrganizer = action.payload?.data?.organizer_activities
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchActivitiesForOrganizatorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# organizator item detail ######
        builder.addCase(fetchSelectedActivityForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedActivityForOrganizerFunc.fulfilled, (state, action) => {

            state.selectedActivityForOrganizer = action.payload?.data?.organizer_activity
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedActivityForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# settings ######
        builder.addCase(fetchSettingsFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSettingsFunc.fulfilled, (state, action) => {
            state.settings = action.payload?.data?.moderator_settings
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSettingsFunc.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })

    }
})


export default activityReducer

export const activityFunc = {
    fetchActivitiesForModeratorFunc,
    fetchActivitiesForOrganizatorFunc,
    fetchSelectedActivityForModeratorFunc,
    fetchSelectedActivityForOrganizerFunc,
    fetchSettingsFunc,

    resetSelectedActivity: actions.resetSelectedActivity

}