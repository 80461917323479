/* eslint-disable arrow-body-style */
import axios from "axios"
import { findImageContentType } from "../../utils"
import { dmsConfig } from "./_dmsConfig"


const listingCoverImagePresignedUrl = async ({ listingId, file, accessToken }) => {

    const contentType = findImageContentType(file)

    const data = JSON.stringify({
        query: `
        query ListingCoverImagePresignedUrl($listingId: ID!, $contentType: ImageContentType!) {
            listingCoverImagePresignedUrl(listingId: $listingId, contentType: $contentType) {
              presignedUploadUrl
              presignedThumbnailUpload
              url
            }
          }
          `,
        variables: {
            "listingId": listingId,
            "contentType": contentType
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.listingCoverImagePresignedUrl
    } catch (err) {
        return err
    }

}

const listingImagePresignedUrlList = async ({ listingId, file, accessToken, imageCount }) => {

    const contentType = findImageContentType(file)

    const data = JSON.stringify({
        query: `
        query ListingImagePresignedUrlList($listingId: ID!, $contentType: ImageContentType!, $imageCount: Int) {
            listingImagePresignedUrlList(listingId: $listingId, contentType: $contentType, imageCount: $imageCount) {
              presignedUploadUrl
              presignedThumbnailUpload
              url
            }
          }
          `,
        variables: {
            "listingId": listingId,
            "contentType": contentType,
            "imageCount": imageCount
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.listingImagePresignedUrlList
    } catch (err) {
        return err
    }

}

const listingExteriorOrgImagePresignedUrl = async ({ listingId, file, accessToken }) => {

    const contentType = findImageContentType(file)

    const data = JSON.stringify({
        query: `
        query ListingExteriorOrgImagePresignedUrl($userId: ID!, $contentType: ImageContentType!) {
            listingExteriorOrgImagePresignedUrl(userId: $userId, contentType: $contentType) {
              presignedThumbnailUpload
              presignedUploadUrl
              url
            }
          }
          `,
        variables: {
            "userId": listingId,
            "contentType": contentType
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.listingExteriorOrgImagePresignedUrl
    } catch (err) {
        return err
    }
}



export const listingImagesPresignedUrls = {
    listingCoverImagePresignedUrl,
    listingImagePresignedUrlList,
    listingExteriorOrgImagePresignedUrl,
}