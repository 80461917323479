import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// utils
import { fDateTime } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { shortDateLabel } from 'src/components/custom-date-range-picker';
import { useState } from 'react';
import { MyMiniSlider } from 'src/hophop/components';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ActivityCard({ tour, onEdit, onDelete, onMakeActive, onMakePassive }) {
  const popover = usePopover();

  // const shortLabel = shortDateLabel(tour?.available?.startDate, tour?.available?.endDate);

  // const renderRating = (
  //   <Stack
  //     direction="row"
  //     alignItems="center"
  //     sx={{
  //       top: 8,
  //       right: 8,
  //       zIndex: 9,
  //       borderRadius: 1,
  //       position: 'absolute',
  //       p: '2px 6px 2px 4px',
  //       typography: 'subtitle2',
  //       bgcolor: 'warning.lighter',
  //     }}
  //   >
  //     <Iconify icon="eva:star-fill" sx={{ color: 'warning.main', mr: 0.25 }} /> {tour?.ratingNumber}
  //   </Stack>
  // );

  const renderPrice = (

    <Stack
      direction="row"
      gap={1}
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        position: 'absolute',
        backgroundColor: tour?.status === "passive" ? "#ffffff99" : "",

        right: 8,
        p: 1,
        borderRadius: 1,

      }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{

          borderRadius: 1,
          bgcolor: 'grey.800',
          p: '2px 6px 2px 4px',
          color: 'common.white',
          typography: 'subtitle2',
        }}
      >
        {!!tour?.priceSale && ( // indirim öncesi hali varsa
          <Box component="span" sx={{ color: 'grey.500', mr: 0.25, textDecoration: 'line-through' }}>
            {fCurrency(tour?.priceSale)}
          </Box>
        )}
        {/* {fCurrency(tour?.price)} */}
        ₺{tour?.price}
      </Stack>

      {
        tour?.status === "passive" && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              // top: 8,
              // right: 8,
              // zIndex: 9,
              borderRadius: 1,
              // position: 'absolute',
              p: '2px 6px 2px 4px',
              typography: 'subtitle2',
              bgcolor: 'error.main',
              color: 'common.white',
            }}
          >
            {/* <Iconify icon="eva:star-fill" sx={{ color: 'warning.main', mr: 0.25 }} /> */}
            {/* {tour?.status} */}
            Pasif
          </Stack>
        )
      }
    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack
        flexGrow={1}
        sx={{ position: 'relative' }}
      >
        {renderPrice}
        {/* {renderRating} */}

        <MyMiniSlider
          autoStart={false}
          list={[tour?.listing?.coverImage, ...(tour?.listing?.images ?? [])]}
          renderItem={(item) => (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url('${item}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
          containerStyle={{
            height: 250
          }}
        />


        {/* <Image alt={tour?.listing?.title} src={tour?.listing?.coverImage} sx={{ borderRadius: 1, height: 164, width: 1 }} /> */}
      </Stack>
      {/* <Stack spacing={0.5}>
        <Image alt={tour?.listing?.title} src={tour?.listing?.images?.[0]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
        <Image alt={tour?.listing?.title} src={tour?.listing?.images?.[1]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
      </Stack> */}
    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      primary={`Posted date: ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.createdAt))}`}
      secondary={
        <Link
          component={Typography}
          // component={RouterLink} 
          // href={`/aktiviteler/${tour?._id}`} 
          color="inherit"
        >
          {tour?.title}
        </Link>
      }
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,
        noWrap: true,
        component: 'span',
        color: 'text.primary',
        typography: 'subtitle1',
      }}
    />
  );

  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
      }}
    >
      <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 20, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      {[
        {
          label: tour?.location?.address?.title,
          icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
        },
        {
          label: `${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.schedule?.startDate))}
          ${tour?.schedule?.totalDayCount !== 1 ? "-" : ""}
            ${tour?.schedule?.totalDayCount !== 1 ? new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.schedule?.endDate)) : ""}`,
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: `${tour?.quota} Kişi (Kontenjan)`,
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
        },
      ].map((item, index) => (
        <Stack
          key={index}
          // key={item.label}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          {item.icon}
          {item.label}
        </Stack>
      ))}
    </Stack>
  );


  return (
    <>
      <Card
        sx={{
          ...(tour?.status === "passive" && { backgroundColor: '#f1f1f1' }),
          transitionDuration: '0,3',
          boxShadow: '1px 1px 10px 0px #d9d9d9',
          // '&:hover': {
          //   boxShadow: '1px 1px 20px 0px #ccc'
          // },
        }}

      >
        {renderImages}

        {renderTexts}

        {renderInfo}
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {
          tour?.status === "passive" ? (
            <>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onMakeActive();
                }}
              >
                <Iconify icon="heroicons-solid:status-online" />
                Aktif Yap
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onEdit();
                }}
              >
                <Iconify icon="solar:pen-bold" />
                Düzenle
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onDelete();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
                Sil
              </MenuItem>

              {/* <MenuItem
          onClick={() => {
            popover.onClose();
            onMakePassive();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem> */}
            </>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onMakePassive();
                }}
              >
                <Iconify icon="heroicons-solid:status-offline" />
                Pasif Yap
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onEdit();
                }}
              >
                <Iconify icon="solar:pen-bold" />
                Düzenle
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  popover.onClose();
                  onDelete();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
                Sil
              </MenuItem> */}
            </>
          )
        }
      </CustomPopover>
    </>
  );
}

ActivityCard.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onMakeActive: PropTypes.func,
  onMakePassive: PropTypes.func,
  tour: PropTypes.object,
};
