import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tr } from 'date-fns/locale'; // Türkçe lokalizasyonunu içe aktarın
import { formatDistanceToNow } from 'date-fns'; // date-fns'ı doğrudan içe aktarın
import { Avatar, Checkbox, Stack, TextField, Button, ListItemText, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';

import Label from 'src/components/label';
import { graphqlApi } from 'src/hophop/api';
import Iconify from 'src/components/iconify';
import { reduxFunc } from 'src/hophop/redux';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { reservationStatus } from 'src/hophop/utils/_enums';



export default function LocationTableRow({ row, selected, onSelectRow }) {

  const dispatch = useDispatch()
  const messagePopup = useBoolean();
  const notificationPopup = useBoolean();
  const { handleFetchData } = useFetchData()
  const { accessToken } = useSelector(state => state.auth)

  const [message, setMessage] = useState("")
  const [notifcation, setNotification] = useState("")


  const handleSendMessage = useCallback(async (id) => {

    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
          activityId: row?.activity?._id,
          attachments: [],
          content: message,
          parentIds: [
            {
              "_id": row?.parent?._id
            }
          ]
        }),
        accessToken,
        url: "/activity/graphql",
      })


      if (response?.data?.data?.moderator_createMessages?.success) {
        enqueueSnackbar('Mesajınız gönderildi!');
        setMessage("")
      } else {
        enqueueSnackbar('Mesaj iletilemedi!', {
          variant: "error"
        })
      }

      // if (response?.data?.errors) {

      //   response?.data?.errors?.forEach((item, index) => {

      //     if (item?.message === "There are activities are referenced to the location") {
      //       enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
      //         variant: "error"
      //       })
      //     }
      //   })

      // }

    } catch (error) {
      console.error({ error })
    }

  }, [accessToken, handleFetchData, message, row?.activity?._id, row?.parent?._id]);

  const handleSendNotification = useCallback(async (id) => {

    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({ locationId: id }),
        accessToken,
        url: "/activity/graphql",
      })


      if (response?.data?.data?.moderator_removeLocation?.success) {
        enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
        dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }))
      }

      if (response?.data?.errors) {
        response?.data?.errors?.forEach((item, index) => {

          if (item?.message === "There are activities are referenced to the location") {
            enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
              variant: "error"
            })
          }
        })
      }

    } catch (error) {
      console.error({ error })
    }

  }, [accessToken, dispatch, handleFetchData]);

  return (
    <>

      <TableRow hover selected={selected}>

        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={row?.parent?.displayName} src={row?.parent?.displayImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.parent?.displayName}
            // secondary={row?.parent?.description ?? "-"}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {formatDistanceToNow(new Date(row?.createdAt), { addSuffix: true, locale: tr })}
        </TableCell>


        <TableCell>
          <Label
            variant="soft"
            color={
              (row?.status === 'reserved' && 'success') ||
              (row?.status === 'pending' && 'warning') ||
              (row?.status === 'canceled' && 'error') ||
              'default'
            }
          >
            {reservationStatus?.find(item => item?.value === row?.status)?.name}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

          <Tooltip title="Mesaj" placement="top" arrow>
            <IconButton color={messagePopup.value ? 'default' : 'primary'} onClick={messagePopup.onTrue}>
              <Iconify icon="mingcute:send-fill" />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Bildirim" placement="top" arrow>
            <IconButton color={notificationPopup.open ? 'inherit' : 'secondary'} onClick={notificationPopup.onTrue} >
              <Iconify icon="ion:notifcations" />
            </IconButton>
          </Tooltip> */}

        </TableCell>
      </TableRow>



      <ConfirmDialog
        open={messagePopup.value}
        onClose={messagePopup.onFalse}
        title="Mesaj gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3}>
            <TextField
              multiline
              name="message"
              label="Mesaj"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                width: '100%',
                mt: 2
              }}
            />
            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={message === ""}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSendMessage()
                  messagePopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={messagePopup.onFalse}>
                iptal
              </Button>
            </Stack>
          </Stack>
        }
      />

      <ConfirmDialog
        open={notificationPopup.value}
        onClose={notificationPopup.onFalse}
        title="Bildirim gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3}>
            <TextField
              name="notification"
              label="Bildirim"
              value={notifcation}
              onChange={(e) => setNotification(e.target.value)}
              sx={{
                width: '100%',
                mt: 2
              }}
            />
            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={notifcation === ""}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleSendNotification()
                  notificationPopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={notificationPopup.onFalse}>
                iptal
              </Button>
            </Stack>
          </Stack>
        }
      />

    </>
  );
}

LocationTableRow.propTypes = {
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
