export const dmsConfig = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/dms/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data
});
