/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { reduxFunc } from 'src/hophop/redux';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';

import { parkingForLocation } from 'src/hophop/utils/_enums';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';


const FormThree = ({ values, setValue, control, errors }) => {

    const dispatch = useDispatch()
    const [isAddNew, setIsAddNew] = useState(JSON.parse(sessionStorage.getItem("organizerCreateActivityLocationAddNew")))
    const { accessToken } = useSelector(state => state.auth)
    const { cities, locations } = useSelector(state => state.parameter)
    const { selectedlocationForOrganizer } = useSelector(state => state.location)
    const { selectedActivityForOrganizer } = useSelector((state) => state?.activity);


    useEffect(() => {
        if (locations?.length === 0) {
            setIsAddNew(true)
            sessionStorage.setItem("organizerCreateActivityListingAddNew", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedActivityForOrganizer) {
            setIsAddNew(true)
            sessionStorage.setItem("organizerCreateActivityListingAddNew", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedActivityForOrganizer])

    useEffect(() => {
        if (isAddNew) {
            setValue("locationId", "")
        } else {
            setValue("locationId", selectedlocationForOrganizer?._id)
        }
    }, [isAddNew, selectedlocationForOrganizer?._id, setValue])


    useEffect(() => {
        if (values?.locationId) {
            dispatch(reduxFunc.locationFunc.fetchSelectedlocationForOrganizerFunc({
                accessToken,
                locationId: values?.locationId
            }))
        }
    }, [accessToken, dispatch, values?.locationId])



    const handleSetIsAddNew = () => {
        setIsAddNew(!isAddNew)
        sessionStorage.setItem("organizerCreateActivityLocationAddNew", !isAddNew)
    }



    const [selectedCity, setSelectedCity] = useState(null)
    useEffect(() => {
        setSelectedCity(cities?.data?.find(item => item?.name === values?.location?.city))
    }, [cities?.data, values?.location?.city])



    useEffect(() => {

        if (selectedlocationForOrganizer && selectedCity) {

            setValue("location.address.title", selectedlocationForOrganizer?.address?.title)
            setValue("location.address.description", selectedlocationForOrganizer?.address?.description)
            setValue("location.parking", selectedlocationForOrganizer?.parking)
            setValue("location.city", selectedlocationForOrganizer?.city)
            setValue("location.district", selectedlocationForOrganizer?.district)
            // setValue("location.subCategory", selectedlocationForOrganizer?.subCategory)
            setValue("location.lat", selectedlocationForOrganizer?.lat)
            setValue("location.long", selectedlocationForOrganizer?.long)

            if (!isAddNew) {
                setValue("locationId", selectedlocationForOrganizer?._id)
            }

        }
    }, [isAddNew, selectedCity, selectedlocationForOrganizer, setValue])


    return (

        <>
            {
                values?.listing?.format === "faceToFace" ? (
                    <>

                        {
                            locations?.length !== 0 && (
                                <Grid container spacing={3} mb={3}>

                                    <Grid item xs={12} md={8}>
                                        <RHFSelect name="locationId" label="Konumlar">
                                            {locations?.map((status) => (
                                                <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                                                    {status?.address?.title?.toLocaleLowerCase()}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            onClick={handleSetIsAddNew}
                                            variant='contained'
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                (!selectedlocationForOrganizer && isAddNew) && 'İptal'
                                                ||
                                                (!selectedlocationForOrganizer && !isAddNew) && 'Ekle'
                                                ||
                                                (selectedlocationForOrganizer && isAddNew) && "İptal"
                                                ||
                                                (selectedlocationForOrganizer && !isAddNew) && "Değiştir"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }

                        {
                            (isAddNew || values?.locationId) && (
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={6} >
                                        <RHFTextField name="location.address.title" label="Konum Adı" disabled={!isAddNew} />
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <RHFSelect name="location.parking" label="Park Yeri" disabled={!isAddNew} >
                                            {(parkingForLocation)?.map((status, i) => (
                                                <MenuItem key={status?.value ?? i} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                                    {status?.name?.toLocaleLowerCase()}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <RHFSelect name="location.city" label="İl" disabled={!isAddNew} >
                                            {(cities?.data ?? [])?.map((status, i) => (
                                                <MenuItem key={status?._id ?? i} value={status?.name} sx={{ textTransform: 'capitalize' }}>
                                                    {status?.name?.toLocaleLowerCase()}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                    </Grid>

                                    {
                                        selectedCity && (
                                            <Grid item xs={12} md={6} >
                                                <RHFSelect name="location.district" label="İlçe" disabled={!isAddNew} >
                                                    <MenuItem value="" sx={{ textTransform: 'capitalize' }}>
                                                        {/*  */}
                                                    </MenuItem>
                                                    {(selectedCity?.districts || [])?.map((status, i) => (
                                                        <MenuItem key={status ?? i} value={status} sx={{ textTransform: 'capitalize' }}>
                                                            {status.toLocaleLowerCase()}
                                                        </MenuItem>
                                                    ))}
                                                </RHFSelect>
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs={12}  >
                                        <RHFTextField multiline rows={4} name="location.address.description" label="Adres" disabled={!isAddNew} />
                                    </Grid>

                                    <Grid item xs={12}  >
                                        <GoogleMapComponent setValue={setValue} errors={errors} values={values} disabled={!isAddNew} />
                                    </Grid>


                                </Grid>
                            )
                        }
                    </>
                ) : (
                    <Typography sx={{ textAlign: 'center' }}>Aktivitenin formatı online.</Typography>
                )
            }
        </>
    );
};


const GoogleMapComponent = ({ setValue, errors, values, disabled }) => {

    const defaultProps = {
        center: {
            lat: 41.01258256731506,
            lng: 28.997054894224362,
        },
        zoom: 8
    };

    const [defaultValues, setDefaultValues] = useState(defaultProps.center)

    useEffect(() => {
        setDefaultValues({
            lat: values?.location?.lat,
            lng: values?.location?.long,
        })
    }, [values?.location?.lat, values?.location?.long])


    return (
        <>
            <Box
                sx={{
                    height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    border: errors?.lat?.message ? "3px solid #FF5630" : "",

                }}
            >
                <GoogleMapReact

                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    // center={defaultValues}

                    draggable
                    onDrag={(e) => null}
                    // onChange={(e) => null}
                    onClick={(e) => {


                        if (!disabled) {

                            setDefaultValues({
                                lat: e.lat,
                                lng: e.lng
                            })
                            setValue("location.lat", e.lat, { shouldDirty: true })
                            setValue("location.long", e.lng, { shouldDirty: true })
                        }
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        borderRadius: '8px'
                    }}
                >
                    <AnyReactComponent
                        lat={defaultValues.lat}
                        lng={defaultValues.lng}
                    // text="IYTE"
                    />
                </GoogleMapReact>
            </Box>

            <Typography
                sx={{
                    color: '#666',
                    fontSize: '12px',
                    mt: '5px'
                }}
            >
                *Konum seçmek veya değiştirmek için haritada tıklama yapmalısınız.
            </Typography>

            <Typography
                sx={{
                    color: '#FF5630',
                    fontSize: '12px',
                    mt: '5px'
                }}
            >
                {errors?.lat?.message}
            </Typography>
        </>
    );
}


const AnyReactComponent = ({ text = "konum", color = "red" }) => {

    return (
        <Box
            sx={{
                position: 'relative',
                top: '-50px',
                left: '-20px',
                width: '40px',
                height: '40px',
                backgroundColor: color,
                transform: 'rotate(45deg)',
                borderRadius: '50%',
                borderEndEndRadius: '0px',
            }}
        >
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: color,
                    transform: 'rotate(-45deg)',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                }}
            >
                {text}
            </Box>
        </Box>
    )
};

export default FormThree