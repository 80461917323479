/* eslint-disable arrow-body-style */
import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from 'src/routes/hook';
import { reduxFunc } from 'src/hophop/redux';
import { useSettingsContext } from 'src/components/settings';
// import AppWelcome from 'src/sections/overview/app/app-welcome'
// import SeoIllustration from 'src/assets/illustrations/seo-illustration'
import { ChatView } from 'src/hophop/pages/dashboard/moderator/8-messages/chat/view';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

const Messages = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const { accessToken } = useSelector((state) => state?.auth);
  const { moderatorConversations } = useSelector((state) => state.message);

  // konuşmaları listelemek için
  useEffect(() => {
    dispatch(
      reduxFunc.messageFunc.fetchConservationsForModeratorFunc({ variables: null, accessToken })
    );
  }, [accessToken, dispatch]);

  // seçilen konuşmavarsa onun yoksa ilk kişinin mesajlarını listelemek için
  const urlParams = new URLSearchParams(window.location.search);
  const activityId = urlParams.get('activityId');
  const receiverId = urlParams.get('receiverId');
  useEffect(() => {
    if (activityId && receiverId && activityId !== 'undefined' && receiverId !== 'undefined') {
      dispatch(
        reduxFunc.messageFunc.fetchSelectedUserMessagesForModeratorFunc({
          variables: {
            activityId,
            receiverId,
          },
          accessToken,
        })
      );
    } else {
      router.push(
        `/mesajlar?activityId=${moderatorConversations?.data?.[0]?.activity?.activityId?._id}&&receiverId=${moderatorConversations?.data?.[0]?.receiver?._id}`
      );
    }
  }, [accessToken, activityId, dispatch, moderatorConversations?.data, receiverId, router]);

  // url de id leri  bulunan konuşmayı seçmek için
  useEffect(() => {
    if (moderatorConversations?.count) {
      const selected = moderatorConversations?.data?.find(
        (item) =>
          item?.activity?.activityId?._id === activityId && item?.receiver?._id === receiverId
      );
      dispatch(reduxFunc.messageFunc.setSelectedConversation(selected));
    }
  }, [activityId, dispatch, moderatorConversations, receiverId]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <CustomBreadcrumbs
        heading="Mesajlar"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ChatView />
      
    </Container>
  );
};

export default Messages;
