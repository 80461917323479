/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    reservationsForModerator: [],
    reservationsForOrganizer: [],
    selectedReservationForModerator: null,
    selectedReservationForOrganizer: null,
    loading: false,
    error: false,
}

// moderator reservations
export const fetchReservationsForModeratorFunc = createAsyncThunk('reservation/fetchReservationsForModeratorFunc', async ({ accessToken, variables, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.reservationsForModerator.allReservations({ variables, activityId })
    }))
    return response?.data
})

// moderator selected reservation
export const fetchSelectedReservationForModeratorFunc = createAsyncThunk('reservation/fetchSelectedReservationForModeratorFunc', async ({ accessToken, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.reservationsForModerator.reservationDetail({ activityId })
    }))
    return response?.data
})


// organizer reservations
export const fetchReservationsForOrganizatorFunc = createAsyncThunk('reservation/fetchReservationsForOrganizatorFunc', async ({ accessToken, variables, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.reservationsForOrganizer.allReservations({ variables, activityId })
    }))
    return response?.data
})

// organizer selected reservation
export const fetchSelectedReservationForOrganizerFunc = createAsyncThunk('reservation/fetchSelectedReservationForOrganizerFunc', async ({ accessToken, activityId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.reservationsForOrganizer.reservationDetail({ activityId })
    }))
    return response?.data
})



const { reducer: reservationReducer, actions } = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        resetSelectedReservation: (state, action) => {
            state.selectedReservationForModerator = null
            state.selectedReservationForOrganizer = null
        }
    },
    extraReducers: (builder) => {
        // ################# moderator all reservations ######
        builder.addCase(fetchReservationsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchReservationsForModeratorFunc.fulfilled, (state, action) => {

            state.reservationsForModerator = action.payload?.data?.moderator_reservations
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchReservationsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# moderator item detail ######
        builder.addCase(fetchSelectedReservationForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedReservationForModeratorFunc.fulfilled, (state, action) => {

            state.selectedReservationForModerator = action.payload?.data?.moderator_reservation
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedReservationForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // ################# organizator all reservations ######
        builder.addCase(fetchReservationsForOrganizatorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchReservationsForOrganizatorFunc.fulfilled, (state, action) => {

            state.reservationsForOrganizer = action.payload?.data?.organizer_reservations
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchReservationsForOrganizatorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# organizator item detail ######
        builder.addCase(fetchSelectedReservationForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedReservationForOrganizerFunc.fulfilled, (state, action) => {

            state.selectedReservationForOrganizer = action.payload?.data?.organizer_reservation
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedReservationForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

    }
})


export default reservationReducer

export const reservationFunc = {
    fetchReservationsForModeratorFunc,
    fetchReservationsForOrganizatorFunc,
    fetchSelectedReservationForModeratorFunc,
    fetchSelectedReservationForOrganizerFunc,

    resetSelectedReservation: actions.resetSelectedReservation

}