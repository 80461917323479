/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'

// graphqlApi

const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/user/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})
const paymentConfigFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/payment/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    user: null,
    allUser: [],
    loading: false,
    isOnboarding: null,
    isOnboardingMerchant: null,

}

export const fetchUserInfoFunc = createAsyncThunk('user/fetchUserInfoFunc', async ({ userId, accessToken }) => {
    const response = await axios(configFunc({ accessToken, data: graphqlApi.user.queries.userQueries.userQuery({ userId }) }))
    return response?.data
})

export const fetchPaymentUserInfoFunc = createAsyncThunk('user/fetchPaymentUserInfoFunc', async ({ accessToken }) => {
    const response = await axios(paymentConfigFunc({ accessToken, data: graphqlApi.user.queries.userQueries.paymentUserQuery() }))
    return response?.data
})

export const fetchAllUserInfoFunc = createAsyncThunk('user/fetchAllUserInfoFunc', async ({ variables, accessToken }) => {
    const response = await axios(configFunc({ accessToken, data: graphqlApi.user.queries.userQueries.allUsersQuery({ variables }) }))
    return response?.data
})



const { reducer: userReducer } = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# user info ######
        builder.addCase(fetchUserInfoFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchUserInfoFunc.fulfilled, (state, action) => {

            state.user = action.payload?.data?.user
            state.isOnboarding = action.payload?.data?.user?.onboarded
            state.loading = false
        })
        builder.addCase(fetchUserInfoFunc.rejected, (state, action) => {

            state.loading = false
        })





        // ################# payment user info ######
        builder.addCase(fetchPaymentUserInfoFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchPaymentUserInfoFunc.fulfilled, (state, action) => {

            state.isOnboardingMerchant = !!action.payload?.data?.self_user?.subMerchantInfo
            state.loading = false
        })
        builder.addCase(fetchPaymentUserInfoFunc.rejected, (state, action) => {

            state.loading = false
        })



        // ################# all users ######
        builder.addCase(fetchAllUserInfoFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchAllUserInfoFunc.fulfilled, (state, action) => {

            state.allUser = action.payload?.data?.users
            // state.isOnboarding = action.payload?.data?.user?.onboarded
            state.loading = false
        })
        builder.addCase(fetchAllUserInfoFunc.rejected, (state, action) => {

            state.loading = false
        })




    }
})

export const userFunc = {
    fetchUserInfoFunc,
    fetchPaymentUserInfoFunc,
    fetchAllUserInfoFunc,
}


export default userReducer