/* eslint-disable arrow-body-style */
import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum ad, nisi commodi pariatur, nesciunt dolore praesentium quaerat porro ducimus dolorem repellat amet vero fugit, ratione eius beatae laboriosam eligendi animi.
        </div>
    )
}

export default PrivacyPolicy