import PropTypes from 'prop-types';
import { Avatar, TableRow, TableCell, ListItemText, Stack, Rating } from '@mui/material';

import Label from 'src/components/label/label';
import { activityStatus } from 'src/hophop/utils/_enums';
import moment from 'moment';


export default function PaymentTableRow({ row, selected }) {

  const isActive = moment(row?.schedule?.endDate).format("YYY-MM-DD") > moment(new Date().getTime()).format("YYY-MM-DD")

  return (
    <TableRow hover selected={selected}>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={row?.title} src={row?.listing?.coverImage} sx={{ mr: 2 }} >
          {row?.title?.slice(0, 1)}
        </Avatar>

        <ListItemText
          primary={row?.title}
          secondary={row?.additionalDetails?.slice(0, 30)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.price} ₺</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label
          variant="soft"
          color={
            (row?.participantCount === null && 'default') ||
            (row?.participantCount === 0 && 'default') ||
            (row?.participantCount === row?.quota && 'success') ||
            'warning'
          }
        >
          {row?.participantCount ?? 0}/{row?.quota}

        </Label>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{(row?.participantCount ?? 0) * (row?.price ?? 0)} ₺</TableCell>


      <TableCell>
        <Label
          variant="soft"
          color={
            // (row?.status === 'active' && 'success') ||
            // (row?.status === 'passive' && 'warning') ||
            // (row?.status === 'nonexist' && 'error') ||
            // 'default'
            isActive ? "success" : "warning"
          }
        >
          {/* {activityStatus.find(item => item.value === row?.status).name} */}
          {isActive ? "Aktif" : "Pasif"}
        </Label>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', }} >
        <Stack direction="row" gap={1} alignItems="center">
          <Rating name="half-rating" defaultValue={2.5} value={row?.listing?.rating?.average ?? 0} precision={0.5} />

          <span>
            ({row?.listing?.rating?.numberOfReviews ?? 0})
          </span>
        </Stack>
      </TableCell>

    </TableRow>
  );
}

PaymentTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
