/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
import PropTypes from 'prop-types';
import { Autocomplete, Avatar, Checkbox, Stack, TextField } from '@mui/material';
import { useRoutes } from 'react-router';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import Label from 'src/components/label';
import { useRouter } from 'src/routes/hook';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePopover } from 'src/components/custom-popover';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { graphqlApi } from 'src/hophop/api';
import { useCallback, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { reduxFunc } from 'src/hophop/redux';
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { RHFAutocomplete } from 'src/components/hook-form';
import Image from 'src/components/image/image';



export default function CustomersTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {

  const dispatch = useDispatch()
  const messagePopup = useBoolean();
  const notificationPopup = useBoolean();
  const { handleFetchData } = useFetchData()
  const { accessToken } = useSelector(state => state.auth)

  const [message, setMessage] = useState("")
  const [notifcation, setNotification] = useState("")
  const [selectedActivity, setSelectedActivity] = useState(null)


  const handleSendMessage = useCallback(async (id) => {

    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
          activityId: selectedActivity?._id,
          attachments: [],
          content: message,
          parentIds: [
            {
              "_id": row?._id
            }
          ]
        }),
        accessToken,
        url: "/activity/graphql",
      })

      if (response?.data?.data?.moderator_createMessages?.success) {
        enqueueSnackbar('Mesajınız gönderildi!');
        setMessage("")
      } else {
        enqueueSnackbar('Mesaj iletilemedi!', {
          variant: "error"
        })
      }

      // if (response?.data?.errors) {

      //   response?.data?.errors?.forEach((item, index) => {
      //     if (item?.message === "There are activities are referenced to the location") {
      //       enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
      //         variant: "error"
      //       })
      //     }
      //   })

      // }

    } catch (error) {
      console.error({ error })
    }

  }, [accessToken, handleFetchData, message, row?._id, selectedActivity?._id]);

  const handleSendNotification = useCallback(async (id) => {

    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({ locationId: id }),
        accessToken,
        url: "/activity/graphql",
      })

      if (response?.data?.data?.moderator_removeLocation?.success) {
        enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
        dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }))
      }

      if (response?.data?.errors) {
        response?.data?.errors?.forEach((item, index) => {
          if (item?.message === "There are activities are referenced to the location") {
            enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
              variant: "error"
            })
          }
        })
      }

    } catch (error) {
      console.error({ error })
    }

  }, [accessToken, dispatch, handleFetchData]);

  return (
    <>
      <TableRow
        hover
        selected={selected}
      // onClick={() => router.push(`/rezervasyonlar/${row?._id}`)}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {
            row?.displayImageUrl?.startsWith("https://")
              ? (<Avatar alt={row?.displayName} src={row?.displayImageUrl} sx={{ mr: 2 }} />)
              : (<Avatar sx={{ mr: 2 }}>{row?.displayName?.slice(0, 2)?.toUpperCase()}</Avatar>)
          }

          <ListItemText
            primary={row?.displayName}
            // secondary={row?.description ?? "-"}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.description ?? "-"}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.role === "parent" ? "Aile" : "Organizator"}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell> */}

        {/* <TableCell>
          <Label
            variant="soft"
            color={
              (row?.parking === 'exists' && 'success') ||
              (row?.parking === 'limitid' && 'warning') ||
              (row?.parking === 'nonexist' && 'error') ||
              'default'
            }
          >
            {parkingForLocation?.find(item => item?.value === row?.parking)?.name}
          </Label>
        </TableCell> */}

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

          <Tooltip title="Mesaj" placement="top" arrow>
            <IconButton color={messagePopup.value ? 'default' : 'primary'} onClick={messagePopup.onTrue}>
              <Iconify icon="mingcute:send-fill" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Bildirim" placement="top" arrow>
            <IconButton color={notificationPopup.open ? 'inherit' : 'secondary'} onClick={notificationPopup.onTrue} >
              <Iconify icon="ion:notifcations" />
            </IconButton>
          </Tooltip>

        </TableCell>

      </TableRow>

      {/* {quickEdit.value && <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} id={row?._id} />} */}

      <ConfirmDialog
        open={messagePopup.value}
        onClose={messagePopup.onFalse}
        title="Mesaj gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3} sx={{ pt: 2 }}>

            <Autocomplete
              name="country"
              label="Country"
              value={selectedActivity}
              onChange={(e, onChangeOption) => {
                setSelectedActivity(onChangeOption)
              }}
              options={row?.activities?.map((country) => country)}
              getOptionLabel={(option) => option?.listing?.title}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props?.id}>
                    <Image src="" />
                    {option?.listing?.title}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  label="Aktivite"
                  placeholder="Aktivite seç..."
                  error={false}
                  // helperText={false ? "error?.message" : "helperText"}
                  {...params}
                />
              )}
            />

            <TextField
              multiline
              name="message"
              label="Mesaj"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                width: '100%',
              }}
            />

            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={message === "" || !selectedActivity}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSendMessage()
                  messagePopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={messagePopup.onFalse}>
                iptal
              </Button>
            </Stack>

          </Stack>
        }
      />

      <ConfirmDialog
        open={notificationPopup.value}
        onClose={notificationPopup.onFalse}
        title="Bildirim gönder"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3} sx={{ pt: 2 }}>

            <Autocomplete
              name="country"
              label="Country"
              value={selectedActivity}
              onChange={(e, onChangeOption) => {
                setSelectedActivity(onChangeOption)
              }}
              options={row?.activities?.map((country) => country)}
              getOptionLabel={(option) => option?.listing?.title}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props?.id}>
                    <Image src="" />
                    {option?.listing?.title}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  label="Aktivite"
                  placeholder="Aktivite seç..."
                  error={false}
                  // helperText={false ? "error?.message" : "helperText"}
                  {...params}
                />
              )}
            />

            <TextField
              name="notification"
              label="Bildirim"
              value={notifcation}
              onChange={(e) => setNotification(e.target.value)}
              sx={{
                width: '100%',
              }}
            />

            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={notifcation === ""}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleSendNotification()
                  notificationPopup.onFalse()
                }}
              >
                Gönder
              </Button>

              <Button variant="outlined" color="inherit" onClick={notificationPopup.onFalse}>
                iptal
              </Button>
            </Stack>

          </Stack>
        }
      />
    </>
  );
}

CustomersTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
