/* eslint-disable arrow-body-style */
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Table, Tooltip, Container, TableBody, IconButton, TableContainer } from '@mui/material';

import {
    useTable,
    TableNoData,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { reduxFunc } from 'src/hophop/redux'
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import UserTableRow from './comment-table-row';


const TABLE_HEAD = [
    { id: 'name', label: 'İsim' },
    { id: 'date', label: 'Tarih' },
    { id: 'format', label: 'Format' },
    { id: 'category', label: 'Kategori' },
    { id: 'rate', label: 'Puan' },
    { id: 'action', label: '' },
];


const Comments = () => {

    const table = useTable();
    const confirm = useBoolean();
    const { id } = useParams()
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { accessToken } = useSelector(state => state.auth)
    const { listingsForOrganizer: list } = useSelector(state => state.listing)
    const [variables, setVariables] = useState({
        "filter": {
            // "byCoordinates": {
            //   "lat": null,
            //   "long": null,
            //   "radius": null
            // },
            "city": null,
            "createdByCurrentUser": null,
            "district": null,
            "parking": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 5
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.listingFunc.fetchListingsForOrganizatorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, id, variables])


    const handleChangePage = (a, b) => {
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))
        table.onChangePage(a, b)
    }


    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs heading="Yorumlar" sx={{ mb: { xs: 3, md: 5 }, }} />

            <Card>

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table?.selected?.length}
                        rowCount={list?.data?.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                list?.data.map((row) => row.id)
                            )
                        }
                        action={
                            <>
                                <Tooltip title="Mesaj">
                                    <IconButton color="primary" onClick={confirm.onTrue}>
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bildirim">
                                    <IconButton color="primary" onClick={confirm.onTrue}>
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    />

                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={list?.data?.length}
                                numSelected={table?.selected?.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {list?.data
                                    // ?.slice(
                                    //     table.page * table.rowsPerPage,
                                    //     table.page * table.rowsPerPage + table.rowsPerPage
                                    // )
                                    ?.map((row) => (
                                        <UserTableRow
                                            key={row?._id}
                                            row={row}
                                            selected={table.selected.includes(row?._id)}
                                            onSelectRow={() => table.onSelectRow(row?._id)}
                                        />
                                    ))}



                                <TableNoData notFound={list?.count === 0} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={list?.count ?? 0}
                    page={table.page}
                    rowsPerPage={variables.pagination.pageSize}

                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={table.onChangeRowsPerPage}

                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />

            </Card>

        </Container>
    )
}

export default Comments