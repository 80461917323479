import {
    Card,
    Table,
    Avatar,
    Tooltip,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    ListItemText,
    TableContainer,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';

import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify/iconify'
import Scrollbar from 'src/components/scrollbar/scrollbar'
import { TableHeadCustom, TableNoData, TableSelectedAction, useTable } from 'src/components/table'


const TABLE_HEAD = [
    { id: 'name', label: 'Son Müşteriler', width: 250 },
];


const CustomersTable = ({ list = null }) => {

    const table = useTable();
    const confirm = useBoolean();

    return (
        <Card>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>

                <TableSelectedAction
                    dense={table.dense}
                    numSelected={table?.selected?.length}

                    rowCount={list?.length}
                    onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                            checked,
                            list?.data.map((row) => row.id)
                        )
                    }
                    action={
                        <>
                            <Tooltip title="Mesaj">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bildirim">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                />

                <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} >
                        <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={list?.data?.length}
                            numSelected={table?.selected?.length}
                            onSort={table.onSort}
                        />

                        <TableBody>
                            {
                                list?.data?.map((row) => (
                                    <CustomersTableRow
                                        key={row?._id}
                                        row={row}
                                        selected={table.selected.includes(row?._id)}
                                        onSelectRow={() => table.onSelectRow(row?._id)}
                                    />
                                ))
                            }
                            <TableNoData notFound={list?.count === 0} />
                        </TableBody>
                    </Table>
                </Scrollbar>

            </TableContainer>
        </Card>
    )
}

CustomersTable.propTypes = {
    list: PropTypes.object
};

export default CustomersTable



function CustomersTableRow({ row, selected }) {

    return (
        <TableRow hover selected={selected}  >

            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>

                {
                    row?.displayImageUrl?.startsWith("https://")
                        ? (<Avatar alt={row?.displayName} src={row?.displayImageUrl} sx={{ mr: 2 }} />)
                        : (<Avatar sx={{ mr: 2 }}>{row?.displayName?.slice(0, 2)?.toUpperCase()}</Avatar>)
                }

                <ListItemText
                    primary={row?.displayName}
                    secondary={row?.description ?? "Açıklama yok"}
                    primaryTypographyProps={{ typography: 'body2', textTransform: 'capitalize' }}
                    secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                />
            </TableCell>

            {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.description ?? "Açıklama yok."}</TableCell> */}

            {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.role === "parent" ? "Aile" : "Organizator"}</TableCell> */}


        </TableRow>
    );
}

CustomersTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
};




