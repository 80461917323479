const createMessage = ({ content, attachments, activityId, parentIds }) => {

    const data = JSON.stringify({
        query: `
        mutation Moderator_createMessages($activityId: String!, $messageInput: MessageInput!, $parentIds: [EntityInput]!) {
            moderator_createMessages(activityId: $activityId, messageInput: $messageInput, parentIds: $parentIds) {
              success
            }
          }
        `,
        variables: {
            "activityId": activityId,
            "messageInput": {
                "attachments": attachments,
                "content": content
            },
            "parentIds": parentIds
        }


    })

    return data

}
const createMessageForModerators = ({ content, attachments, activityId, receiverId }) => {

    const data = JSON.stringify({
        query: `
        mutation Moderator_createActivityConversationMessage($activityId: String!, $receiverId: String!, $messageInput: MessageInput!) {
            moderator_createActivityConversationMessage(activityId: $activityId, receiverId: $receiverId, messageInput: $messageInput) {
              _id
            }
          }
        `,
        variables: {
            "activityId": activityId,
            "messageInput": {
                "attachments": attachments,
                "content": content
            },
            "receiverId": receiverId
        }


    })

    return data

}



export const messagesForModerator = {
    createMessage,
    createMessageForModerators,
}