import React, { useState } from 'react'
import { Button, Card, Container, TextField, Typography } from '@mui/material'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { useSettingsContext } from 'src/components/settings';
import { useSelector } from 'react-redux';
import { graphqlApi } from 'src/hophop/api';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';




const Notifications = () => {

    const settings = useSettingsContext();
    const [loading, setLoading] = useState(false)
    const { accessToken } = useSelector(state => state.auth)
    const [nottification, setNotification] = useState({
        title: "",
        description: "",
    })

    const handleSetNotification = (e) => {
        setNotification({
            ...nottification,
            [e.target.name]: e.target.value
        })
    }


    const handleSendNotification = async () => {
        setLoading(true)
        try {

            const axiosConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/notification/graphql`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                data: graphqlApi.notifications.mutations.sendNotification({ values: nottification })
            };

            const response = await axios.request(axiosConfig)


            if (response?.data?.data?.admin_sendAdvertisementToParents) {

                enqueueSnackbar("Bildirim başarılı bir şekilde gönderildi!")

            } else {
                enqueueSnackbar("Bildirim gönderilemedi!", {
                    variant: "error"
                })
            }

        } catch (error) {

            console.error(error);

            if (error?.response?.data?.errors?.length > 0) {

                error?.response?.data?.errors?.forEach((item, index) => {

                    if (item?.msg === "Email already in use") {
                        enqueueSnackbar("Kayıtlı email, başka bir email deneyiniz!", {
                            variant: "error"
                        })
                    } else {
                        enqueueSnackbar("Birşeyler yanlış gitti!", {
                            variant: "error"
                        })
                    }
                })

            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", {
                    variant: "error"
                })
            }

        }

        setLoading(false)
        setNotification({
            title: "",
            description: "",
        })

    }


    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Bildirimler"
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Card

                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    gap: 3,

                    maxWidth: 900,
                    // boxShadow: '1px 1px 20px 0px #ddd'

                }}
            >
                <Typography
                    sx={{
                        fontWeight: '500',
                    }}
                >
                    Bildirim Gönder
                </Typography>
                <TextField label="Başlık" name="title" fullWidth value={nottification.title} onChange={handleSetNotification} />
                <TextField
                    label="Açıklama"
                    name="description"
                    fullWidth
                    multiline
                    rows={5}
                    value={nottification.description}
                    onChange={handleSetNotification}
                />

                <Button
                    disabled={
                        nottification.title === "" ||
                        nottification.description === ""
                    }
                    variant='contained'
                    sx={{
                        width: 'auto !important',
                        alignSelf: 'end',
                    }}

                    onClick={handleSendNotification}
                >
                    Gönder
                </Button>
            </Card>


        </Container>
    )
}

export default Notifications