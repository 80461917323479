export const userNotifications = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query User_userNotifications($filter: FilterUserNotificationInput, $pagination: Pagination) {
      user_userNotifications(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          uid
          user {
            _id
            # role
            displayName
            displayImage
          }
          notificationType
          notificationPreview {
            name
            subject
            content
            data {
              uid
              targetId
              target
            }
          }
          isRead
          createdAt
          updatedAt
        }
      }
    }
        `,
    variables: {
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null,
      },
      "filter": {
        "isRead": variables?.filter?.isRead ?? null,
      }
    }
  })

  return data
}


export const queries = {
  userNotifications
}