const createListing = ({ values, images, coverImage }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_createListing($listingInput: ListingByOrganizerInput!) {
      organizer_createListing(listingInput: $listingInput) {
        _id
      }
    }
            `,
    variables: {
      "listingInput": {
        "ageInterval": values?.ageInterval ?? "",
        "category": values?.category ?? "",
        "companionship": values?.companionship ?? "",
        "coverImage": coverImage ?? "",
        "description": values?.description ?? "",
        "format": values?.format ?? "",
        "images": images ?? [],
        "title": values?.title ?? "",

        "subCategory": values?.subCategory ?? "",
        // "type": values?.type ?? ""
      }
    }
  })

  return data

}


const removeListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_removeListing($listingId: String!) {
      organizer_removeListing(listingId: $listingId) {
        success
      }
    }
            `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const updateOrganizatorListing = ({ listingId, values, coverImage, images }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_updateOrganizerListing($listingId: String!, $updateInput: UpdateOrganizerListingByOrganizerInput) {
      organizer_updateOrganizerListing(listingId: $listingId, updateInput: $updateInput) {
        success
      }
    }
            `,
    variables: {
      "listingId": listingId,
      "updateInput": {
        "ageInterval": values?.ageInterval ?? null,
        "category": values?.category ?? null,
        "companionship": values?.companionship ?? null,
        "coverImage": coverImage ?? null,
        "description": values?.description ?? null,
        "format": values?.format ?? null,
        "images": images ?? [],
        "subCategory": values?.subCategory ?? null,
        "title": values?.title ?? null,
      }
    }
  })
  return data

}


export const listingsForOrganizer = {
  createListing,
  removeListing,
  updateOrganizatorListing,
}