import { Customers } from "./customers";
import Moderators from "./moderators/Moderators";
import Payments from "./payments/Payments";

import Panel from "./panel/Panel";
import Notifications from "./notifications/Notifications";



import UserProfile from "./userProfile/UserProfile";
import Settings from "./settings/Settings";


export const Admin = {
    Customers,
    Moderators,
    Payments,
    Notifications,

    Panel,
    Settings,
    UserProfile
}