/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Grid, MenuItem } from '@mui/material'
import React from 'react'
import { RHFSelect, RHFTextField } from 'src/components/hook-form'
import { hoursBeforeReservationExpiresList } from 'src/hophop/utils/_enums'

const FormOne = ({ values, setValue }) => {


    return (

        <Grid container spacing={3}>


            {/* <Grid item xs={12} md={6}>
                <RHFSelect name="locationId" label="Lokasyon">
                    {locations?.map((status) => (
                        <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                            {status?.address?.title?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid> */}

            <Grid item xs={12}>
                <RHFTextField name="title" label="Aktivite İsmi" />
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFSelect name="duration" label="Süre">
                    {[
                        { value: 10, name: '10 dk' },
                        { value: 20, name: '20 dk' },
                        { value: 30, name: '30 dk' },
                        { value: 40, name: '40 dk' },
                        { value: 50, name: '50 dk' },
                        { value: 60, name: '60 dk' },
                        { value: 70, name: '70 dk' },
                        { value: 80, name: '80 dk' },
                        { value: 90, name: '90 dk' },
                    ]?.map((status) => (
                        <MenuItem
                            key={status?.value}
                            value={status?.value}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFTextField type="number" name="quota" label="Kontenjan" />
            </Grid>

            <Grid item xs={12}>
                <RHFSelect name="hoursBeforeReservationExpires" label="Son rezervasyon saati">
                    {hoursBeforeReservationExpiresList?.map((status) => (
                        <MenuItem
                            key={status?.value}
                            value={status?.value}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFSelect name="fee" label="Ücret seçeneği">
                    <MenuItem value="free" onClick={() => setValue('price', 0)}>Ücretsiz</MenuItem>
                    <MenuItem value="paid">Ücretli</MenuItem>
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFTextField
                    type="number"
                    name="price"
                    label="Ücret"
                    disabled={!!values?.price}
                // disabled={values?.fee === 'free'}
                />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField multiline rows={4} name="additionalDetails" label="Ek Açıklama" />
            </Grid>

        </Grid>


    )
}


export default FormOne