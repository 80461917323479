import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { Box, IconButton, Typography } from '@mui/material'
import CelebrationIcon from '@mui/icons-material/Celebration';


const FirstPage = () => {

    const { id } = useParams();

    useEffect(() => {
        sessionStorage.clear()
    }, [])

    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: "center",
            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 2,
                }}
            >
                <CelebrationIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                {id ? "Aktivite Güncelle" : "Yeni Aktivite Oluştur"}
            </Typography>
            <Typography
                sx={{
                    fontSize: 20,
                    fontFamily: 'sen !important',
                    color: '#999',
                    textAlign: 'center',
                }}
            >
                Oluşturduğunuz aktivitenin ön izlemesini sağ tarafta görüntüleyebilirsiniz.
            </Typography>
        </Box>
    )
}

export default FirstPage