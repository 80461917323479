/* eslint-disable arrow-body-style */


export const findImageContentType = (file) => {


    if (file?.type === "image/png") {
        return "pngImage"
    }

    return "jpegImage"
}