/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useCallback, useState } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';


import UserTableRow from './reservation-table-row';

const TABLE_HEAD = [
  { id: '1', label: '' },
  { id: 'customer', label: 'Müşteri', width: 180 },
  { id: 'time', label: 'Tarih', width: 220 },
  { id: 'status', label: 'Durumu', width: 100 },
  { id: '2', width: 88 },
  // { id: '3', width: 88 },
];

const ReservationDetailList = () => {

  const { id } = useParams();
  const table = useTable();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const { accessToken } = useSelector((state) => state.auth);
  const { reservationsForModerator: list } = useSelector((state) => state.reservation);
  const { selectedActivityForModerator } = useSelector((state) => state.activity);
  const [variables, setVariables] = useState({
    filter: {
      activityId: null,
      dateInterval: {
        from: null,
        to: null,
      },
      "status": "reserved"
    },
    pagination: {
      page: 1,
      pageSize: 5,
    },
  });

  useEffect(() => {
    if (accessToken) {
      dispatch(
        reduxFunc.reservationFunc.fetchReservationsForModeratorFunc({
          accessToken,
          variables,
          activityId: id,
        })
      );

      dispatch(
        reduxFunc.activityFunc.fetchSelectedActivityForModeratorFunc({
          accessToken,
          activityId: id,
        })
      );
    }
  }, [accessToken, dispatch, id, variables]);

  const handleChangePage = (a, b) => {
    ;

    setVariables((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: b + 1,
      },
    }));

    table.onChangePage(a, b);
  };

  const messagePopup = useBoolean();
  const notificationPopup = useBoolean();
  const messageToAllPopup = useBoolean();
  const notificationToAllPopup = useBoolean();
  const { handleFetchData } = useFetchData();

  const [message, setMessage] = useState('');
  const [notifcation, setNotification] = useState('');

  const handleSendMessageToAllParents = useCallback(async () => {
    try {
      const allIds = await handleFetchData({
        data: graphqlApi.activity.queries.reservationsForModerator.allReservationsId({
          variables: null,
        }),
        accessToken,
      });


      if (allIds?.data?.data?.moderator_reservations?.count) {
        const response = await handleFetchData({
          data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
            activityId: id,
            attachments: [],
            content: message,
            parentIds: allIds?.data?.data?.moderator_reservations?.data?.map((item) => ({
              _id: item?.parent?._id,
            })),
          }),
          accessToken,
          url: '/activity/graphql',
        });


        if (response?.data?.data?.moderator_createMessages?.success) {
          enqueueSnackbar('Mesajınız gönderildi!');
          setMessage('');
        } else {
          enqueueSnackbar('Mesaj iletilemedi!', {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('Mesaj gönderecek rezervasyon yok!', {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error({ error })
    }
  }, [accessToken, handleFetchData, id, message]);

  // const handleSendNotificationToAllParent = useCallback(
  //   async (id) => {
  //     try {
  //       const response = await handleFetchData({
  //         data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({
  //           locationId: id,
  //         }),
  //         accessToken,
  //         url: '/activity/graphql',
  //       });


  //       if (response?.data?.data?.moderator_removeLocation?.success) {
  //         enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
  //         dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }));
  //       }

  //       if (response?.data?.errors) {
  //         response?.data?.errors?.forEach((item, index) => {
  //           ;
  //           if (item?.message === 'There are activities are referenced to the location') {
  //             enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
  //               variant: 'error',
  //             });
  //           }
  //         });
  //       }
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   },
  //   [accessToken, dispatch, handleFetchData]
  // );

  const handleSendGroupMessage = useCallback(async () => {
    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.messagesForModerator.createMessage({
          activityId: id,
          attachments: [],
          content: message,
          parentIds: table.selected,
        }),
        accessToken,
        url: '/activity/graphql',
      });


      if (response?.data?.data?.moderator_createMessages?.success) {
        enqueueSnackbar('Mesajınız gönderildi!');
        setMessage('');
      } else {
        enqueueSnackbar('Mesaj iletilemedi!', {
          variant: 'error',
        });
      }

      // if (response?.data?.errors) {

      //   response?.data?.errors?.forEach((item, index) => {

      //     if (item?.message === "There are activities are referenced to the location") {
      //       enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
      //         variant: "error"
      //       })
      //     }
      //   })

      // }
    } catch (error) {
      console.error({ error })
    }
  }, [accessToken, handleFetchData, id, message, table.selected]);

  const handleSendGroupNotification = useCallback(
    async (id) => {
      try {
        const response = await handleFetchData({
          data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({
            locationId: id,
          }),
          accessToken,
          url: '/activity/graphql',
        });


        if (response?.data?.data?.moderator_removeLocation?.success) {
          enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
          dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }));
        }

        if (response?.data?.errors) {
          response?.data?.errors?.forEach((item, index) => {
            ;
            if (item?.message === 'There are activities are referenced to the location') {
              enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
                variant: 'error',
              });
            }
          });
        }
      } catch (error) {
        console.error(error)
      }
    },
    [accessToken, dispatch, handleFetchData]
  );


  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <CustomBreadcrumbs
        heading={`${selectedActivityForModerator?.title} Detayı`}
        links={[
          { name: 'Rezervasyonlar', href: '/rezervasyonlar' },
          { name: selectedActivityForModerator?.title, href: 'yorumlar' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack
        direction="row"
        justifyContent="end"
        spacing={3}
        sx={{
          mb: 3,
          '& > *': {
            fontSize: '14px !important',
          },
        }}
      >
        <Tooltip title="Toplu Mesaj">
          <Button color="primary" onClick={messageToAllPopup.onTrue}>
            <Iconify icon="mingcute:send-fill" />
            &nbsp; Toplu Mesaj
          </Button>
        </Tooltip>
        {/* <Tooltip title="Toplu Bildirim">
          <IconButton color="secondary" onClick={notificationToAllPopup.onTrue}>
            <Iconify icon="ion:notifcations" />
            &nbsp; Toplu Bildirim
          </IconButton>
        </Tooltip> */}
      </Stack>

      <Card>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table?.selected?.length}
            rowCount={list?.data?.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked
                // list?.data.map((row) => ({ "_id": row?.parent?._id }))
              )
            }
            action={
              <>
                <Tooltip title="Seçilenlere Mesaj">
                  <IconButton color="primary" onClick={messagePopup.onTrue}>
                    <Iconify icon="mingcute:send-fill" />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Seçilenlere Bildirim">
                  <IconButton color="secondary" onClick={notificationPopup.onTrue}>
                    <Iconify icon="ion:notifcations" />
                  </IconButton>
                </Tooltip> */}
              </>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={list?.data?.length}
                numSelected={table?.selected?.length}
                onSort={table.onSort}
              />

              <TableBody>
                {list?.data
                  // ?.slice(
                  //     table.page * table.rowsPerPage,
                  //     table.page * table.rowsPerPage + table.rowsPerPage
                  // )
                  ?.map((row, i) => (
                    <UserTableRow
                      key={i}
                      row={row}
                      selected={!!table.selected.find((item) => item?._id === row?.parent?._id)}
                      onSelectRow={() => table.onSelectRow({ _id: row?.parent?._id })}
                    // onDeleteRow={() => handleDeleteRow(row?._id)}
                    />
                  ))}

                {/* <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(table?.page, table?.rowsPerPage, locationsForModerator?.data?.length)}
                                    /> */}

                <TableNoData notFound={list?.count === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={list?.count ?? 0}
          page={table.page}
          rowsPerPage={variables.pagination.pageSize}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={table.onChangeRowsPerPage}

          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <>
        <ConfirmDialog
          open={messageToAllPopup.value}
          onClose={messageToAllPopup.onFalse}
          title="Toplu mesaj gönder"
          // content="Reddetmek sebebi?"
          noCanselButton
          action={
            <Stack direction="column" spacing={3}>
              <TextField
                multiline
                name="message"
                label="Toplu mesaj"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'end',
                }}
              >
                <Button
                  disabled={message === ''}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSendMessageToAllParents();
                    messageToAllPopup.onFalse();
                  }}
                >
                  Gönder
                </Button>

                <Button variant="outlined" color="inherit" onClick={messageToAllPopup.onFalse}>
                  iptal
                </Button>
              </Stack>
            </Stack>
          }
        />

        <ConfirmDialog
          open={notificationToAllPopup.value}
          onClose={notificationToAllPopup.onFalse}
          title="Toplu Bildirim gönder"
          // content="Reddetmek sebebi?"
          noCanselButton
          action={
            <Stack direction="column" spacing={3}>
              <TextField
                name="notification"
                label="Toplu bildirim"
                value={notifcation}
                onChange={(e) => setNotification(e.target.value)}
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'end',
                }}
              >
                <Button
                  disabled={notifcation === ''}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleSendGroupNotification();
                    notificationToAllPopup.onFalse();
                  }}
                >
                  Gönder
                </Button>

                <Button variant="outlined" color="inherit" onClick={notificationToAllPopup.onFalse}>
                  iptal
                </Button>
              </Stack>
            </Stack>
          }
        />
      </>

      <>
        <ConfirmDialog
          open={messagePopup.value}
          onClose={messagePopup.onFalse}
          title="Seçilenlere mesaj gönder"
          // content="Reddetmek sebebi?"
          noCanselButton
          action={
            <Stack direction="column" spacing={3}>
              <TextField
                multiline
                name="message"
                label="Seçilenlere mesaj"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'end',
                }}
              >
                <Button
                  disabled={message === ''}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSendGroupMessage();
                    messagePopup.onFalse();
                  }}
                >
                  Gönder
                </Button>

                <Button variant="outlined" color="inherit" onClick={messagePopup.onFalse}>
                  iptal
                </Button>
              </Stack>
            </Stack>
          }
        />

        <ConfirmDialog
          open={notificationPopup.value}
          onClose={notificationPopup.onFalse}
          title="Seçilenlere Bildirim gönder"
          // content="Reddetmek sebebi?"
          noCanselButton
          action={
            <Stack direction="column" spacing={3}>
              <TextField
                name="notification"
                label="Seçilenlere bildirim"
                value={notifcation}
                onChange={(e) => setNotification(e.target.value)}
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'end',
                }}
              >
                <Button
                  disabled={notifcation === ''}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleSendGroupNotification();
                    notificationPopup.onFalse();
                  }}
                >
                  Gönder
                </Button>

                <Button variant="outlined" color="inherit" onClick={notificationPopup.onFalse}>
                  iptal
                </Button>
              </Stack>
            </Stack>
          }
        />
      </>
    </Container>
  );
};

export default ReservationDetailList;
