import moment from "moment"

const subMerchantInformations = () => {

  const data = JSON.stringify({
    query: `
        query Self_retrieveIyzicoSubMerchant {
            self_retrieveIyzicoSubMerchant {
              subMerchantKey
              subMerchantExternalId
              subMerchantType
              name
              email
              gsmNumber
              address
              iban
              taxOffice
              contactName
              contactSurname
              legalCompanyTitle
              swiftCode
              currency
              identityNumber
              taxNumber
            }
          }
        `,
    variables: {}

  })

  return data

}

const organizerTotalRevenue = () => {

  const data = JSON.stringify({
    query: `
    query Query($filterTotal: FilterTotalRevenueInput, $filterMonthly: FilterTotalRevenueInput, $filterWeekly: FilterTotalRevenueInput) {
      total:organizer_totalRevenue(filter: $filterTotal) {
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
      monthly:organizer_totalRevenue(filter: $filterMonthly) {
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
      weekly:organizer_totalRevenue(filter: $filterWeekly) {
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
    }
    `,
    variables: {

      "filterTotal": {
        "dateFilter": {
          "from": null,
          "to": null,
        }
      },

      "filterMonthly": {
        "dateFilter": {
          "from": moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD"),
          "to": null,
        }
      },

      "filterWeekly": {
        "dateFilter": {
          "from": moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
          "to": null,
        }
      },


    }

  })

  return data

}

const moderatorTotalRevenue = () => {

  const data = JSON.stringify({
    query: `
    query Query($filterTotal: FilterTotalRevenueInput, $filterMonthly: FilterTotalRevenueInput, $filterWeekly: FilterTotalRevenueInput) {
      total:moderator_totalRevenue(filter: $filterTotal){
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
      monthly:moderator_totalRevenue(filter: $filterMonthly){
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
      weekly:moderator_totalRevenue(filter: $filterWeekly){
        totalSalesAmount
        totalOrganizerPayoutAmount
        totalHopHopPayoutAmount
        totalIyizicoCommissionRateAmount
        totalIyizicoCommissionFeeAmount
      }
    }
    `,
    variables: {

      "filterTotal": {
        "dateFilter": {
          "from": null,
          "to": null,
        }
      },

      "filterMonthly": {
        "dateFilter": {
          "from": moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD"),
          "to": null,
        }
      },

      "filterWeekly": {
        "dateFilter": {
          "from": moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
          "to": null,
        }
      },


    }

  })


  return data

}

export const queries = {
  subMerchantInformations,
  organizerTotalRevenue,
  moderatorTotalRevenue,
}