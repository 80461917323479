import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { SplashScreen } from 'src/components/loading-screen';

import { AuthPages } from 'src/hophop/pages/auth';


export const authRoutes = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: '',
      element: (
        <AuthClassicLayout>
          <AuthPages.LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <AuthPages.LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="">
          <AuthPages.RegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <AuthPages.VerifyCodePage /> },
        { path: 'forgot-password', element: <AuthPages.ResetPasswordPage /> },
        { path: 'new-password', element: <AuthPages.NewPasswordPage /> },
      ],
    },
  ],
};