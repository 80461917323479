import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Container, MenuItem, Select, InputLabel } from '@mui/material';
import animateScrollTo from 'animated-scroll-to';

import LastSection from './sections/LastSection';
import PersonCompany from './sections/PersonCompany';
import LimitedCompany from './sections/LimitedCompany';
import OnboardingNavbar from './components/OnboardingNavbar';
import PersonalOrganization from './sections/PersonalOrganization';
import { ColorlibConnector, ColorlibStepIcon } from './components/Colorlib';


const OnboardingContext = createContext();

const stepsObj = {
  personalOrganization: ['Kişisel Bilgiler', 'Organizasyon Bilgileri', 'Bitiş'],
  personCompany: ['Organizasyon Bilgileri', 'Fatura Bilgileri', 'Bitiş'],
  limitedCompany: ['Organizasyon Bilgileri', 'Fatura Bilgileri', 'Bitiş'],
};


const OnboardingMain = () => {

  const [values, setValues] = useState({
    activeStep: 0,
    skipped: new Set(),
    pageState: 'personalOrganization',
    steps: stepsObj?.personalOrganization,
    accountType: "personalOrganization",
  });

  useEffect(() => {
    if (values?.activeStep !== 3) animateScrollTo(0, { maxDuration: 1000 });
  }, [values])


  const handleNext = useCallback(() => {

    let newSkipped = values?.skipped;
    if (values?.skipped.has(values?.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(values?.activeStep);
    }

    setValues((prevState) => ({
      ...prevState,
      skipped: newSkipped,
      activeStep: prevState.activeStep + 1,
      ...(prevState.activeStep === 2 && { pageState: "last" }),
    }));

  }, [values?.activeStep, values?.skipped]);

  const handleBack = () => {
    setValues((prevState) => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
  };

  const handleChangeAccountType = (e) => {
    const type = e.target.value
    setValues(prevState => ({ ...prevState, accountType: type, steps: stepsObj[type], pageState: type }));
  }


  const renderOrganizerOnboardingPages = useCallback(() => {

    return (
      <>

        <Box sx={{ display: values?.pageState !== "personalOrganization" ? "none" : "block" }}>
          <PersonalOrganization
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={values?.activeStep}
          />
        </Box>


        <Box sx={{ display: values?.pageState !== "personCompany" ? "none" : "block" }}>
          <PersonCompany
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={values?.activeStep}
          />
        </Box>



        <Box sx={{ display: values?.pageState !== "limitedCompany" ? "none" : "block" }}>
          <LimitedCompany
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={values?.activeStep}
          />
        </Box>

        <Box sx={{ display: values?.pageState !== "last" ? "none" : "block" }}>
          <LastSection
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={values?.activeStep}
          />
        </Box>



      </>
    )

  }, [handleNext, values?.activeStep, values?.pageState])



  return (
    <OnboardingContext.Provider value={values}>
      <Box sx={{ pb: '100px' }}>

        <OnboardingNavbar />

        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: { xs: '50px', sm: '15px' },
            my: { xs: '100px', sm: '150px' },
          }}
        >

          <Stepper
            activeStep={(values?.activeStep ?? 1)}
            alternativeLabel
            connector={<ColorlibConnector />}
            sx={{
              width: '100%',
              transition: 'all',
              transitionDuration: '1s',
              mb: '50px'
              // display: values?.activeStep === 0 ? 'none' : 'flex',
            }}
          >
            {
              values?.steps.map((label, _) => (
                <Step key={label} sx={{ transitionDuration: '1s' }}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                    {label}
                  </StepLabel>
                </Step>
              ))
            }
          </Stepper>

          <Box sx={{ width: '100%', px: { sm: '10%' }, display: values.activeStep === 0 ? "block" : "none" }}>
            <InputLabel id="demo-simple-select-label">*Hesap türü</InputLabel>

            <Select
              sx={{ width: '100%' }}
              onChange={handleChangeAccountType}
              value={values.accountType}
              title='Hesap Türü'
            >
              {
                [
                  { id: 1, name: "BİREYSEL ORGANİZATÖR / ŞAHIS", helperText: "(Vergi No yoksa)", value: "personalOrganization" },
                  { id: 2, name: "BİREYSEL ORGANİZATÖR / ŞAHIS FİRMASI", value: "personCompany" },
                  { id: 3, name: "KURUMSAL ORGANİZATÖR", helperText: "(Limited Şirket yada Anonim Şirket)", value: "limitedCompany" },
                ].map((status, i) => (
                  <MenuItem
                    key={`${status}-${i}`}
                    value={status?.value}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      textTransform: 'uppercase',
                    }}
                  >
                    <span style={{ textTransform: 'uppercase', margin: 0, padding: 0 }}>
                      {status?.name?.toLocaleLowerCase()}&nbsp;
                    </span>
                    {status?.helperText && (<span style={{ textTransform: 'capitalize' }}>{status.helperText}</span>)}
                  </MenuItem>

                ))
              }
            </Select>

          </Box>

          {renderOrganizerOnboardingPages()}

        </Container>

      </Box>
    </OnboardingContext.Provider>
  );
};

// OnboardingMain.prototype = {};

export default OnboardingMain;
