const userUpdate = ({ userId, values, profileImageUrl }) => {

    const data = JSON.stringify({
        query: `
            mutation UpdateUser($updateUserInput: UpdateUserInput!, $userId: ID) {
                updateUser(updateUserInput: $updateUserInput, userId: $userId) {
                _id
                }
            }
        `,
        variables: {
            "updateUserInput": {
                "contact": {
                    "address": {
                        "city": "İstanbul",
                        "district": " ",
                        "content": {
                            "title": " ",
                            "description": values?.address ?? null
                        },
                        "lat": 41.015137,
                        "long": 28.979530
                    },
                    "email": values?.email ?? null,
                    "phoneNumber": values?.phoneNumber ?? null,
                },
                "onboarded": true,
                "personalInformation": {
                    "childAge": (values?.childAge || values?.childAge !== "") ? values?.childAge : null,
                    "description": values?.description ?? null,

                    "fullName": values?.fullName ?? null,
                    "identificationNumber": values?.identificationNumber?.toString() ?? null,
                    "organization": values?.organization ?? null,
                    "phoneNumber": values?.phoneNumber ?? null,
                    "profileImageUrl": profileImageUrl ?? null,
                    "taxNo": values?.taxNo ?? null,
                    "taxOffice": values?.taxOffice ?? null
                },
                "socialMedia": {
                    "youtube": values?.youtube ?? null,
                    "website": values?.website ?? null,
                    "twitter": values?.twitter ?? null,
                    "instagram": values?.instagram ?? null,
                    "facebook": values?.facebook ?? null
                }

            },
            "userId": userId ?? null
        }
    })


    return data
}



export const userLMutations = {
    userUpdate,
}
