/* eslint-disable arrow-body-style */
import { Outlet } from "react-router";
import { Badge } from "@mui/material";
import NotFoundPage from "src/pages/404";
import { HophopPages } from "src/hophop/pages";
import Iconify from "src/components/iconify/iconify";
import DashboardLayout from "src/layouts/dashboard/layout";
import { useEffect } from "react";
import RouteGuard from "../RouteGuard";


const icon = (name = "mingcute:add-line") => (<Iconify icon={name} />)



export const moderatorRoutes = ({ userRole, isOnboarding, listingCount, locationCount }) => {




    const navConfig = [
        {
            subheader: 'hophop',
            items: [
                {
                    title: 'panel',
                    path: '/panel',
                    icon: icon("mingcute:dashboard-fill"),
                },
                {
                    title: 'Aktiviteler',
                    path: "/aktiviteler",
                    icon: icon("gis:signpost"),
                    // icon: icon("lucide:party-popper"),
                    children: [
                        { title: 'Aktivite Listeleri', path: "/aktiviteler/liste" },
                        { title: 'Aktivite Oluştur', path: "/aktiviteler/olustur" },
                    ]
                },
                {
                    title: 'Listelemeler',
                    path: "/listelemeler",
                    icon: icon("ep:list"),
                    children: [
                        { title: 'Listelemeler Listesi', path: "/listelemeler/liste" },
                        { title: 'Listeleme Oluştur', path: "/listelemeler/olustur" },
                    ]
                },
                {
                    title: 'Konumlar',
                    path: "/konumlar",
                    icon: icon("typcn:location"),
                    children: [
                        { title: 'Konum Listeleri', path: "/konumlar/liste" },
                        { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    ]
                },
                {
                    title: 'Blog',
                    path: "/blog",
                    icon: icon("fluent:news-16-filled"),
                    children: [
                        { title: 'Blog Listesi', path: "/blog/liste" },
                        { title: 'Blog Oluştur', path: "/blog/olustur" },
                    ]
                },
                {
                    title: 'Blog+',
                    path: "/blog-plus",
                    icon: icon("mdi:tab-plus"),
                    children: [
                        { title: 'Blog+ Listesi', path: "/blog-plus/liste" },
                        { title: 'Blog+ Oluştur', path: "/blog-plus/olustur" },
                    ]
                },


                {
                    title: 'Rezervasyonlar',
                    path: "/rezervasyonlar",
                    icon: icon("line-md:clipboard-list-twotone"),
                    // icon: icon("ri:reserved-fill"),
                    // children: [
                    //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Müşteriler',
                    path: "/musteriler",
                    icon: icon("fontisto:persons"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Yorumlar',
                    path: "/yorumlar",
                    icon: icon("mdi:comments-text"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Mesajlar',
                    path: "/mesajlar",
                    icon: icon("jam:messages-f"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Ödemeler',
                    path: "/odemeler",
                    icon: icon("mdi:account-payment"),
                    // children: [
                    //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Kategoriler',
                    path: "/kategoriler",
                    icon: icon("material-symbols:category"),
                    // children: [
                    //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Uygulama Düzeni',
                    path: "/uygulama-duzeni",
                    icon: icon("mdi:cellphone-settings"),
                    // children: [
                    //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                }
            ],
        },

        {
            subheader: 'Onay Menüsü',
            items: [
                {
                    title: 'Listeleme Onaylama',
                    path: '/listeleme-onayla',
                    icon: icon("line-md:clipboard-list"),
                    info: <Badge badgeContent={listingCount} sx={{ mr: 2 }} color="primary" />
                },
                {
                    title: 'Konum Onaylama',
                    path: '/konum-onayla',
                    icon: icon("mingcute:location-3-line"),
                    info: <Badge badgeContent={locationCount} sx={{ mr: 2 }} color="primary" />
                },
            ]
        },

    ];


    return ({
        path: '',
        element: (
            <RouteGuard redirectPath="/" isAuth={userRole === "moderator"}>
                <Outlet />
            </RouteGuard>
        ),
        children: [

            // dashboard
            {
                path: '/',
                element: (
                    <DashboardLayout navConfig={navConfig}>
                        <Outlet />
                    </DashboardLayout>
                ),
                children: [
                    {
                        path: "",
                        element: (<HophopPages.DashboardPages.Moderator.Panel />)
                    },
                    {
                        path: "panel",
                        element: (<HophopPages.DashboardPages.Moderator.Panel />)
                    },


                    {
                        path: "aktiviteler",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.CreateActivity />)
                    },
                    {
                        path: "aktiviteler/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.CreateActivity />)
                    },


                    {
                        path: "listelemeler",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.Main />)
                    },
                    {
                        path: "listelemeler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.Main />)
                    },
                    {
                        path: "listelemeler/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.CreateListing />)
                    },
                    {
                        path: "listelemeler/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.CreateListing />)
                    },
                    {
                        path: "listeleme-onayla",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.AproveListings />)
                    },


                    {
                        path: "konumlar",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.Main />)
                    },
                    {
                        path: "konumlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.Main />)
                    },
                    {
                        path: "konumlar/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.CreateLocations />)
                    },
                    {
                        path: "konum-onayla",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.AproveLocations />)
                    },



                    {
                        path: "rezervasyonlar",
                        element: (<HophopPages.DashboardPages.Moderator.Reservations.Main />)
                    },
                    {
                        path: "rezervasyonlar/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Reservations.ReservationDetailList />)
                    },


                    {
                        path: "musteriler",
                        element: (<HophopPages.DashboardPages.Moderator.Customers.Main />)
                    },

                    {
                        path: "yorumlar",
                        element: (<HophopPages.DashboardPages.Moderator.Comments.Main />)
                    },
                    {
                        path: "yorumlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Comments.Main />)
                    },
                    {
                        path: "yorumlar/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Comments.ListingComments />)
                    },

                    {
                        path: "mesajlar",
                        element: (<HophopPages.DashboardPages.Moderator.Messages.Main />)
                    },
                    {
                        path: "mesajlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Messages.Main />)
                    },
                    {
                        path: "mesajlar/:activityId/:receiverId",
                        element: (<HophopPages.DashboardPages.Moderator.Messages.Main />)
                    },

                    {
                        path: "odemeler",
                        element: (<HophopPages.DashboardPages.Moderator.Payments.Main />)
                    },

                    {
                        path: "kategoriler",
                        element: (<HophopPages.DashboardPages.Moderator.Categories />)
                    },

                    {
                        path: "uygulama-duzeni",
                        element: (<HophopPages.DashboardPages.Moderator.UygulamaDuzeni />)
                    },

                    {
                        path: "blog",
                        element: (<HophopPages.DashboardPages.Moderator.Blog.Main />)
                    },
                    {
                        path: "blog/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Blog.Main />)
                    },
                    {
                        path: "blog/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Blog.CreateBlog />)
                    },
                    {
                        path: "blog/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Blog.CreateBlog />)
                    },
                    {
                        path: "blog-plus",
                        element: (<HophopPages.DashboardPages.Moderator.BlogPlus.MainPlus />)
                    },
                    {
                        path: "blog-plus/liste",
                        element: (<HophopPages.DashboardPages.Moderator.BlogPlus.MainPlus />)
                    },
                    {
                        path: "blog-plus/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.BlogPlus.CreateBlogPlus />)
                    },
                    {
                        path: "blog-plus/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.BlogPlus.CreateBlogPlus />)
                    },
                    {
                        path: "user-profile",
                        element: (<HophopPages.DashboardPages.Moderator.UserProfile />)
                    },
                    {
                        path: "settings",
                        element: (<HophopPages.DashboardPages.Moderator.Settings />)
                    },
                    { path: '*', element: <NotFoundPage /> },

                ]
            },

            { path: '*', element: <NotFoundPage /> },
            // { path: '*', element: <Navigate to="/¨" replace /> },
        ]
    })

}