import Compressor from "compressorjs"


export const compressImage = (file, quality) => {

    console.log(file, 'file')

    return new Promise((resolve, reject) => {

        // eslint-disable-next-line no-new
        new Compressor(
            file, {
            quality: quality ?? 0.6,
            success(result) {
                resolve(result)
            },
            error(e) {
                reject(e)
            },
        })

    })

}