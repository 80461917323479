const locationDetail = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_location($locationId: String!) {
      moderator_location(locationId: $locationId) {
        _id
        city
        district
        country
        address {
           title
           description
        }
        lat
        long
        parking
        status
        # createdBy {
          #   _id
          #   displayName
          #   displayImageUrl
          #   role
          # }
        updatedBy {
          _id
          displayName
          displayImageUrl
          role
        }
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "locationId": locationId
    }
  })

  return data

}

const allLocations = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_locationsOfModerators($filter: FilterLocationsOfModeratorsByModeratorInput, $pagination: Pagination) {
      moderator_locationsOfModerators(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          address {
            description
            title
          }
          city
          country
          createdAt
          # createdBy {
            #   _id
            #   displayName
            #   displayImageUrl
            #   role
            # }
          district
          lat
          long
          parking
          rejectionDetail
          status
          updatedAt
          updatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
        }
      }
    }
              `,
    variables: {
      "filter": {
        // "byCoordinates": {
        //   "lat": null,
        //   "long": null,
        //   "radius": null
        // },
        "city": null,
        "createdByCurrentUser": null,
        "district": null,
        "parking": null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const allOrganizerLocationsForModerator = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_locationsOfOrganizers($filter: FilterLocationsOfOrganizersByModeratorInput, $pagination: Pagination) {
      moderator_locationsOfOrganizers(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          address {
            title
            description
          }
          city
          createdBy {
            displayImageUrl
            displayName
            description
            _id
          }
          parking
          status
          district
          long
          lat
        }
      }
    }
    `,
    variables: {
      "filter": {
        "byCoordinates": {
          "lat": null,
          "long": null,
          "radius": null
        },
        "city": null,
        "district": null,
        "parking": null,
        "status": variables?.filter?.status ?? "pendingApproval",
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
      }
    }
  })

  return data
}

const isUpdatable = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    query Query($locationId: String!) {
      user_isLocationUpdatable(locationId: $locationId)
    }
    `,
    variables: {
      "locationId": locationId
    }
  })

  return data
}


export const locationsForModerator = {
  locationDetail,
  allLocations,
  isUpdatable,

  allOrganizerLocationsForModerator,
}