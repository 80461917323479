import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Grid, Typography, Button } from '@mui/material'

import { reduxFunc } from 'src/hophop/redux'
import { RouterLink } from 'src/routes/components'
import Iconify from 'src/components/iconify/iconify'
import AppWelcome from 'src/sections/overview/app/app-welcome'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import CustomersTable from './_CustomersTable'
import ActivitiesTable from './_ActivitiesTable'
import PaymentSummeryItem from './_PaymentSummeryItem'
import { SeoIllustration } from '../../../../../assets/illustrations'
import { useSettingsContext } from '../../../../../components/settings'


const Panel = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { themeStretch } = useSettingsContext();
    const { accessToken } = useSelector(state => state.auth)
    const { user: userInfo } = useSelector(state => state.user)
    const { total_revenue } = useSelector(state => state.payment)
    const { customersForModerator: customers } = useSelector(state => state.customer)
    const { activitiesForModerator: activities } = useSelector(state => state.activity)


    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.paymentFunc.fetchModeratorRevenueFunc({ accessToken }))
            dispatch(reduxFunc.customerFunc.fetchCustomersForModeratorFunc({
                accessToken,
                variables: {
                    pagination: {
                        page: 1,
                        pageSize: 3,
                    },
                }
            }))
            dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({
                accessToken,
                variables: {
                    pagination: {
                        page: 1,
                        pageSize: 5,
                    },
                }
            }))
        }
    }, [accessToken, dispatch])

    return (
        <>
            <Helmet>
                <title> Panel</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>

                <CustomBreadcrumbs
                    // heading="Panel"
                    action={
                        <Button
                            component={RouterLink}
                            href="/aktiviteler/olustur"
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}
                        >
                            Yeni Aktivite
                        </Button>
                    }
                    sx={{
                        my: { xs: 3, md: 3 },
                    }}
                />

                <Grid container spacing={3}>

                    <Grid item xs={12} >
                        <AppWelcome
                            title={`Hoş geldiniz ${userInfo?.personalInformation?.fullName?.toUpperCase()}`}
                            description="Bu platformu kullanarak etkinlikler oluşturabilir ve yönetebilirsiniz."
                            img={
                                <SeoIllustration
                                    sx={{
                                        p: 3,
                                        margin: { xs: 'auto', md: 'inherit' },
                                    }}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <PaymentSummeryItem
                            title="Toplam Aktivite"
                            total={activities?.count ?? 0}
                            icon={<Iconify icon="lucide:party-popper" width={80} />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <PaymentSummeryItem
                            title="Toplam Müşteri"
                            total={customers?.count ?? 0}
                            icon={<Iconify icon="mdi:customer-service" width={80} />}
                            color="secondary"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <PaymentSummeryItem
                            title="Toplam Kazanç"
                            total={Math.floor(total_revenue) ?? 0}
                            icon={<Iconify icon="tdesign:money" width={80} />}
                            color="warning"
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        {
                            // activities?.data?.length 
                            true
                                ? (
                                    <ActivitiesTable list={activities} />
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 2,
                                        }}
                                    >
                                        <Typography>Aktif aktivite bununmamaktadır, oluşturmak için</Typography>
                                        <Typography
                                            onClick={() => navigate("/aktiviteler/olustur")}
                                            sx={{
                                                textDecoration: 'underline',
                                                ml: '4px',
                                                cursor: 'pointer',
                                                fontWeight: '600'
                                            }}
                                        >
                                            tıklayınız.
                                        </Typography>
                                    </Box>
                                )
                        }
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CustomersTable list={customers} />
                    </Grid>

                </Grid>

            </Container >
        </>
    )
}

export default Panel