/* eslint-disable arrow-body-style */
import React from 'react'

const UserAgreement = () => {
    return (
        <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus hic delectus, maxime illo cumque magnam autem deserunt quas aperiam sit fuga distinctio ex aliquam, corrupti ipsa, ullam soluta voluptatem. Veniam.
        </div>
    )
}

export default UserAgreement