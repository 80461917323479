/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import GoogleMapReact from 'google-map-react';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import { RHFTextField } from 'src/components/hook-form';
import { Box, Dialog, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
//

// ----------------------------------------------------------------------

export default function LocationTableRow({ row, selected, onApproveRow, onRejectRow }) {

  const approvePopup = useBoolean();
  const rejectPopup = useBoolean();
  const detailPopup = useBoolean();

  const [rejectionText, setRejectionText] = useState("")


  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={row?.address?.title} src={row?.createdBy?.displayImageUrl ?? ""} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.address?.title}
            secondary={row?.address?.description}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.city}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.district}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell> */}

        <TableCell>
          <Label
            variant="soft"
            color={
              (row?.parking === 'exists' && 'success') ||
              (row?.parking === 'limitid' && 'warning') ||
              (row?.parking === 'nonexist' && 'error') ||
              'default'
            }
          >
            {parkingForLocation?.find(item => item?.value === row?.parking)?.name}
          </Label>
        </TableCell>

        {
          row?.status !== "approved" && (
            <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>

              <Tooltip title="Detay" placement="top" arrow>
                <IconButton color={detailPopup.open ? 'inherit' : 'default'} onClick={detailPopup.onTrue} sx={{ color: 'primary.main' }}>
                  <Iconify icon="typcn:eye" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Onayla" placement="top" arrow>
                <IconButton color={approvePopup.value ? 'inherit' : 'default'} onClick={approvePopup.onTrue}>
                  <Iconify icon="typcn:tick" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reddet" placement="top" arrow>
                <IconButton color={rejectPopup.open ? 'inherit' : 'default'} onClick={rejectPopup.onTrue} sx={{ color: 'error.main' }}>
                  <Iconify icon="entypo:cross" />
                </IconButton>
              </Tooltip>

            </TableCell>
          )
        }

      </TableRow>

      <Dialog
        open={detailPopup.value}
        onClose={detailPopup.onFalse}
        sx={{
          '& > * > *': {
            width: { xs: "90%" },
            borderRadius: 1,
            p: 3
          }
        }}
      >

        <Stack
          spacing={1}
          sx={{
            '& > p': {
              color: '#999'
            },
            '& > p > span': {
              color: '#000',
            },
            '& *': {
              textTransform: 'capitalize',
            }
          }}
        >

          <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
            <Stack direction="row" spacing={1}>
              <Avatar alt={row?.address?.title} src={row?.createdBy?.displayImageUrl ?? ""} sx={{ mr: 2 }} />

              <ListItemText
                primary={row?.createdBy?.displayName}
                // secondary={row?.createdBy?.description ?? "-"}
                secondary={
                  <Label
                    variant="soft"
                    color={
                      (row?.parking === 'exists' && 'success') ||
                      (row?.parking === 'limitid' && 'warning') ||
                      (row?.parking === 'nonexist' && 'error') ||
                      'default'
                    }
                  >
                    park yeri {parkingForLocation?.find(item => item?.value === row?.parking)?.name}
                  </Label>
                }
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
              />
            </Stack>

            <Stack direction="row">
              <Tooltip title="Onayla" placement="top" arrow>
                <IconButton color={approvePopup.value ? 'inherit' : 'default'} onClick={approvePopup.onTrue}>
                  <Iconify icon="typcn:tick" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reddet" placement="top" arrow>
                <IconButton color={rejectPopup.open ? 'inherit' : 'default'} onClick={rejectPopup.onTrue} sx={{ color: 'error.main' }}>
                  <Iconify icon="entypo:cross" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>

          {/* <Typography>
            Başlık : <span>{row?.address?.title}</span>
          </Typography> */}

          <Typography variant='h4' textAlign="center" my={2} sx={{ textDecoration: 'underline' }}>
            {row?.address?.title}
          </Typography>

          <Typography>
            İl : <span>{row?.city}</span>
          </Typography>
          <Typography>
            İlçe : <span>{row?.district}</span>
          </Typography>

          <Typography>
            İlçe : <span>{row?.district}</span>
          </Typography>

          <Typography>
            Adress : <span>{row?.address?.description}</span>
          </Typography>

          <Box>
            <GoogleMapComponent value={{ lat: row?.lat, long: row?.long }} />

          </Box>
        </Stack>
      </Dialog>


      <ConfirmDialog
        open={approvePopup.value}
        onClose={approvePopup.onFalse}
        title="Konum onayla"
        content="Konumu onaylamak istediğinize eminmisiniz?"

        action={
          <Button variant="contained" color="success" onClick={() => {
            onApproveRow(row?._id)
            approvePopup.onFalse()
          }}
          >
            Onayla
          </Button>
        }
      />

      <ConfirmDialog
        open={rejectPopup.value}
        onClose={rejectPopup.onFalse}
        title="Konum Reddet"
        // content="Reddetmek sebebi?"
        noCanselButton
        action={
          <Stack direction="column" spacing={3}>
            <TextField
              name="rejectionText"
              label="Reddetme sebebi"
              value={rejectionText}
              onChange={(e) => setRejectionText(e.target.value)}
              sx={{
                width: '100%',
                mt: 2
              }}
            />
            <Stack direction="row" spacing={1}
              sx={{
                justifyContent: 'end'
              }}
            >
              <Button
                disabled={rejectionText === ""}
                variant="contained"
                color="error"
                onClick={() => {
                  onRejectRow({ id: row?._id, rejectionText })
                  rejectPopup.onFalse()
                }}
              >
                Reddet
              </Button>

              <Button variant="outlined" color="inherit" onClick={rejectPopup.onFalse}>
                iptal
              </Button>
            </Stack>
          </Stack>
        }
      />

    </>
  );
}

LocationTableRow.propTypes = {
  onApproveRow: PropTypes.func,
  onRejectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};

const GoogleMapComponent = ({ value }) => {

  const defaultProps = {
    center: {
      lat: value?.lat ?? 41.01258256731506,
      lng: value?.long ?? 28.997054894224362,
    },
    zoom: 8
  };

  const [defaultValues, setDefaultValues] = useState(defaultProps.center)

  return (
    <>
      <Box
        sx={{
          height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
          width: '100%',
          borderRadius: '12px',
          overflow: 'hidden',
          // border: errors?.lat?.message ? "3px solid #FF5630" : "",

        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}

          draggable
          onDrag={(e) => null}
          // onChange={(e) => null}
          onClick={(e) => {

            // setDefaultValues({
            //   lat: e.lat,
            //   lng: e.lng
            // })
            // setValue("lat", e.lat)
            // setValue("long", e.lng)
          }}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '8px'
          }}
        >
          <AnyReactComponent
            lat={defaultValues.lat}
            lng={defaultValues.lng}
          // text="IYTE"
          />
        </GoogleMapReact>
      </Box>

      {/* <Typography
              sx={{
                  color: '#FF5630',
                  fontSize: '12px',
                  mt: '5px'
              }}
          >
              {errors?.lat?.message}
          </Typography> */}
    </>
  );
}


const AnyReactComponent = ({ text = "konum", color = "red" }) => (
  <Box
    sx={{
      position: 'relative',
      top: '-50px',
      left: '-20px',
      width: '40px',
      height: '40px',
      backgroundColor: color,
      transform: 'rotate(45deg)',
      borderRadius: '50%',
      borderEndEndRadius: '0px',
    }}
  >
    <Box
      sx={{
        width: '40px',
        height: '40px',
        backgroundColor: color,
        transform: 'rotate(-45deg)',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      }}
    >
      {text}
    </Box>
  </Box>
);
