import { reduxFunc } from 'src/hophop/redux'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Container, Grid, IconButton, Table, TableBody, TableContainer, Tooltip } from '@mui/material'

import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify/iconify'
import Scrollbar from 'src/components/scrollbar/scrollbar'
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table'

import PaymentTableRow from './_PaymentTableRow'
import PaymentSummeryItem from './_PaymentSummeryItem'


const TABLE_HEAD = [
    { id: 'name', label: 'Aktivite' },
    { id: 'createdBy', label: 'Ücret' },
    { id: 'owner', label: 'Kontenjan' },
    { id: 'category', label: 'Toplam Ücret' },
    { id: 'date', label: 'Durumu' },
    { id: 'star', label: 'Puan' },
];

const Payments = () => {

    const table = useTable();
    const confirm = useBoolean();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { accessToken } = useSelector(state => state.auth)
    const { activitiesForModerator: list } = useSelector(state => state.activity)
    const {
        total_revenue,
        monthly_revenue,
        weekly_revenue
    } = useSelector(state => state.payment)

    const [variables, setVariables] = useState({
        "filter": {
            // "byCoordinates": {
            //   "lat": null,
            //   "long": null,
            //   "radius": null
            // },
            "city": null,
            "createdByCurrentUser": null,
            "district": null,
            "parking": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 5
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({ accessToken, variables }))
            dispatch(reduxFunc.paymentFunc.fetchModeratorRevenueFunc({ accessToken }))
        }
    }, [accessToken, dispatch, variables])


    const handleChangePage = (a, b) => {
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))
        table.onChangePage(a, b)
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Ödemeler"
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={4}>
                    <PaymentSummeryItem
                        title="Toplam Kazanç"
                        total={Math.floor(total_revenue) ?? 0}
                        icon={<Iconify icon="fluent:payment-20-filled" width={80} />}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <PaymentSummeryItem
                        title="Aylık Kazanç"
                        total={Math.floor(monthly_revenue) ?? 0}
                        icon={<Iconify icon="ic:baseline-calendar-month" width={80} />}
                        color="secondary"
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <PaymentSummeryItem
                        title="Haftalık Kazanç"
                        total={Math.floor(weekly_revenue) ?? 0}
                        icon={<Iconify icon="tdesign:money" width={80} />}
                        color="warning"
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 8 }}>

                    <Card >

                        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                            <TableSelectedAction
                                dense={table.dense}
                                numSelected={table?.selected?.length}

                                rowCount={list?.length}
                                onSelectAllRows={(checked) =>
                                    table.onSelectAllRows(
                                        checked,
                                        list?.data.map((row) => row.id)
                                    )
                                }
                                action={
                                    <>
                                        <Tooltip title="Mesaj">
                                            <IconButton color="primary" onClick={confirm.onTrue}>
                                                <Iconify icon="solar:trash-bin-trash-bold" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Bildirim">
                                            <IconButton color="primary" onClick={confirm.onTrue}>
                                                <Iconify icon="solar:trash-bin-trash-bold" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            />

                            <Scrollbar>
                                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                                    <TableHeadCustom
                                        order={table.order}
                                        orderBy={table.orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={list?.data?.length}
                                        numSelected={table?.selected?.length}
                                        onSort={table.onSort}
                                    />

                                    <TableBody>
                                        {
                                            list?.data?.map((row) => (
                                                <PaymentTableRow
                                                    key={row?._id}
                                                    row={row}
                                                    selected={table.selected.includes(row?._id)}
                                                    onSelectRow={() => table.onSelectRow(row?._id)}
                                                />
                                            ))
                                        }
                                        <TableNoData notFound={list?.count === 0} />
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </TableContainer>

                        <TablePaginationCustom
                            page={table?.page}
                            dense={table.dense}
                            count={list?.count ?? 0}
                            onPageChange={handleChangePage}
                            onChangeDense={table.onChangeDense}
                            rowsPerPage={variables?.pagination?.pageSize}
                        />

                    </Card>
                </Grid>

            </Grid>


        </Container>
    )
}

export default Payments