import { organizationPresignedUrls } from './_organizationPresignedUrls'
import { listingImagesPresignedUrls } from './_listingImagesPresignedUrls'

import { handleSaveImage } from './_handleSaveImage'

export const dms = {
    organizationPresignedUrls,
    listingImagesPresignedUrls,

    handleSaveImage,
}

