import { Helmet } from 'react-helmet-async';

import CompactLayout from 'src/layouts/compact/layout';
import ClassicForgotPasswordView from './classic-forgot-password-view';


// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  return (
    <>
      <Helmet>
        <title> Reset Password</title>
      </Helmet>
      <ClassicForgotPasswordView />
    </>
  );
}
