import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import AccountGeneral from './sections/AccountGeneral';
import { useSettingsContext } from '../../../../../components/settings';
// import CustomBreadcrumbs from '../../../../../../components/custom-breadcrumbs/CustomBreadcrumbs';



export default function UserAccountPage() {

  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> User: Account Settings </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>

        <CustomBreadcrumbs heading="Profil" />

        <AccountGeneral />

      </Container>
    </>
  );
}
