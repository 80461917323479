const blogDetail = ({ blogId }) => {

  const data = JSON.stringify({
    query: `
    query Anonymous_activityBlog($activityBlogId: String!) {
      anonymous_activityBlog(activityBlogId: $activityBlogId) {
        _id
        additionalDetails
        createdAt
        createdBy {
          _id
        }
        duration
        listing {
          _id
          title
          category
          subCategory
          ageInterval
          format
          description
          images
          coverImage
          companionship

          exteriorOrganizer {
            _id
            contact {
              phoneNumber
              email
            }
            socialMedia {
              facebook
              instagram
              twitter
              website
              youtube
            }
          }
        }
        location {
          _id
          city
          district
          country
          address {
            title
            description
          }
          lat
          long
          parking
        }
        schedule {
          _id
          repeatedIntervalType
          startDate
          endDate
          totalDayCount
          openHour
          closeHour
          createdAt
          updatedAt
        }
        updatedAt
      }
    }
    `,
    variables: {
      "activityBlogId": blogId
    }
  })

  return data

}

const allBlogs = ({ variables = null }) => {

  const data = JSON.stringify({
    query: `
    query Anonymous_activityBlogs($pagination: Pagination, $filter: FilterActivityBlogsByAnonymousInput) {
      anonymous_activityBlogs(pagination: $pagination, filter: $filter) {
        count
        data {
          _id
          additionalDetails
          createdAt
          fee
          createdBy {
            _id
          }
          duration
          updatedAt
          schedule {
            _id
            closeHour
            createdAt
            updatedAt
            totalDayCount
            startDate
            repeatedIntervalType
            openHour
            endDate
            days {
              _id
              activityBlog {
                _id
              }
              createdAt
              date
              day
              endTime
              startTime
              updatedAt
            }
          }
          listing {
            _id
            title
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship

            exteriorOrganizer {
              _id
              contact {
                phoneNumber
              }
              socialMedia {
                facebook
                instagram
                twitter
                website
                youtube
              }
            }


          }
          location {
            _id
            city
            district
            country
            address {
              title
              description
            }
            lat
            long
            parking
          }
        }
      }
    }
    `,
    variables: {
      "filter": {
        "additionalDetails": null,
        "plus": variables?.filter?.plus ?? null,
        "duration": {
          "to": null,
          "from": null
        },
        "listing": {
          "ageInterval": null,
          "category": null,
          "companionship": null,
          "coverImage": null,
          "description": null,
          "format": null,
          "images": null,
          "subCategory": null,
          "title": null
        },
        "location": {
          "parking": null,
          "district": null,
          "city": null,
          "byCoordinates": {
            "lat": null,
            "long": null,
            "radius": null
          }
        },
        // "schedule": {
        //   "dateInterval": {
        //     "from": null,
        //     "to": null
        //   },
        //   "days": null,
        //   "finished": null
        // }
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }

  })

  return data

}

const isUpdatable = ({ listingId }) => {

  const data = JSON.stringify({
    query: `

    `,
    variables: {}
  })

  return data
}

export const blogsForModerator = {
  blogDetail,
  allBlogs,
  isUpdatable
}