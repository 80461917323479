const userQuery = ({ userId }) => {

  const data = JSON.stringify({
    query: `
        query User($userId: ID!) {
            user(userId: $userId) {
              _id
              onboarded
              role
              personalInformation {
                fullName
                phoneNumber
                profileImageUrl
                description
                childAge
                identificationNumber
                organization
                taxOffice
                taxNo
              }

              socialMedia {
                website
                instagram
                twitter
                facebook
                youtube
              }
              
              contact {
                phoneNumber
                phoneNumberVerified
                email
                emailVerified
                address {
                  city
                  district
                  content {
                    title
                    description
                  }
                  lat
                  long
                }
              }
              rating {
                average
                numberOfReviews
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        `,
    variables: {
      "userId": userId
    }
  })

  return data
}
const paymentUserQuery = () => {

  const data = JSON.stringify({
    query: `
    query Self_user {
      self_user {
        _id
        role
        displayName
        displayImageUrl
        subMerchantInfo {
          subMerchantKey
          subMerchantType
        }
        createdAt
        updatedAt
      }
    }
        `,
    variables: {}

  })

  return data
}


const allUsersQuery = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Users($filter: FilterUserInput, $pagination: Pagination) {
      users(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          contact {
            email
            address {
              city
              content {
                title
                description
              }
              district
              lat
              long
            }
            emailVerified
            phoneNumber
            phoneNumberVerified
          }
          createdAt
          onboarded
          personalInformation {
            fullName
            description
            childAge
            identificationNumber
            organization
            phoneNumber
            profileImageUrl
            taxNo
            taxOffice
          }
          socialMedia {
            youtube
            twitter
            website
            instagram
            facebook
          }
          role
          updatedAt
        }
      }
    }
        `,
    variables: {
      "filter": {
        "role": variables?.filter?.role ?? null,
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null,
      }
    }
  })

  return data
}


export const userQueries = {
  userQuery,
  allUsersQuery,
  paymentUserQuery,
}
