import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
// assets
import { EmailInboxIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ClassicVerifyView() {

  const { handleFetchData } = useFetchData()

  const VerifySchema = Yup.object().shape({
    code: Yup.string().min(6, 'Code must be at least 6 characters').required('Code is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    code: '',
    email: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await handleFetchData({
        data: JSON.stringify({
          code: data.code,
          email: data.email
        }),
        url: "/auth/auth/confirmRegistration"
      })
      enqueueSnackbar('Email başarılı bir şekilde onaylanmıştır!');
    } catch (error) {
      console.error(error);
    }
  });


  const handleResendCode = () => {

  }

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="email"
        label="Email"
        placeholder="example@gmail.com"
        InputLabelProps={{ shrink: true }}
      />

      <RHFCode name="code" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Onayla
      </LoadingButton>

      <Typography variant="body2">
        {`Don’t have a code? `}
        <Link
          onClick={handleResendCode}
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
          }}
        >
          Tekrar gönder
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        href="/auth/login"
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Geri dön
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <EmailInboxIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Emailini kontrol ediniz!</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Emailinize gönderilen kodu giriniz.
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
