export default function getDates(startDate, stopDate, interval = 1) {
    const dateArray = [];
    const currentDate = new Date(startDate);

    while (currentDate <= stopDate) {

        if (interval === "monthly") {
            dateArray.push(new Date(currentDate));

            currentDate.setMonth(currentDate.getMonth() + 1)
        } else if (typeof interval === "object") {

            if (interval.includes(currentDate?.getDay())) {
                dateArray.push(new Date(currentDate.getTime()));
            }
            currentDate.setDate(currentDate.getDate() + 1);

        } else {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + interval);
        }

    }

    return dateArray;
}