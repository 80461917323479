import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export function RHFAutocomplete2({
  name,
  rules,
  options,
  getOptionLabel,
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={null}
      render={({
        field: { ref, ...field },
        fieldState: { error, invalid }
      }) => {
        return (
          <Autocomplete
            {...field}
            multiple
            handleHomeEndKeys
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                inputRef={ref}
                error={invalid}
                helperText={error?.message}
              />
            )}
            onChange={(e, value) => field.onChange(value)}
            onInputChange={(_, data) => {
              if (data) field.onChange(data);
            }}
          />
        );
      }}
    />
  );
}

RHFAutocomplete2.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired
};

export default RHFAutocomplete2;


