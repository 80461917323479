const makeActive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsActive($activityId: String!) {
      moderator_setActivityAsActive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const makePassive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsPassive($activityId: String!) {
      moderator_setActivityAsPassive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const createActivity = ({ values }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_createActivity($activityInput: ActivityByOrganizerInput!) {
      organizer_createActivity(activityInput: $activityInput) {
        _id
      }
    }
              `,
    variables: {
      "activityInput": {
        "additionalDetails": values?.additionalDetails,
        "duration": values?.duration,
        "fee": values?.fee,
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires,
        "listingId": values?.listingId,
        "locationId": values?.locationId,
        "price": Number(values?.price),
        "quota": values?.quota ?? 0,
        "schedule": {
          "days": values?.schedule?.days,
          // "endDate": values?.schedule?.endDate,
          // "startDate": values?.schedule?.startDate,
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType,
          "totalDayCount": values?.schedule?.totalDayCount
        }
      }
    }

  })

  return data

}


const createActivityWithListingAndLocation = ({ values, coverImage, images }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_createActivityAlongSideListingLocation($activityInput: PendingActivityInput!) {
      organizer_createActivityAlongSideListingLocation(activityInput: $activityInput) {
        _id
      }
    }
    `,
    variables: {
      "activityInput": {
        "additionalDetails": values?.additionalDetails ?? null,
        "duration": values?.duration ?? null,
        "fee": values?.fee ?? null,
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires ?? null,
        "price": Number(values?.price ?? 0) ?? null,
        "quota": values?.quota ? Number(values?.quota) : 0,


        "schedule": {
          "days": values?.schedule?.days ?? [],
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType ?? null,
          "totalDayCount": values?.schedule?.totalDayCount ?? null
        },
        // "status": values?.status ?? "active",
        "title": values?.title ?? null,


        ...(values?.listingId && { "listingId": values?.listingId ?? null }),
        ...(values?.locationId && { "locationId": values?.locationId ?? null }),


        ...(!values?.listingId && {
          "listing": {
            // "ageInterval": values?.listing?.ageInterval ?? null,
            "ageInterval": (typeof (values?.listing?.ageInterval?.[0]) !== "number" ? values?.listing?.ageInterval?.[0] : values?.listing?.ageInterval),

            "category": values?.listing?.category ?? null,
            "companionship": values?.listing?.companionship ?? null,
            "coverImage": coverImage ?? null,
            "description": values?.listing?.description,
            "format": values?.listing?.format,
            "images": images,
            "subCategory": values?.listing?.subCategory ?? "activity",
            "title": values?.listing?.title
          },
        }),


        ...(!values?.locationId && {
          "location": {
            "address": {
              "description": values?.location?.address?.description ?? "online",
              "title": values?.location?.address?.title ?? "online"
            },
            "city": values?.location?.city ?? "online",
            "district": values?.location?.district ?? "online",
            "lat": values?.location?.lat ?? 0,
            "long": values?.location?.long ?? 0,
            "parking": values?.location?.parking ?? "nonExists",
          }
        }),









      }
    }

  })

  return data

}


const removeActivity = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeActivity($activityId: String!) {
      moderator_removeActivity(activityId: $activityId) {
        success
      }
    }
    `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const updateActivity = ({ activityId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateActivity($activityId: String!, $updateInput: UpdateActivityByModeratorInput!) {
      moderator_updateActivity(activityId: $activityId, updateInput: $updateInput) {
        _id
      }
    }
              `,
    variables: {
      "activityId": activityId,
      "updateInput": {
        "additionalDetails": values?.additionalDetails ?? "",
        "duration": values?.duration ?? "",
        "fee": values?.fee ?? "",
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires ?? "",
        "price": values?.price ? Number(values?.price) : 0,
        "quota": values?.quota ?? 0,
        "schedule": {
          "days": values?.schedule?.days ?? [],
          "endDate": values?.schedule?.endDate ?? "",
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType ?? "",
          "startDate": values?.schedule?.startDate ?? "",
          "totalDayCount": values?.schedule?.totalDayCount ?? ""
        }
      }
    }

  })

  return data

}

const updateActivityWithListingAndLocation = ({ activityId, values, images, coverImage }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_updateActivityAlongSideListingLocation($activityId: String!, $updateInput: UpdateActivityByOrganizerInput!) {
      organizer_updateActivityAlongSideListingLocation(activityId: $activityId, updateInput: $updateInput) {
        _id
      }
    }
    `,
    variables: {
      "activityId": activityId,
      "updateInput": {
        "additionalDetails": values?.additionalDetails ?? "",
        "duration": values?.duration ?? "",
        "fee": values?.fee ?? "",
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires ?? "",

        "listing": {
          // "ageInterval": values?.listing?.ageInterval ?? "",
          "ageInterval": (typeof (values?.listing?.ageInterval?.[0]) !== "number" ? values?.listing?.ageInterval?.[0] : values?.listing?.ageInterval),
          "category": values?.listing?.category ?? "",
          "companionship": values?.listing?.companionship ?? "",
          "coverImage": coverImage,
          "description": values?.listing?.description ?? "",
          "format": values?.listing?.format ?? "",
          "images": images,
          "subCategory": values?.listing?.subCategory ?? "",
          "title": values?.listing?.title ?? ""
        },
        "location": {
          "address": {
            "title": values?.location?.address?.title ?? "online",
            "description": values?.location?.address?.description ?? ""
          },
          "city": values?.location?.city ?? "",
          "country": values?.location?.country ?? "Turkey",
          "district": values?.location?.district ?? "",
          "lat": values?.location?.lat ?? "",
          "long": values?.location?.long ?? "",
          "parking": values?.location?.parking ?? "nonExists"
        },
        "price": values?.price ? Number(values?.price) : 0,
        "quota": Number(values?.quota ?? 0) ?? 0,
        "schedule": {
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType ?? "",
          "days": values?.schedule?.days ?? "",
          "totalDayCount": values?.schedule?.totalDayCount ?? ""
        },
        "title": values?.title ?? ""
      }
    }

  })

  return data

}




export const activitiesForOrganizer = {
  createActivity,
  removeActivity,
  updateActivity,

  createActivityWithListingAndLocation,
  updateActivityWithListingAndLocation,

  makeActive,
  makePassive,
}