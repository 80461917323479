import LoginPage from './login/LoginPage'
import RegisterPage from './register/RegisterPage'
import VerifyCodePage from './register/VerifyCodePage'

import ResetPasswordPage from './forget-password/ResetPasswordPage'
import NewPasswordPage from './forget-password/NewPasswordPage'



export const AuthPages = {
    LoginPage,
    NewPasswordPage,
    RegisterPage,
    ResetPasswordPage,
    VerifyCodePage,
}