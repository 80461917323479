import PropTypes from 'prop-types';
import { tr } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import { Box, Stack, Avatar, Typography } from '@mui/material';


export default function ChatMessageItem({ message, participants, onOpenLightbox }) {

  const { user } = useSelector(state => state.auth);

  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 1,
        color: 'text.disabled',
        ...(user?.userId !== message?.sender?._id && {
          mr: 'auto',
        }),
      }}
    >
      {user?.userId !== message?.sender?._id && `${message?.sender?.displayName}, `}
      {formatDistanceToNow(new Date(message?.updatedAt), { locale: tr })} &nbsp; önce
    </Typography>
  );

  // mesaj mı yoksa file mı olduğunu kontrol ediyor
  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        maxWidth: 320,
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(true && {
          color: 'grey.800',
          bgcolor: 'primary.lighter',
        }),
        ...(false && {
          p: 0,
          bgcolor: 'transparent',
        }),
      }}
    >
      {false ? (
        <Box
          component="img"
          alt="attachment"
          src="body"
          // onClick={() => onOpenLightbox(body)}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.9,
            },
          }}
        />
      ) : (
        message?.content
      )}
    </Stack>
  );

  // const renderActions = (
  //   <Stack
  //     direction="row"
  //     className="message-actions"
  //     sx={{
  //       pt: 0.5,
  //       opacity: 0,
  //       top: '100%',
  //       left: 0,
  //       position: 'absolute',
  //       transition: (theme) =>
  //         theme.transitions.create(['opacity'], {
  //           duration: theme.transitions.duration.shorter,
  //         }),
  //       ...(true && {
  //         left: 'unset',
  //         right: 0,
  //       }),
  //     }}
  //   >
  //     <IconButton size="small">
  //       <Iconify icon="solar:reply-bold" width={16} />
  //     </IconButton>
  //     <IconButton size="small">
  //       <Iconify icon="eva:smiling-face-fill" width={16} />
  //     </IconButton>
  //     <IconButton size="small">
  //       <Iconify icon="solar:trash-bin-trash-bold" width={16} />
  //     </IconButton>
  //   </Stack>
  // );

  return (
    <Stack
      direction="row"
      justifyContent={message?.sender?.role !== "parent" ? 'flex-end' : 'unset'}
      sx={{
        mb: 5,
      }}
    >

      {
        true && <Avatar
          alt={message?.sender?.displayName}
          src={message?.sender?.displayImageUrl?.startsWith("http") ? message?.sender?.displayImageUrl : ""}
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      }

      <Stack
        alignItems={message?.sender?.role === "moderator" ? "flex-end" : "flex-start"}>
        {renderInfo}

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
        </Stack>
      </Stack>
    </Stack>
  );
}

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  onOpenLightbox: PropTypes.func,
  participants: PropTypes.array,
};
