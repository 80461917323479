import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Grid, Divider, Box, Typography, Button, Tabs, Tab, Checkbox, TextField, FormControlLabel, Select, MenuItem } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { fetchParametersFunc } from 'src/hophop/redux/slices/_parameterSlice';
import { fetchSettingsFunc } from 'src/hophop/redux/slices/_activitySlice';
import Iconify from 'src/components/iconify';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { graphqlApi } from 'src/hophop/api';
import { enqueueSnackbar } from 'notistack';


export const UygulamaDuzeni = () => {

  const dispatch = useDispatch();
  const { handleFetchData } = useFetchData()
  const { categories } = useSelector((state) => state.parameter);
  const { accessToken } = useSelector((state) => state.auth);

  const [settigs, setSettings] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [sliderCategories, setSliderCategories] = useState([]);
  const [section1Categories, setSection1Categories] = useState([]);
  const [section2Categories, setSection2Categories] = useState([]);
  const [section3Categories, setSection3Categories] = useState([]);
  const [categoryRankingCategories, setCategoryRankingCategories] = useState([]); // New state for Category Ranking tab

  const [finalArray, setFinalArray] = useState([]);

  const [showTitles, setShowTitles] = useState([false, false, false, false, false]); // Update for new tab
  const [titles, setTitles] = useState(["", "", "", "", ""]); // Update for new tab
  const [componentTypes, setComponentTypes] = useState(["", "", "", "", ""]); // Update for new tab


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleShowTitleChange = (index) => (event) => {
    const newShowTitles = [...showTitles];
    newShowTitles[index] = event.target.checked;
    setShowTitles(newShowTitles);
    if (!event.target.checked) {
      const newTitles = [...titles];
      newTitles[index] = "";
      setTitles(newTitles);
    }
  };

  const handleTitleChange = (index) => (event) => {
    const newTitles = [...titles];
    newTitles[index] = event.target.value;
    setTitles(newTitles);
  };

  const handleComponentTypeChange = (index) => (event) => {
    const newComponentTypes = [...componentTypes];
    newComponentTypes[index] = event.target.value;
    setComponentTypes(newComponentTypes);
  };

  const addCategoryToSection = (category, setSectionFunc) => {
    setSectionFunc((prev) => [...prev, category]);
  };

  const removeCategoryFromSection = (categoryId, setSectionFunc) => {
    setSectionFunc((prev) => prev.filter((cat) => cat._id !== categoryId));
  };

  const moveCategoryInSection = (index, direction, sectionCategories, setSectionFunc) => {
    const newCategories = [...sectionCategories];
    const [removed] = newCategories.splice(index, 1);
    newCategories.splice(index + direction, 0, removed);
    setSectionFunc(newCategories);
  };

  const isCategoryInSection = (categoryId, sectionCategories) => {
    return sectionCategories.some(category => category._id === categoryId);
  };

  const renderCategorySection = (sectionCategories, setSectionFunc) => (
    <Box sx={{ p: 2 }}>
      {sectionCategories.map((category, index) => (
        <Box key={category._id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" sx={{ flexGrow: 1 }}>{category._id}</Typography>
          <Button onClick={() => moveCategoryInSection(index, -1, sectionCategories, setSectionFunc)} disabled={index === 0}>Yukarı</Button>
          <Button onClick={() => moveCategoryInSection(index, 1, sectionCategories, setSectionFunc)} disabled={index === sectionCategories.length - 1}>Aşağı</Button>
          <Button onClick={() => removeCategoryFromSection(category._id, setSectionFunc)}>Çıkar</Button>
        </Box>
      ))}
    </Box>
  );

  const handleSaveOptions = async () => {
    console.log('Save options');
    console.log(finalArray);

    const updateSettings = await handleFetchData({
      data: graphqlApi.activity.mutations.activitiesForModerator.updateSettings({
        dashboardComponents: finalArray
      }),
      url: "/activity/graphql",
      accessToken,
    });

    console.log(updateSettings?.data?.data?.moderator_updateSettings?._id, 'updateSettings');

    if (updateSettings?.data?.data?.moderator_updateSettings?._id) {
      enqueueSnackbar('Ayarlar başarıyla kaydedildi.', { variant: 'success' });
    } else {
      enqueueSnackbar('Ayarlar kaydedilirken bir hata oluştu.', { variant: 'error' });
    }
  };

  useEffect(() => {
    const getFinalArray = () => {
      return [
        {
          categories: sliderCategories.map(category => ({ category: category._id })),
          componentType: componentTypes[0],
          name: titles[0],
          nameVisible: showTitles[0]
        },
        {
          categories: section1Categories.map(category => ({ category: category._id })),
          componentType: componentTypes[1],
          name: titles[1],
          nameVisible: showTitles[1]
        },
        {
          categories: section2Categories.map(category => ({ category: category._id })),
          componentType: componentTypes[2],
          name: titles[2],
          nameVisible: showTitles[2]
        },
        {
          categories: section3Categories.map(category => ({ category: category._id })),
          componentType: componentTypes[3],
          name: titles[3],
          nameVisible: showTitles[3]
        },
        {
          categories: categoryRankingCategories.map(category => ({ category: category._id })), // Add new tab data
          componentType: componentTypes[4],
          name: titles[4],
          nameVisible: showTitles[4]
        }
      ];
    };

    setFinalArray(getFinalArray());
  }, [sliderCategories, section1Categories, section2Categories, section3Categories, categoryRankingCategories, showTitles, titles, componentTypes]);



  useEffect(() => {
    dispatch(fetchParametersFunc({ accessToken }));
    dispatch(fetchSettingsFunc({ accessToken })).then((response) => {

      console.log(response, 'response')
      if (response?.payload?.data?.settings?.dashboardComponents) {
        const { dashboardComponents } = response?.payload?.data?.settings || {};
        setSettings(response?.payload?.data?.settings);
        setSliderCategories(dashboardComponents[0]?.categories);
        setSection1Categories(dashboardComponents[1]?.categories);
        setSection2Categories(dashboardComponents[2]?.categories);
        setSection3Categories(dashboardComponents[3]?.categories);
        setCategoryRankingCategories(dashboardComponents[4]?.categories); // Add new tab data
        setShowTitles([
          dashboardComponents[0]?.nameVisible,
          dashboardComponents[1]?.nameVisible,
          dashboardComponents[2]?.nameVisible,
          dashboardComponents[3]?.nameVisible,
          dashboardComponents[4]?.nameVisible // Add new tab data
        ]);
        setTitles([
          dashboardComponents[0]?.name,
          dashboardComponents[1]?.name,
          dashboardComponents[2]?.name,
          dashboardComponents[3]?.name,
          dashboardComponents[4]?.name // Add new tab data
        ]);
        setComponentTypes([
          dashboardComponents[0]?.componentType,
          dashboardComponents[1]?.componentType,
          dashboardComponents[2]?.componentType,
          dashboardComponents[3]?.componentType,
          dashboardComponents[4]?.componentType // Add new tab data
        ]);
      }
    }
    );
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      setSliderCategories(categories.slice(0, 5)); // Başlangıçta ilk 5 kategoriyi seç
    }
  }, [categories]);



  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs heading="Uygulama Düzeni"
        action={
          <Button
            onClick={handleSaveOptions}
            variant="contained"
            startIcon={<Iconify icon="mdi:content-save" />}
          >
            Kaydet
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Slider" />
        <Tab label="Bölüm 1" />
        <Tab label="Bölüm 2" />
        <Tab label="Bölüm 3" />
        <Tab label="Kategoriler - Genel" /> {/* New tab */}
      </Tabs>

      <FormControlLabel
        control={<Checkbox checked={showTitles[tabIndex]} onChange={handleShowTitleChange(tabIndex)} />}
        label="Başlık olsun mu?"
        sx={{ mt: 2 }}
      />
      {showTitles[tabIndex] && (
        <TextField
          label="Başlık"
          value={titles[tabIndex]}
          onChange={handleTitleChange(tabIndex)}
          sx={{ mt: 2, mb: 2 }}
          fullWidth
        />
      )}

      <Select
        value={componentTypes[tabIndex]}
        onChange={handleComponentTypeChange(tabIndex)}
        displayEmpty
        fullWidth
        sx={{ mt: 2, mb: 2 }}
      >
        <MenuItem value="" disabled>Liste Görünümü</MenuItem>
        <MenuItem value="slider">Slider</MenuItem>
        <MenuItem value="oneColumnVertical">Tek Sütun Liste</MenuItem>
        <MenuItem value="twoColumnvertical">Çift Sütun Liste</MenuItem>
      </Select>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} sx={{ mt: 8 }}>
          <Card>
            <Typography variant="h5" sx={{ p: 2 }}>
              {tabIndex === 0 && "Slider"}
              {tabIndex === 1 && "Bölüm 1"}
              {tabIndex === 2 && "Bölüm 2"}
              {tabIndex === 3 && "Bölüm 3"}
              {tabIndex === 4 && "Kategoriler - Genel"} {/* New tab title */}
            </Typography>
            <Divider />
            {tabIndex === 0 && renderCategorySection(sliderCategories, setSliderCategories)}
            {tabIndex === 1 && renderCategorySection(section1Categories, setSection1Categories)}
            {tabIndex === 2 && renderCategorySection(section2Categories, setSection2Categories)}
            {tabIndex === 3 && renderCategorySection(section3Categories, setSection3Categories)}
            {tabIndex === 4 && renderCategorySection(categoryRankingCategories, setCategoryRankingCategories)} {/* New tab content */}
          </Card>
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mt: 8 }}>
          <Card>
            <Typography variant="h5" sx={{ p: 2 }}>Tüm Kategoriler</Typography>
            <Divider />
            <Box sx={{ p: 2 }}>
              {categories?.data?.map((category) => (
                <Box key={category._id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>{category._id}</Typography>
                  <Button
                    onClick={() => {
                      if (tabIndex === 0) addCategoryToSection(category, setSliderCategories);
                      if (tabIndex === 1) addCategoryToSection(category, setSection1Categories);
                      if (tabIndex === 2) addCategoryToSection(category, setSection2Categories);
                      if (tabIndex === 3) addCategoryToSection(category, setSection3Categories);
                      if (tabIndex === 4) addCategoryToSection(category, setCategoryRankingCategories); // Add category to new tab
                    }}
                    disabled={
                      tabIndex === 0 && isCategoryInSection(category._id, sliderCategories) ||
                      tabIndex === 1 && isCategoryInSection(category._id, section1Categories) ||
                      tabIndex === 2 && isCategoryInSection(category._id, section2Categories) ||
                      tabIndex === 3 && isCategoryInSection(category._id, section3Categories) ||
                      tabIndex === 4 && isCategoryInSection(category._id, categoryRankingCategories) // Check for new tab
                    }
                  >
                    Ekle
                  </Button>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UygulamaDuzeni;
