/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'

// graphqlApi

const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/payment/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    payment_user: null,
    loading: false,
    isOnboarding: null,

    total_revenue: 0,
    monthly_revenue: 0,
    weekly_revenue: 0,

}

// payment user information
export const fetchPaymentUserInfoFunc = createAsyncThunk('payment/fetchPaymentUserInfo', async ({ userId, accessToken }) => {
    const response = await axios(configFunc({ accessToken, data: graphqlApi.payment.queries.subMerchantInformations() }))
    return response?.data
})

// organizer revenue
export const fetchOrganzerRevenueFunc = createAsyncThunk('payment/fetchOrganzerRevenueFunc', async ({ accessToken }) => {
    const response = await axios(configFunc({ accessToken, data: graphqlApi.payment.queries.organizerTotalRevenue() }))
    return response?.data
})

// moderator revenue
export const fetchModeratorRevenueFunc = createAsyncThunk('payment/fetchModeratorRevenueFunc', async ({ accessToken }) => {
    const response = await axios(configFunc({ accessToken, data: graphqlApi.payment.queries.moderatorTotalRevenue() }))
    return response?.data
})



const { reducer: paymentReducer } = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# payment user information ######
        builder.addCase(fetchPaymentUserInfoFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchPaymentUserInfoFunc.fulfilled, (state, action) => {

            state.payment_user = action.payload?.data?.self_retrieveIyzicoSubMerchant
            state.loading = false
        })
        builder.addCase(fetchPaymentUserInfoFunc.rejected, (state, action) => {

            state.loading = false
        })


        // ################# moderator revenue ######
        builder.addCase(fetchModeratorRevenueFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchModeratorRevenueFunc.fulfilled, (state, action) => {

            state.total_revenue = action.payload?.data?.total?.totalHopHopPayoutAmount
            state.monthly_revenue = action.payload?.data?.monthly?.totalHopHopPayoutAmount
            state.weekly_revenue = action.payload?.data?.weekly?.totalHopHopPayoutAmount
            state.loading = false
        })
        builder.addCase(fetchModeratorRevenueFunc.rejected, (state, action) => {

            state.loading = false
        })


        // ################# organizer revenue ######
        builder.addCase(fetchOrganzerRevenueFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchOrganzerRevenueFunc.fulfilled, (state, action) => {

            state.total_revenue = action.payload?.data?.total?.totalOrganizerPayoutAmount
            state.monthly_revenue = action.payload?.data?.monthly?.totalOrganizerPayoutAmount
            state.weekly_revenue = action.payload?.data?.weekly?.totalOrganizerPayoutAmount
            state.loading = false
        })
        builder.addCase(fetchOrganzerRevenueFunc.rejected, (state, action) => {

            state.loading = false
        })





    }
})


export default paymentReducer

export const paymentFunc = {
    fetchPaymentUserInfoFunc,
    fetchOrganzerRevenueFunc,
    fetchModeratorRevenueFunc,
}