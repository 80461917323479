/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    listingsForModerator: [],
    listingsForOrganizer: [],
    selectedListingForModerator: null,
    selectedListingForOrganizer: null,
    allOrganizerListingsForModerator: null,
    loading: false,
    error: false,
}

// moderator listings
export const fetchListingsForModeratorFunc = createAsyncThunk('listing/fetchListingsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.listingsForModerator.allListings({ variables })
    }))
    return response?.data
})

// moderator selected listing 
export const fetchSelectedListingForModeratorFunc = createAsyncThunk('listing/fetchSelectedListingForModeratorFunc', async ({ accessToken, listingId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.listingsForModerator.listingDetail({ listingId })
    }))
    return response?.data
})


// organizer listings
export const fetchListingsForOrganizatorFunc = createAsyncThunk('listing/fetchListingsForOrganizatorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.listingsForOrganizer.allListings({ variables })
    }))
    return response?.data
})

// organizer selected listing
export const fetchSelectedListingForOrganizerFunc = createAsyncThunk('listing/fetchSelectedListingForOrganizerFunc', async ({ accessToken, listingId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.listingsForOrganizer.listingDetail({ listingId })
    }))

    return response?.data
})


// all organizer listings for moderator approve
export const fetchAllOrganizerLisstingsForModeratorFunc = createAsyncThunk('listing/fetchAllOrganizerLisstingsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.listingsForModerator.allOrganizerListingsForModerator({ variables })
    }))
    console.log({ response })
    return response?.data
})



const { reducer: listingReducer, actions } = createSlice({
    name: 'listing',
    initialState,
    reducers: {
        resetSelectedListing: (state, action) => {
            state.selectedListingForModerator = null
            state.selectedListingForOrganizer = null
        }
    },
    extraReducers: (builder) => {
        // ################# moderator all list ######
        builder.addCase(fetchListingsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchListingsForModeratorFunc.fulfilled, (state, action) => {

            state.listingsForModerator = action.payload?.data?.moderator_listingsOfModerators
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchListingsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# moderator item detail ######
        builder.addCase(fetchSelectedListingForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedListingForModeratorFunc.fulfilled, (state, action) => {

            state.selectedListingForModerator = action.payload?.data?.moderator_listing
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedListingForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // ################# organizator all list ######
        builder.addCase(fetchListingsForOrganizatorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchListingsForOrganizatorFunc.fulfilled, (state, action) => {

            state.listingsForOrganizer = action.payload?.data?.organizer_listings
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchListingsForOrganizatorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# organizator item detail ######
        builder.addCase(fetchSelectedListingForOrganizerFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedListingForOrganizerFunc.fulfilled, (state, action) => {

            state.selectedListingForOrganizer = action.payload?.data?.organizer_listing
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedListingForOrganizerFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })


        // ################# all organizator listings list for moderator ######
        builder.addCase(fetchAllOrganizerLisstingsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchAllOrganizerLisstingsForModeratorFunc.fulfilled, (state, action) => {

            state.allOrganizerListingsForModerator = action.payload?.data?.moderator_listingsOfOrganizers
            // {
            //     data: action.payload?.data?.moderator_listingsOfOrganizers?.data?.filter(item => item?.status === "pendingApproval"),
            //     count: action.payload?.data?.moderator_listingsOfOrganizers?.data?.filter(item => item?.status === "pendingApproval")?.length
            // }
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchAllOrganizerLisstingsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

    }
})


export default listingReducer

export const listingFunc = {
    fetchListingsForModeratorFunc,
    fetchListingsForOrganizatorFunc,
    fetchSelectedListingForModeratorFunc,
    fetchSelectedListingForOrganizerFunc,
    fetchAllOrganizerLisstingsForModeratorFunc,

    resetSelectedListing: actions.resetSelectedListing
}