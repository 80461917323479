export const readUserNotification = ({ notificationId }) => {

    const data = JSON.stringify({
        query: `
            mutation User_readUserNotification($uid: String!) {
                user_readUserNotification(uid: $uid) {
                success
                }
            }
        `,
        variables: {
            "uid": notificationId
        }

    })

    return data
}

export const readUserAllNotifications = () => {

    const data = JSON.stringify({
        query: `
            mutation User_readAllUserNotifications {
                user_readAllUserNotifications {
                success
                }
            }
        `,
        variables: {}

    })

    return data
}


export const sendNotification = ({ values }) => {

    const data = JSON.stringify({
        query: `
        mutation Admin_sendAdvertisementToParents($customBody: String) {
            admin_sendAdvertisementToParents(customBody: $customBody) {
              isSuccess
              errorMessage
              data {
                email {
                  isSuccess
                  errorMessage
                  messageId
                }
                sms {
                  isSuccess
                  errorMessage
                  messageId
                }
                push {
                  isSuccess
                  errorMessage
                  messageId
                }
              }
            }
          }
        `,
        variables: {
            "customTitle": values?.title,
            "customBody": values?.description,
        }

    })

    return data
}


export const mutations = {
    readUserNotification,
    readUserAllNotifications,
    sendNotification
}