import {
    reduxLoginFunc,
    reduxLogoutFunc,
} from './slices/_authSlice'

import { userFunc } from './slices/_userSlice'
import { locationFunc } from './slices/_locationSlice'
import { activityFunc } from './slices/_activitySlice'
import { listingFunc } from './slices/_listingSlice'
import { parameterFunc } from './slices/_parameterSlice'
import { blogFunc } from './slices/_blogSlice'
import { reservationFunc } from './slices/_reservationSlice'
import { customerFunc } from './slices/_customerSlice'
import { messageFunc } from './slices/_messageSlice'
import { paymentFunc } from './slices/_paymentSlice'
import { notificationFunc } from './slices/_notificationSlice'


export const reduxFunc = {
    reduxLoginFunc,
    reduxLogoutFunc,

    userFunc,
    locationFunc,
    activityFunc,
    listingFunc,
    reservationFunc,
    blogFunc,
    parameterFunc,
    customerFunc,
    messageFunc,
    paymentFunc,
    notificationFunc
}