// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ThemeProvider from 'src/theme';
import AppRouter from 'src/hophop/routes/AppRouter'
import ReduxProvider from 'src/hophop/redux/_store';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';

import './styles/main.scss'
import { trTR } from '@mui/x-date-pickers';
import { tr } from "date-fns/locale";


const HophopApp = () => {

    useScrollToTop();

    return (
        <ReduxProvider>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                // localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}
                adapterLocale={tr}
                localeText={{
                    ...trTR.components.MuiLocalizationProvider.defaultProps.localeText,

                }}
            >
                <SettingsProvider
                    defaultSettings={{
                        themeMode: 'light', // 'light' | 'dark'
                        themeDirection: 'ltr', //  'rtl' | 'ltr'
                        themeContrast: 'default', // 'default' | 'bold'
                        themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                        themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                        themeStretch: false,
                    }}
                >
                    <ThemeProvider>
                        <MotionLazy>
                            <SnackbarProvider>
                                <SettingsDrawer />
                                <ProgressBar />
                                <AppRouter />
                            </SnackbarProvider>
                        </MotionLazy>
                    </ThemeProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </ReduxProvider>
    );
}

export default HophopApp