/* eslint-disable arrow-body-style */
import { Outlet } from "react-router";

import NotFoundPage from "src/pages/404";
import { HophopPages } from "src/hophop/pages";
import Iconify from "src/components/iconify/iconify";
import DashboardLayout from "src/layouts/dashboard/layout";

import RouteGuard from "../RouteGuard";


const icon = (name = "mingcute:add-line") => (<Iconify icon={name} />)


const navConfig = [
    {
        subheader: 'hophop',
        items: [
            {
                title: 'panel',
                path: '/panel',
                icon: icon("mingcute:dashboard-fill"),
            },

            {
                title: 'Moderatorler',
                path: "/moderatorler",
                icon: icon("clarity:employee-group-solid"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },

            {
                title: 'Müşteriler',
                path: "/musteriler",
                icon: icon("fontisto:persons"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },

            {
                title: 'Ödemeler',
                path: "/odemeler",
                icon: icon("mdi:account-payment"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },

            {
                title: 'Bildirimler',
                path: "/bildirimler",
                icon: icon("mdi:bell-notification"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },



        ],
    },



];

export const adminRoutes = ({ userRole, isOnboarding }) => {


    return ({
        path: '',
        element: (
            <RouteGuard redirectPath="/" isAuth={userRole === "admin"}>
                <Outlet />
            </RouteGuard>
        ),
        children: [

            // dashboard
            {
                path: '/',
                element: (
                    <DashboardLayout navConfig={navConfig}>
                        <Outlet />
                    </DashboardLayout>
                ),
                children: [
                    {
                        path: "",
                        element: (<HophopPages.DashboardPages.Admin.Panel />)
                    },
                    {
                        path: "panel",
                        element: (<HophopPages.DashboardPages.Admin.Panel />)
                    },


                    {
                        path: "moderatorler",
                        element: (<HophopPages.DashboardPages.Admin.Moderators />)
                    },
                    {
                        path: "musteriler",
                        element: (<HophopPages.DashboardPages.Admin.Customers.Main />)
                    },
                    {
                        path: "odemeler",
                        element: (<HophopPages.DashboardPages.Admin.Payments />)
                    },
                    {
                        path: "bildirimler",
                        element: (<HophopPages.DashboardPages.Admin.Notifications />)
                    },




                    {
                        path: "user-profile",
                        element: (<HophopPages.DashboardPages.Admin.UserProfile />)
                    },
                    {
                        path: "settings",
                        element: (<HophopPages.DashboardPages.Admin.Settings />)
                    },
                    { path: '*', element: <NotFoundPage /> },

                ]
            },

            { path: '*', element: <NotFoundPage /> },
        ]
    })

}