/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Avatar, ListItemText, Rating, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'


const CommentCard = ({ comment }) => {

    return (
        <Stack
            spacing={2}
            sx={{
                boxShadow: "1px 1px 15px 0px #ccc",
                p: 3,
                borderRadius: 2,
            }}
        >

            <Stack direction="row" justifyContent="space-between" alignItems="center">

                <Stack sx={{ display: 'flex', alignItems: 'center' }} direction="row">
                    <Avatar
                        alt={comment?.createdBy?.displayName?.slice(0, 1)}
                        src={comment?.createdBy?.displayImageUrl}
                        sx={{ mr: 2 }}
                    >
                        {comment?.createdBy?.displayName?.slice(0, 1) ?? ""}
                    </Avatar>

                    <ListItemText
                        primary={comment?.createdBy?.displayName ?? "Name"}
                        secondary={comment?.createdAt ? moment(comment?.createdAt)?.format("DD-MM-YYYY") : "12-05-2023"}
                        primaryTypographyProps={{ typography: 'body2' }}
                        secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                    />
                </Stack>

                {/* <Typography>
                    {comment?.createdAt ? moment(comment?.createdAt)?.format("DD-MM-YYY") : "12-05-2023"}
                </Typography> */}

            </Stack>

            <Rating name="half-rating" defaultValue={2.5} value={comment?.rating ?? 5} precision={0.5} />

            <Typography>
                {comment?.comment}
            </Typography>

        </Stack>
    )
}

export default CommentCard