/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// routes
// import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
// import { fDate } from 'src/utils/format-time';
// import { fShortenNumber } from 'src/utils/format-number';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import {
  ageIntervalsForListing,
  companionshipsForListing,
  formatsForListing,
} from 'src/hophop/utils/_enums';
import { Avatar, Chip, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { enqueueSnackbar } from 'notistack';
import { graphqlApi } from 'src/hophop/api';

// ----------------------------------------------------------------------

const ListingCard = ({ post, handleDelete }) => {

  const popover = usePopover();

  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  const { accessToken } = useSelector((state) => state.auth);
  const { handleFetchData } = useFetchData();
  // const {
  //   title,
  //   author,
  //   publish,
  //   coverUrl,
  //   createdAt,
  //   totalViews,
  //   totalShares,
  //   totalComments,
  //   description,
  // } = post;
  const format = formatsForListing?.find((item) => item?.value === post?.format)?.name;

  const handleUpdate = async () => {
    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.queries.listingsForModerator.isUpdatable({
          listingId: post?._id,
        }),
        accessToken,
      });



      if (response?.data?.data?.user_isListingUpdatable) {
        popover.onClose();
        router.push(`/listelemeler/edit/${post?._id}`);
      } else {
        popover.onClose();
        enqueueSnackbar('Bu listelemeye güncelleme yapılamaz!', {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error({ error })
    }
  };

  return (
    <>
      <Stack
        component={Card}
        direction="row"
        sx={{
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          // alignItems: "center"
        }}
      >
        <Stack
          sx={{
            p: (theme) => theme.spacing(3, 1, 2, 2),
            flex: 1,

            '&  *': {
              fontSize: { xs: 12, xl: 14 },
            },
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            {format ? (
              <Label
                variant="soft"
                color={
                  (post?.format === 'online' && 'info') ||
                  (post?.format === 'prerecorded' && 'warning') ||
                  'primary'
                }
              >
                {format}
              </Label>
            ) : (
              <Skeleton variant="text" width={50} height={30} />
            )}

            <Box component="span" sx={{ typography: 'caption', color: 'text.disabled' }}>
              {new Intl.DateTimeFormat('tr', {
                dateStyle: 'medium',
              }).format(new Date(post?.createdAt))}
            </Box>
          </Stack>

          <Stack spacing={1} flexGrow={1}>
            {post.title ? (
              <TextMaxLine variant="subtitle2" line={2}>
                {post?.title}
              </TextMaxLine>
            ) : (
              <Skeleton variant="text" width={100} height={20} />
            )}

            {post.description ? (
              <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }}>
                {post?.description}
              </TextMaxLine>
            ) : (
              <Skeleton variant="rounded" width={200} height={60} />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {post?.category ? (
              <TextMaxLine variant="body2" sx={{ textTransform: 'capitalize' }}>
                Kategori : {post?.category?.join(', ')}
              </TextMaxLine>
            ) : (
              <Skeleton variant="text" width={200} height={20} />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {post?.ageInterval?.length ? (
              <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }} alignItems="center">
                <TextMaxLine variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                  Yaş aralığı :
                </TextMaxLine>
                {post?.ageInterval?.map((option, index) => (
                  <Chip
                    // {...getTagProps({ index })}
                    key={option}
                    label={ageIntervalsForListing.find((item) => item?.value === option)?.name}
                    size="small"
                    color="info"
                    variant="soft"
                    sx={{
                      '& span': {
                        fontSize: { xs: 10, xl: 13 },
                        p: { xs: '3px', xl: 1 },
                      },
                    }}
                  />
                ))}
              </Stack>
            ) : (
              <Skeleton variant="text" width={200} height={20} />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {post?.companionship ? (
              <TextMaxLine variant="body2" sx={{ textTransform: 'capitalize' }}>
                Aile katılımı :{' '}
                {
                  companionshipsForListing?.find((item) => item?.value === post?.companionship)
                    ?.name
                }
              </TextMaxLine>
            ) : (
              <Skeleton variant="text" width={200} height={20} />
            )}
          </Stack>

          <Stack direction="row" alignItems="center">
            {/* <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton> */}

            {/* <Stack
              spacing={1.5}
              flexGrow={1}
              direction="row"
              justifyContent="flex-end"
              sx={{
                typography: 'caption',
                color: 'text.disabled',
              }}
            >
              <Stack direction="row" alignItems="center">
                <Iconify icon="eva:message-circle-fill" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalComments)}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Iconify icon="solar:eye-bold" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalViews)}
              </Stack>

              <Stack direction="row" alignItems="center">
                <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(post?.totalShares)}
              </Stack>

            </Stack> */}
          </Stack>
        </Stack>

        {
          // mdUp
          // && (post?.coverImage?.preview ?? post?.coverImage)
          true && (
            <Box
              sx={{
                width: { xs: '100%', sm: 180 },
                height: { xs: 240 },
                position: 'relative',
                flexShrink: 0,
                p: 1,
                // objectFit: "cover"
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  left: 16,
                  zIndex: 9,
                  borderRadius: 1,
                  p: 1,
                  color: '#fff',
                  backgroundColor: '#ffffff99',
                }}
              >
                <Avatar
                  src={
                    post?.exteriorOrganizer?.imageUrl?.preview ?? post?.exteriorOrganizer?.imageUrl
                  }
                  alt={post?.exteriorOrganizer?.name}
                // sx={{ position: 'absolute', top: 16, right: 16, zIndex: 9 }}
                />
                <Stack>
                  <Box>{post?.exteriorOrganizer?.name?.slice(0, 7)}</Box>
                  <Box sx={{ fontSize: 10 }}>{post?.exteriorOrganizer?.details?.slice(0, 20)}</Box>
                </Stack>
              </Stack>

              <IconButton
                color={popover.open ? 'inherit' : 'default'}
                onClick={popover.onOpen}
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  zIndex: 9,
                  backgroundColor: '#ffffff99',
                  '&:hover': {
                    backgroundColor: '#ffffff66',
                  },
                }}
              >
                <Iconify icon="eva:more-horizontal-fill" />
              </IconButton>

              {post?.coverImage?.preview ?? post?.coverImage ? (
                <Image
                  alt={post?.title}
                  src={post?.coverImage?.preview ?? post?.coverImage}
                  sx={{
                    height: 1,
                    borderRadius: 1.5,
                    // objectFit: "cover",
                    width: '100%',
                    // height: "100%",
                  }}
                />
              ) : (
                <Skeleton variant="rounded" width={160} height={220} />
              )}
            </Box>
          )
        }
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ width: 140 }}
      >
        {/* <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths?.dashboard?.post?.details(post?.title));
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem> */}

        <MenuItem onClick={handleUpdate}>
          <Iconify icon="solar:pen-bold" />
          Düzenle
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleDelete({ id: post?._id });
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Sil
        </MenuItem>
      </CustomPopover>
    </>
  );
};

ListingCard.propTypes = {
  post: PropTypes.shape({
    author: PropTypes.object,
    coverUrl: PropTypes.string,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    publish: PropTypes.string,
    title: PropTypes.string,
    totalComments: PropTypes.number,
    totalShares: PropTypes.number,
    totalViews: PropTypes.number,
  }),
};

export default ListingCard;
