/* eslint-disable arrow-body-style */
import { Suspense } from "react";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";

import NotFoundPage from "src/pages/404";
import { HophopPages } from "src/hophop/pages";
import Iconify from "src/components/iconify/iconify";
import DashboardLayout from "src/layouts/dashboard/layout";

import RouteGuard from "../RouteGuard";

const icon = (name = "mingcute:add-line") => <Iconify icon={name} />


const navConfig = ({ payment_user, payment_loading }) => {


    return ([
        // ----------------------------------------------------------------------
        {
            subheader: 'hophop',
            items: [
                {
                    title: 'panel',
                    path: '/panel',
                    icon: icon("mingcute:dashboard-fill"),
                },


                { // organizator
                    title: 'Organizatör',
                    path: "/user-profile",
                    icon: icon("iconamoon:profile-fill"),
                    info: (payment_user?.iban || payment_loading)
                        ? null
                        : <Iconify icon="ph:warning-bold" sx={{ color: 'warning.main' }} width={32} height={32} />,
                    // children: [
                    //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Aktivite Oluştur',
                    path: "/aktiviteler/olustur",
                    icon: icon("lucide:party-popper"),
                    // children: [
                    //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                {
                    title: 'Aktivitelerim',
                    path: "/aktiviteler/liste",
                    icon: icon("gis:signpost"),
                    // children: [
                    //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },

                // {
                //     title: 'Aktiviteler',
                //     path: "/aktiviteler",
                //     icon: icon("lucide:party-popper"),
                //     // icon: ICONS.booking,
                //     children: [
                //         { title: 'Aktivite Listeleri', path: "/aktiviteler/liste" },
                //         { title: 'Aktivite Oluştur', path: "/aktiviteler/olustur" },
                //     ]
                // },
                // {
                //     title: 'Listelemeler',
                //     path: "/listelemeler",
                //     icon: icon("ep:list"),
                //     // icon: ICONS.ecommerce,
                //     children: [
                //         { title: 'Listelemeler Listesi', path: "/listelemeler/liste" },
                //         { title: 'Listeleme Oluştur', path: "/listelemeler/olustur" },
                //     ]
                // },
                // {
                //     title: 'Konumlar',
                //     path: "/konumlar",
                //     // icon: ICONS.lock,
                //     icon: icon("typcn:location"),
                //     children: [
                //         { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //         { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                //     ]
                // },


                { // rezervasyonlar
                    title: 'Rezervasyonlar',
                    path: "/rezervasyonlar/liste",
                    icon: icon("line-md:clipboard-list-twotone"),
                    // icon: icon("line-md:calendar"),
                    // icon: icon("fluent-mdl2:reservation-orders"),
                    // children: [
                    //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                { // müşteriler
                    title: 'Müşteriler',
                    path: "/musteriler/liste",
                    icon: icon("fontisto:persons"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                { // yorumlar
                    title: 'Yorumlar',
                    path: "/yorumlar/liste",
                    icon: icon("mdi:comments-text"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                { // mesajlar
                    title: 'Mesajlar',
                    path: "/mesajlar",
                    icon: icon("jam:messages-f"),
                    // children: [
                    //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },
                { // ödemeler
                    title: 'Ödemeler',
                    path: "/odemeler/liste",
                    icon: icon("mdi:account-payment"),
                    // children: [
                    //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                    //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                    // ]
                },

            ],
        },

    ])
}

const LoadingComp = () => null // <div>loading</div>

export const organizerRoutes = ({ userRole, isOnboarding, payment_user, payment_loading }) => {

    return ({
        path: '',

        element: (
            <RouteGuard redirectPath="/" isAuth={userRole === "organizer"}>
                <Suspense fallback={<div>loading</div>}>
                    <Outlet />
                </Suspense>
            </RouteGuard>
        ),

        children: [

            // loading
            (isOnboarding === null && ({
                path: "*",
                element: (
                    <RouteGuard redirectPath="/onboarding" isAuth={isOnboarding === null} >
                        <LoadingComp />
                    </RouteGuard>
                )
            })),

            // onboarding
            (isOnboarding === false && ({
                path: '',
                element: (
                    <RouteGuard redirectPath="/panel" isAuth={!isOnboarding} >
                        <Outlet />
                    </RouteGuard>
                ),
                children: [
                    {
                        path: '/onboarding',
                        element: (<HophopPages.OnboardingPages.OrganizerOnboardingMainPage />)
                    },
                    {
                        path: '/',
                        element: (<HophopPages.OnboardingPages.OrganizerOnboardingMainPage />)
                    },
                ]
            })),

            // dashboard
            (!!isOnboarding && ({
                path: '/',
                element: (
                    <RouteGuard redirectPath="/onboarding" isAuth={isOnboarding}>
                        <DashboardLayout navConfig={navConfig({ payment_user, payment_loading })}>
                            <Outlet />
                        </DashboardLayout>
                    </RouteGuard>
                ),
                children: [
                    {
                        path: "",
                        element: (<HophopPages.DashboardPages.Organizer.Panel />)
                    },
                    {
                        path: "panel",
                        element: (<HophopPages.DashboardPages.Organizer.Panel />)
                    },


                    {
                        path: "aktiviteler",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.CreateActivity />)
                    },
                    {
                        path: "aktiviteler/edit/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.EditActivity />)
                    },


                    {
                        path: "listelemeler",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.Main />)
                    },
                    {
                        path: "listelemeler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.Main />)
                    },
                    {
                        path: "listelemeler/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.CreateListing />)
                    },
                    {
                        path: "listelemeler/edit/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.CreateListing />)
                    },


                    {
                        path: "konumlar",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.Main />)
                    },
                    {
                        path: "konumlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.Main />)
                    },
                    {
                        path: "konumlar/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.CreateLocations />)
                    },
                    // {
                    //     path: "konum-onayla",
                    //     element: (<HophopPages.DashboardPages.Organizer.Locations.AproveLocations />)
                    // },



                    {
                        path: "rezervasyonlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Reservations.Main />)
                    },
                    {
                        path: "rezervasyonlar/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Reservations.ReservationDetailList />)
                    },


                    {
                        path: "musteriler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Customers.Main />)
                    },

                    {
                        path: "yorumlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Comments.Main />)
                    },
                    {
                        path: "yorumlar/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Comments.ListingComments />)
                    },

                    {
                        path: "mesajlar",
                        element: (<HophopPages.DashboardPages.Organizer.Messages.Main />)
                    },
                    {
                        path: "mesajlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Messages.Main />)
                    },
                    {
                        path: "mesajlar/:activityId/:receiverId",
                        element: (<HophopPages.DashboardPages.Organizer.Messages.Main />)
                    },

                    {
                        path: "odemeler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Payments.Main />)
                    },


                    {
                        path: "user-profile",
                        element: (<HophopPages.DashboardPages.Organizer.UserProfile />)
                    },
                    {
                        path: "settings",
                        element: (<HophopPages.DashboardPages.Organizer.Settings />)
                    },
                    { path: '*', element: <NotFoundPage /> },

                ]
            })),


            // { path: '*', element: <NotFoundPage /> },
            // { path: '*', element: <Navigate to="/¨" replace /> },
        ]
    })

}