import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// utils
import { fToNow } from 'src/utils/format-time';
// components
import Iconify from 'src/components/iconify';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function ChatHeaderDetail() {

  const { selectedConversation } = useSelector(state => state.message)

  const renderGroup = (
    <AvatarGroup
      max={3}
      sx={{
        [`& .${avatarGroupClasses?.avatar}`]: {
          width: 32,
          height: 32,
        },
      }}
    >
      {[]?.map((participant) => (
        <Avatar key={participant.id} alt={participant.name} src={participant.avatarUrl} />
      ))}
    </AvatarGroup>
  );

  const renderSingle = (
    <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
      <Badge
        variant={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar src={selectedConversation?.receiver?.displayImageUrl?.startsWith("http") ? selectedConversation?.receiver?.displayImageUrl : ""} alt={selectedConversation?.receiver?.displayName} />
      </Badge>

      <ListItemText
        primary={selectedConversation?.receiver?.displayName}
        secondary={
          selectedConversation?.activity?.title
          // selectedConversation?.status === 'offline'
          //   ? fToNow(selectedConversation?.lastActivity)
          //   : selectedConversation?.status
        }
        secondaryTypographyProps={{
          component: 'span',
          ...(selectedConversation?.status !== 'offline' && {
            textTransform: 'capitalize',
          }),
        }}
      />
    </Stack>
  );

  return (
    <>
      {false ? renderGroup : renderSingle}

      <Stack flexGrow={1} />

      {/* <IconButton>
        <Iconify icon="solar:phone-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="solar:videocamera-record-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton> */}
    </>
  );
}

ChatHeaderDetail.propTypes = {
  // participants: PropTypes.array,
};
