const parameters = () => {

  const data = JSON.stringify({
    query: `
    query Anonymous_parameters {
      anonymous_cities {
        count
        data {
          name
          districts
        }
      }
      anonymous_categories {
        count
        data {
          _id
          defaultSorting
          description
          imageUrl
        }
      }
    }
        `,
    variables: {}
  })

  return data
}


export default parameters