import { user } from './user'
import { dms } from './dms'
import { activity } from './activity'
import { payment } from './payment'
import { notifications } from './notifications'

export const graphqlApi = {
    user,
    dms,
    activity,
    payment,
    notifications
}