import Panel from "./1-panel/Panel";
import { Activities } from "./2-activities";
import { Listings } from "./3-listings";
import { Locations } from "./4-locations";
import { Reservations } from "./5-reservations";
import { Customers } from "./6-customers";
import { Comments } from "./7-comments";
import { Messages } from "./8-messages";
import { Payments } from "./9-payments";
import { Blog } from "./10-blog";
import { BlogPlus } from "./11-blog-plus";
import { Categories } from "./12-categories/Categories";
import { UygulamaDuzeni } from "./13-uygulama-duzeni/UygulamaDuzeni";


import UserProfile from "./userProfile/UserProfile";
import Settings from "./settings/Settings";




export const Moderator = {
    Panel,
    Activities,
    Listings,
    Locations,

    Reservations,
    Customers,
    Comments,
    Messages,
    Payments,
    Blog,
    BlogPlus,
    Categories,
    UygulamaDuzeni,

    UserProfile,
    Settings,
}


