/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'


const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    blogsForModerator: [],
    // blogsForOrganizer: [],
    selectedBlogForModerator: null,
    // selectedListingForOrganizer: null,
    loading: false,
    error: false,
}

// moderator blogs
export const fetchBlogsForModeratorFunc = createAsyncThunk('blog/fetchBlogsForModeratorFunc', async ({ accessToken, variables }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.blogsForModerator.allBlogs({ variables })
    }))
    return response?.data
})

// moderator selected blog 
export const fetchSelectedBlogForModeratorFunc = createAsyncThunk('blog/fetchSelectedBlogForModeratorFunc', async ({ accessToken, blogId }) => {
    const response = await axios(configFunc({
        accessToken,
        data: graphqlApi.activity.queries.blogsForModerator.blogDetail({ blogId })
    }))
    return response?.data
})


// organizer blogs
export const fetchBlogsForOrganizatorFunc = createAsyncThunk('listing/fetchBlogsForOrganizatorFunc', async ({ accessToken, variables }) => {
    // const response = await axios(configFunc({
    //     accessToken,
    //     data: graphqlApi.activity.queries.blogsorOrganizer.allBlogs({ variables })
    // }))
    return null // response?.data
})

// organizer selected blog
export const fetchSelectedListingForOrganizerFunc = createAsyncThunk('blog/fetchSelectedListingForOrganizerFunc', async ({ accessToken, listingId }) => {
    // const response = await axios(configFunc({
    //     accessToken,
    //     data: graphqlApi.activity.queries.blogsorOrganizer.allBlogs({ variables })
    // }))
    return null // response?.data
})



const { reducer: blogReducer, actions } = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        resetSelectedBlog: (state, action) => {
            state.selectedBlogForModerator = null
            // state.selectedListingForOrganizer = null
        }
    },
    extraReducers: (builder) => {
        // ################# moderator all list ######
        builder.addCase(fetchBlogsForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchBlogsForModeratorFunc.fulfilled, (state, action) => {

            state.blogsForModerator = action.payload?.data?.anonymous_activityBlogs
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchBlogsForModeratorFunc.rejected, (state, action) => {

            state.loading = false
            state.error = action.error
        })

        // ################# moderator item detail ######
        builder.addCase(fetchSelectedBlogForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchSelectedBlogForModeratorFunc.fulfilled, (state, action) => {

            state.selectedBlogForModerator = action.payload?.data?.anonymous_activityBlog
            state.loading = false
            state.error = false
        })
        builder.addCase(fetchSelectedBlogForModeratorFunc.rejected, (state, action) => {
            console.log({ action })
            state.loading = false
            state.error = action.error
        })


        // // ################# organizator all list ######
        // builder.addCase(fetchBlogsForOrganizatorFunc.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(fetchBlogsForOrganizatorFunc.fulfilled, (state, action) => {
        //     state.blogsForOrganizer = action.payload?.data?.organizer_listings
        //     state.loading = false
        //     state.error = false
        // })
        // builder.addCase(fetchBlogsForOrganizatorFunc.rejected, (state, action) => {

        //     state.loading = false
        //     state.error = action.error
        // })

        // // ################# organizator item detail ######
        // builder.addCase(fetchSelectedListingForOrganizerFunc.pending, (state, action) => {
        //     state.loading = true
        // })
        // builder.addCase(fetchSelectedListingForOrganizerFunc.fulfilled, (state, action) => {

        //     state.selectedListingForOrganizer = action.payload?.data?.organizer_listing
        //     state.loading = false
        //     state.error = false
        // })
        // builder.addCase(fetchSelectedListingForOrganizerFunc.rejected, (state, action) => {

        //     state.loading = false
        //     state.error = action.error
        // })

    }
})


export default blogReducer;

export const blogFunc = {
    fetchBlogsForModeratorFunc,
    fetchBlogsForOrganizatorFunc,
    fetchSelectedBlogForModeratorFunc,
    fetchSelectedListingForOrganizerFunc,

    resetSelectedBlog: actions.resetSelectedBlog
}