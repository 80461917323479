/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePopover } from 'src/components/custom-popover';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import { ConfirmDialog } from 'src/components/custom-dialog';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { graphqlApi } from 'src/hophop/api';
import { Avatar, InputAdornment, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';




// eslint-disable-next-line react/prop-types
export default function ModeratorsTableRow({ row, selected }) {

  // const detailPopup = useBoolean();

  // const quickEdit = useBoolean();
  // const { accessToken } = useSelector(state => state.auth)
  // const { handleFetchData } = useFetchData()

  // const handleUpdate = async () => {

  //   try {

  //     const response = await handleFetchData({
  //       data: graphqlApi.activity.queries.locationsForModerator.isUpdatable({ locationId: row?._id }),
  //       accessToken
  //     })
  //     if (response?.data?.data?.user_isLocationUpdatable) {
  //       quickEdit.onTrue()
  //     } else {
  //       enqueueSnackbar("Bu konuma güncelleme yapılamaz!", {
  //         variant: "error"
  //       })
  //     }

  //   } catch (error) {

  //   }

  // }

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt={row?.personalInformation?.fullName ?? "name"}
            src={row?.personalInformation?.profileImageUrl}
            sx={{ mr: 2 }}
          >
            {row?.personalInformation?.fullName?.slice(0, 1)}
          </Avatar>

          <ListItemText
            secondary={row?.personalInformation?.fullName ?? "İsim bilgisi eklenmedi."}
            primary={row?.contact?.email}
            primaryTypographyProps={{ typography: 'body2', textTransform: 'capitalize' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled', textTransform: 'capitalize' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
          {/* {new Intl.RelativeTimeFormat('tr', { numeric: 'auto', style: 'short' }).format(new Date(row?.createdAt).getTime() - new Date(), "year")} */}
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>{row?.contact?.address?.content?.description ?? "-"}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.contact?.phoneNumber ?? "-"}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              ("aktif" === 'aktif' && 'success') ||
              (row?.parking === 'limited' && 'warning') ||
              (row?.parking === 'nonExists' && 'error') ||
              'default'
            }
          >
            {parkingForLocation?.find(item => item?.value === row?.parking)?.name ?? "Aktif"}
          </Label>
        </TableCell>

        {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Düzenle" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={handleUpdate}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={confirm.onTrue} sx={{ color: 'error.main' }}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>

        </TableCell> */}
      </TableRow>

      {/* {quickEdit.value && <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} id={row?._id} />} */}

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Sil
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Düzenle
        </MenuItem>
      </CustomPopover> */}


    </>
  );
}

ModeratorsTableRow.propTypes = {
  // onDeleteRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
