/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
    Box,
    Grid,
    Stepper,
    Step,
    StepLabel,
    MenuItem,
    Typography,
    Stack,
    IconButton,
    Card,
    Container,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonBase,
    Skeleton,
} from '@mui/material';

import { BusinessCenterOutlined, Person2Outlined, RemoveRedEye } from '@mui/icons-material';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { compressImage } from 'src/hophop/utils';
import { useBoolean } from 'src/hooks/use-boolean';
import { RHFSelect } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'src/components/snackbar';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { ageIntervalsForListing, timeMapping } from 'src/hophop/utils/_enums';

import CreateActivityCard from './_CreateActivityCard';
import FirstPage from './_FirstPage';
import FormOne from './_FormOne';
import FormTwo from './_FormTwo';
import LastPage from './_LastPage';
import FormThree from './_FormThree';
import FormFour from './_FormFour';


const steps = ['Listeleme', 'Konum', 'Temel Bilgiler', 'Tarih Bilgileri'];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    transitionDuration: '1s',

    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        transitionDuration: '1s',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        transitionDuration: '1s',
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    transitionDuration: '1s',
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <PlayCircleOutlineOutlinedIcon />,
        2: <Person2Outlined />,
        3: <BusinessCenterOutlined />,
        4: <OutlinedFlagRoundedIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props?.icon)]}
        </ColorlibStepIconRoot>
    );
}

function getDates(startDate, stopDate, interval = 1) {
    const dateArray = [];
    const currentDate = new Date(startDate);

    while (currentDate <= stopDate) {
        if (interval === 'monthly') {
            dateArray.push(new Date(currentDate));

            currentDate.setMonth(currentDate.getMonth() + 1);
        } else if (typeof interval === 'object') {
            if (interval.includes(currentDate?.getDay())) {
                dateArray.push(new Date(currentDate.getTime()));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        } else {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + interval);
        }
    }

    return dateArray;
}

const EditActivity = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const settings = useSettingsContext();
    const addOrUpdateDatePopup = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
    const { handleFetchData } = useFetchData();
    const [isReview, setIsReview] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [createActivityLoading, setCreateActivityLoading] = useState(false);
    const { accessToken } = useSelector((state) => state?.auth);
    const { selectedActivityForOrganizer } = useSelector((state) => state?.activity);

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.parameterFunc.fetchCreateActivityParametersForOrganizatorFunc({ accessToken }));
            dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
        }
    }, [accessToken, dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(
                reduxFunc.activityFunc.fetchSelectedActivityForOrganizerFunc({
                    accessToken,
                    activityId: id,
                })
            );
        } else {
            dispatch(reduxFunc.activityFunc.resetSelectedActivity());
            dispatch(reduxFunc.listingFunc.resetSelectedListing());
        }
    }, [accessToken, dispatch, id]);


    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (skipped.has(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, skipped]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const NewActivitySchema = Yup.object().shape({

        additionalDetails: Yup.string('').required("Gerekli!"),
        duration: Yup.string('').required('Gerekli!'), // int
        fee: Yup.string('').required('Gerekli!'), // free | paid
        hoursBeforeReservationExpires: Yup.string('').required('Gerekli!'), // int

        // listingId: Yup.string('').required('Gerekli!'),
        // locationId: Yup.string('').required('Gerekli!'),

        price: Yup.string(''), // int
        quota: Yup.string(''), // int

        status: Yup.string('').required('Gerekli!'), // int
        title: Yup.string('').required('Gerekli!'), // int

        listingId: Yup.string(''), // .required('Gerekli!'), // int
        locationId: Yup.string(''), // .required('Gerekli!'), // int

        schedule: Yup.object({
            repeatedIntervalType: Yup.string('').required('Gerekli!'), // once | daily | weekly | monthly
            totalDayCount: Yup.string('').required('Gerekli!'), // int // bu kullanıcıya gösterme frontta arka planda hesapla
            days: Yup.array('').min(1, "En az 1 gün seçilmeli"),

            isRepetetive: Yup.boolean(), // **** bu backende gitmeyecek, sadece front için ****
            startDate: Yup.date(""), // data input
            endDate: Yup.date(), // data input
            day: Yup.array(''),
            startTime: !selectedActivityForOrganizer ? Yup.string('').required('Gerekli!') : Yup.string(""),
            endTime: !selectedActivityForOrganizer ? Yup.string('').required('Gerekli!') : Yup.string(""),
        }),



        listing: Yup.object().shape({
            ageInterval: Yup.array("").required("Gerekli").min(1, "En az bir yaş aralığı seçilmeli!"),
            category: Yup.string("").required("Gerekli!"),
            companionship: Yup.string("").required("Gerekli!"),

            coverImage: Yup.string("").required("Gerekli!").min(1, "En az bir resim seçilmeli!"),
            images: Yup.array("").min(1, "En az bir resim seçilmeli!"),

            description: Yup.string("").required("Gerekli!"),
            format: Yup.string("").required("Gerekli!"),
            subCategory: Yup.string("").required("Gerekli!"),
            title: Yup.string("").required("Gerekli!"),
        }),


        location: Yup.object().shape({
            address: Yup.object().shape({
                description: Yup.string("").required("Gerekli!"),
                title: Yup.string("").required("Gerekli!"),
            }),
            city: Yup.string("").required("Gerekli!"),
            district: Yup.string("").required("Gerekli!"),
            lat: Yup.number(""),
            long: Yup.number(""),
            parking: Yup.string("").required("Gerekli!"),
        })


    });

    console.log(selectedActivityForOrganizer?.listing?.format, "selectedActivityForOrganizer")

    const defaultValues = useMemo(() => ({
        additionalDetails: selectedActivityForOrganizer?.additionalDetails ?? '',
        duration: selectedActivityForOrganizer?.duration ?? '', // int
        fee: selectedActivityForOrganizer?.fee ?? '', // free | paid
        hoursBeforeReservationExpires: selectedActivityForOrganizer?.hoursBeforeReservationExpires ?? '', // int
        // listingId: selectedActivityForOrganizer?.listing?._id ?? '',
        // locationId: selectedActivityForOrganizer?.location?._id ?? '',
        price: selectedActivityForOrganizer?.price ?? '', // int
        quota: selectedActivityForOrganizer?.quota ?? 0, // int
        status: selectedActivityForOrganizer?.status ?? 'active', // int
        title: selectedActivityForOrganizer?.title ?? '', // int
        listingId: "",
        locationId: "",


        schedule: {
            isRepetetive: selectedActivityForOrganizer?.schedule?.totalDayCount > 1, // **** bu backende gitmeyecek, sadece front için ****
            repeatedIntervalType: selectedActivityForOrganizer?.schedule?.repeatedIntervalType ?? 'once', // once | daily | weekly | monthly
            totalDayCount: selectedActivityForOrganizer?.schedule?.totalDayCount ?? 1, // int // bu kullanıcıya gösterme frontta arka planda hesapla
            days: selectedActivityForOrganizer?.schedule?.days ?? [],

            startDate: selectedActivityForOrganizer ? new Date(selectedActivityForOrganizer?.schedule?.startDate) : null, // data input
            endDate: selectedActivityForOrganizer ? new Date(selectedActivityForOrganizer?.schedule?.endDate) : null, // data input
            day: [],
            startTime: selectedActivityForOrganizer?.schedule?.[0]?.startTime ?? '',
            endTime: selectedActivityForOrganizer?.schedule?.[0]?.endTime ?? '',
        },



        listing: {
            ageInterval: selectedActivityForOrganizer?.listing?.ageInterval
                ? selectedActivityForOrganizer?.listing?.ageInterval?.map((item) =>
                    ageIntervalsForListing.find((age) => age?.value === item)
                )
                : [],
            category: selectedActivityForOrganizer?.listing?.category ?? "",
            companionship: selectedActivityForOrganizer?.listing?.companionship ?? "",
            coverImage: selectedActivityForOrganizer?.listing?.coverImage ?? "",
            description: selectedActivityForOrganizer?.listing?.description ?? "",
            format: selectedActivityForOrganizer?.listing?.format ?? "",
            images: selectedActivityForOrganizer?.listing?.images ?? [],
            subCategory: selectedActivityForOrganizer?.listing?.subCategory ?? "activity",
            title: selectedActivityForOrganizer?.listing?.title ?? ""
        },


        location: {
            address: {
                description: selectedActivityForOrganizer?.location?.address?.description ?? "",
                title: selectedActivityForOrganizer?.location?.address?.title ?? ""
            },
            city: selectedActivityForOrganizer?.location?.city ?? "",
            district: selectedActivityForOrganizer?.location?.district ?? "",
            lat: selectedActivityForOrganizer?.location?.lat ?? null,
            long: selectedActivityForOrganizer?.location?.long ?? null,
            parking: selectedActivityForOrganizer?.location?.parking ?? ""
        }


    }), [selectedActivityForOrganizer]);

    const methods = useForm({
        resolver: yupResolver(NewActivitySchema),
        defaultValues,
        mode: 'all',
    });

    const { watch, handleSubmit, formState, setValue, control, reset, trigger } = methods;
    const { dirtyFields, isValid } = formState;
    const values = watch();


    useEffect(() => {
        reset(defaultValues, { keepDirtyValues: true, keepDirty: true });
    }, [defaultValues, reset, id, trigger]);

    useEffect(() => {
        if (!values?.schedule?.isRepetetive) {
            setValue('schedule.repeatedIntervalType', 'once');
            setValue('schedule.totalDayCount', 1);
            setValue('schedule.onceDay', 1);
        } else {
            setValue(
                'schedule.repeatedIntervalType',
                values?.schedule?.repeatedIntervalType === 'once'
                    ? ''
                    : values?.schedule?.repeatedIntervalType
            );
            setValue('schedule.totalDayCount', values?.schedule?.days?.length);
            setValue('schedule.onceDay', '');
        }
    }, [
        setValue,
        values?.schedule?.days?.length,
        values?.schedule?.isRepetetive,
        values?.schedule?.repeatedIntervalType,
    ]);

    useEffect(() => {
        if (!id) {
            if (values?.schedule?.repeatedIntervalType === 'once') {
                if (values?.schedule?.startDate) {
                    setValue('schedule.days', [
                        {
                            date: values?.schedule?.startDate, // date
                            day: new Date(values?.schedule?.startDate).getDay(), // int
                            startTime: values?.schedule?.startTime, // int
                            endTime: values?.schedule?.endTime, // int
                        },
                    ]);
                    // setValue("schedule.endDate", values?.schedule.startDate)
                }
            } else if (values?.schedule?.repeatedIntervalType === 'daily') {
                if (values?.schedule?.startDate) {
                    const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, 1);
                    setValue(
                        'schedule.days',
                        dates?.map((item) => ({
                            date: item, // date
                            day: new Date(item).getDay(), // int
                            startTime: values?.schedule?.startTime, // int
                            endTime: values?.schedule?.endTime, // int
                        }))
                    );
                }
            } else if (values?.schedule?.repeatedIntervalType === 'weekly') {
                const dates = getDates(
                    values?.schedule?.startDate,
                    values?.schedule?.endDate,
                    values?.schedule?.day?.map((item) => item?.value)
                );
                setValue(
                    'schedule.days',
                    dates?.map((item) => ({
                        date: item, // date
                        day: new Date(item).getDay(), // int
                        startTime: values?.schedule?.startTime, // int
                        endTime: values?.schedule?.endTime, // int
                    }))
                );
            } else if (values?.schedule?.repeatedIntervalType === 'monthly') {
                const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, 'monthly');
                setValue(
                    'schedule.days',
                    dates?.map((item) => ({
                        date: item, // date
                        day: new Date(item).getDay(), // int
                        startTime: values?.schedule?.startTime, // int
                        endTime: values?.schedule?.endTime, // int
                    }))
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setValue,
        values?.schedule?.repeatedIntervalType,
        values?.schedule?.day,
        values?.schedule?.startDate,
        values?.schedule?.endDate,
        values?.schedule?.startTime,
        values?.schedule?.endTime,
    ]);

    useEffect(() => {
        if (values.fee === 'free') {
            setValue('price', '0');
        } else {
            setValue('price', selectedActivityForOrganizer?.price ?? 0);
        }
    }, [selectedActivityForOrganizer?.price, setValue, values.fee]);

    useEffect(() => {
        if (!id) {
            if (values?.schedule?.repeatedIntervalType === 'once') {
                setValue('schedule.endDate', values?.schedule.startDate);
            }

        }
    }, [
        id,
        setValue,
        values.fee,
        values?.schedule?.repeatedIntervalType,
        values?.schedule.startDate,
    ]);

    useEffect(() => {
        setValue("schedule.days", [])
    }, [setValue])


    const handleEdit = async () => {

        setCreateActivityLoading(true)
        try {
            // ############# cover Image  ###############
            let coverImageUploadUrl;
            let coverImageUrl = null
            if (typeof values?.listing?.coverImage !== "string") {
                coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
                    listingId: values?.listing.title,
                    file: values?.listing?.coverImage,
                    accessToken
                })

                const compressedImage = await compressImage(values?.listing?.coverImage, 0.6)

                await graphqlApi.dms.handleSaveImage({
                    file: compressedImage,
                    url: coverImageUploadUrl?.presignedUploadUrl
                })

                coverImageUrl = coverImageUploadUrl.url

            }




            // ############# images  ###############
            const prevImagesUrl = values?.listing?.images?.filter(item => typeof item === "string")
            const newImages = values?.listing?.images?.filter(item => typeof item !== "string")


            const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
                listingId: values?.listing?.title,
                file: newImages,
                accessToken,
                imageCount: newImages?.length
            })

            await imageUploadUrls.forEach(async (item, i) => {
                const compressedImage = await compressImage(newImages[i], 0.6)

                await graphqlApi.dms.handleSaveImage({
                    file: compressedImage,
                    url: item?.presignedUploadUrl
                })
            })

            const newImagesUrls = imageUploadUrls.map(item => item.url)


            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForOrganizer.updateActivityWithListingAndLocation({
                    values: {
                        ...values,
                        listing: {
                            ...values?.listing,
                            "ageInterval": values?.listing?.ageInterval?.map(item => item?.value)
                        }
                    },
                    coverImage: coverImageUrl ?? values?.listing?.coverImage,
                    images: [...prevImagesUrl, ...newImagesUrls],
                    activityId: id
                }),
                url: "/activity/graphql",
                accessToken,
            });


            if (response?.data?.data?.organizer_updateActivityAlongSideListingLocation?._id) {
                enqueueSnackbar('Güncelleme başarılı!');
                // setOrganizerOnboardingPageState("last")
                handleNext()
            } else {
                enqueueSnackbar('Güncelleme yapılamadı!', {
                    variant: "info"
                });

            }

        } catch (error) {
            console.error(error);
        }
        sessionStorage.clear()
        setCreateActivityLoading(false)

    };

    const onSubmit = async () => {
        handleEdit()
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const handleRemoveFromDate = (date) => {
        const filteredDates = values?.schedule?.days?.filter((item) => item?.date !== date);
        setValue('schedule.days', filteredDates);
    };

    const handleEditDate = (date) => {
        addOrUpdateDatePopup.onTrue();
        setSelectedDate(date);
    };

    const handleAddDate = () => {
        setSelectedDate(null);
        addOrUpdateDatePopup.onTrue();
    };

    const handleAddOrUpdateDate = (data) => {
        if (selectedDate) {
            const newDates = values?.schedule?.days?.map((item) => {
                return item?.date === selectedDate?.date ? data : item;
            });
            setValue('schedule.days', newDates);
        } else {
            const newDates = [...(values?.schedule?.days ?? []), data];
            setValue('schedule.days', newDates);
        }
        addOrUpdateDatePopup.onFalse();
    };


    const renderOrganizerOnboardingPages = useCallback(() => {

        return (
            <>

                <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                    <FirstPage values={values} setValue={setValue} />
                </Box>

                <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
                    <FormTwo values={values} setValue={setValue} control={control} />
                </Box>

                <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
                    <FormThree values={values} setValue={setValue} />
                </Box>

                <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
                    <FormOne values={values} setValue={setValue} />
                </Box>

                <Box sx={{ display: activeStep === 4 ? "block" : "none" }}>
                    {selectedActivityForOrganizer ? null : <FormFour values={values} setValue={setValue} />}
                </Box>

                <Box sx={{ display: activeStep === 5 ? "block" : "none" }}>
                    <LastPage values={values} setValue={setValue} />
                </Box>

            </>
        )

    }, [activeStep, control, selectedActivityForOrganizer, setValue, values]);



    console.log(values, "values")
    return (
        <>

            <Helmet>
                <title>Aktivite Güncelle</title>
            </Helmet>

            <Container maxWidth={settings.themeStretch ? false : 'lg'}>

                <CustomBreadcrumbs
                    heading={id ? 'Aktivite Güncelle' : 'Aktivite Oluştur'}
                    sx={{
                        my: { xs: 3, md: 5 },
                        position: 'relative'
                    }}

                    action={
                        <IconButton
                            variant="contained"
                            onClick={() => setIsReview(true)}
                            sx={{
                                display: { xs: 'flex', sm: 'none' },
                                position: 'fixed',
                                top: 90,
                                right: 20,
                                zIndex: 10,
                                bgcolor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    color: 'secondary.main',
                                    bgcolor: 'primary.light',

                                }
                            }}
                        >
                            {/* Ön İzleme */}
                            <RemoveRedEye />
                        </IconButton>
                    }
                />

                <Grid container spacing={3} sx={{ mt: 5 }}>

                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                p: { xs: 0, sm: 3, md: 5 },
                                pt: { xs: 0, sm: 5, md: 10 },
                                maxWidth: '900px',
                                boxShadow: { xs: 'none', sm: '1px 1px 25px 0px #ddd' },
                                borderRadius: { xs: 0, sm: 3 },
                            }}
                        >
                            <Stepper
                                activeStep={activeStep - 1}
                                alternativeLabel
                                connector={<ColorlibConnector />}
                                sx={{
                                    // display: { xs: 'none', sm: 'flex' },
                                    width: '100%',
                                    transition: 'all',
                                    transitionDuration: '1s',
                                    mb: 5,
                                }}
                            >
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label} sx={{ transitionDuration: '1s' }}>
                                            <StepLabel
                                                StepIconComponent={ColorlibStepIcon}
                                                sx={{ transitionDuration: '1s' }}
                                            >
                                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{label}</Box>
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Stack
                                    spacing={3}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <Box>{renderOrganizerOnboardingPages()}</Box>

                                    <Box
                                        sx={{
                                            display: activeStep === 5 ? 'none' : 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <LoadingButton
                                            loading={createActivityLoading}
                                            variant="contained"
                                            disabled={activeStep === 0}
                                            onClick={() => {
                                                handleBack();
                                            }}
                                        >
                                            Geri
                                        </LoadingButton>

                                        <LoadingButton
                                            loading={createActivityLoading}
                                            disabled={
                                                // eslint-disable-next-line no-unneeded-ternary
                                                isValid ? !isValid : (

                                                    (
                                                        activeStep === 1 && (
                                                            !values?.listingId &&
                                                            (
                                                                // false ||

                                                                !values.listing?.title ||
                                                                !values.listing.category ||
                                                                !values.listing.description ||
                                                                !values.listing.coverImage ||
                                                                !values.listing.ageInterval ||
                                                                !values.listing.companionship ||
                                                                !values.listing.format ||
                                                                !values.listing.images
                                                            )
                                                        )
                                                    )

                                                    ||

                                                    (
                                                        activeStep === 2 && (
                                                            !values.locationId &&
                                                            values?.listing?.format === "faceToFace" &&

                                                            (
                                                                !values.location?.address?.title ||
                                                                !values.location?.address.description ||
                                                                !values.location.city ||
                                                                !values.location.district ||
                                                                !values.location.lat ||
                                                                !values.location.long ||
                                                                !values.location.parking
                                                            )
                                                        )
                                                    )

                                                    // ||

                                                    // (activeStep === 3 && (
                                                    //     false ||
                                                    //     !dirtyFields.title ||
                                                    //     !dirtyFields.duration ||
                                                    //     !dirtyFields.quota ||
                                                    //     !dirtyFields.hoursBeforeReservationExpires ||
                                                    //     !dirtyFields.fee ||
                                                    //     !dirtyFields.price ||
                                                    //     !dirtyFields.additionalDetails
                                                    // ))

                                                    ||

                                                    (activeStep === 4 && (
                                                        false

                                                        // !dirtyFields.schedule.day ||
                                                        // !dirtyFields.schedule?.days ||
                                                        // !dirtyFields.schedule.totalDayCount ||
                                                        // !dirtyFields.schedule.endDate ||

                                                        // !dirtyFields.schedule?.startDate ||
                                                        // !dirtyFields.schedule.startTime ||
                                                        // !dirtyFields.schedule.endTime ||
                                                        // values?.schedule?.days?.length === 0
                                                    ))
                                                )
                                            }
                                            variant="contained"
                                            onClick={() => {
                                                if (activeStep !== 4) {
                                                    handleNext()
                                                } else {
                                                    onSubmit()
                                                }
                                            }}
                                        >
                                            {activeStep !== 4 ? 'İleri' : 'Kaydet'}
                                        </LoadingButton>
                                    </Box>
                                </Stack>
                            </FormProvider>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <CreateActivityCard values={values} />

                        {values?.schedule?.days?.length ? (
                            <Stack sx={{ mt: 5 }} spacing={1}>
                                {values?.schedule?.days?.map((item, index) => {
                                    return (
                                        <Card
                                            key={index}
                                            sx={{
                                                p: 2,
                                                boxShadow: '1px 1px 10px 0px #ccc',
                                            }}
                                        >
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    spacing={2}
                                                >
                                                    <Typography>
                                                        {new Intl.DateTimeFormat('tr', {
                                                            dateStyle: 'medium',
                                                        }).format(new Date(item?.date))}
                                                    </Typography>
                                                    <Typography>
                                                        {' '}
                                                        {timeMapping.find((timeItem) => timeItem?.value === item?.startTime)
                                                            ?.name ?? ' ... '}{' '}
                                                    </Typography>{' '}
                                                    -
                                                    <Typography>
                                                        {' '}
                                                        {timeMapping.find((timeItem) => timeItem?.value === item?.endTime)
                                                            ?.name ?? ' ... '}{' '}
                                                    </Typography>
                                                </Stack>

                                                {
                                                    activeStep !== 5 && (
                                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                            <Tooltip title="Güncelle" placement="top" arrow>
                                                                <IconButton
                                                                    color={addOrUpdateDatePopup.value ? 'inherit' : 'default'}
                                                                    onClick={() => handleEditDate(item)}
                                                                >
                                                                    <Iconify icon="tabler:edit" />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip title="sil" placement="top" arrow>
                                                                <IconButton
                                                                    color="default"
                                                                    onClick={() => handleRemoveFromDate(item?.date)}
                                                                    sx={{ color: 'error.main' }}
                                                                >
                                                                    <Iconify icon="entypo:cross" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    )
                                                }
                                            </Stack>
                                        </Card>
                                    );
                                })}
                                {activeStep !== 5 ? (
                                    <ButtonBase
                                        onClick={handleAddDate}
                                        sx={{
                                            border: '1px dashed #ccc',
                                            borderRadius: '12px',
                                            p: 2,
                                            mt: 2,
                                            '&:active': {
                                                border: 'none',
                                                boxShadow: '1px 1px 10px 0px #ccc',
                                            },
                                            '& svg': {
                                                transform: 'scale(1.2)',
                                            },
                                            '&:active svg': {
                                                transform: 'scale(1.3)',
                                            },
                                        }}
                                    >
                                        <Iconify icon="mingcute:add-fill" />
                                    </ButtonBase>
                                ) : null}
                            </Stack>
                        ) : (
                            <Skeleton
                                height={50}
                                sx={{
                                    mt: 3,
                                }}
                            />
                        )}

                        {
                            activeStep !== 5 && (
                                <AddAndUpdateNewDatePopup
                                    selectedDate={selectedDate}
                                    open={addOrUpdateDatePopup.value}
                                    onClose={addOrUpdateDatePopup.onFalse}
                                    handleAddOrUpdateDate={handleAddOrUpdateDate}
                                />
                            )
                        }
                    </Grid>

                </Grid>

                <Dialog
                    open={isReview}
                    onClose={() => setIsReview(false)}
                    sx={{
                        '&>*>*': {
                            p: 2,
                            m: 2,
                            width: '100%',
                        },
                    }}
                >
                    <CreateActivityCard values={values} />

                    {values?.schedule?.days?.length ? (
                        <Stack sx={{ mt: 5 }} spacing={1}>
                            {values?.schedule?.days?.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        sx={{
                                            p: 2,
                                            boxShadow: '1px 1px 10px 0px #ccc',
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={2}
                                            >
                                                <Typography>
                                                    {new Intl.DateTimeFormat('tr', {
                                                        dateStyle: 'medium',
                                                    }).format(new Date(item?.date))}
                                                </Typography>
                                                <Typography>
                                                    {' '}
                                                    {timeMapping.find((timeItem) => timeItem?.value === item?.startTime)
                                                        ?.name ?? ' ... '}{' '}
                                                </Typography>{' '}
                                                -
                                                <Typography>
                                                    {' '}
                                                    {timeMapping.find((timeItem) => timeItem?.value === item?.endTime)
                                                        ?.name ?? ' ... '}{' '}
                                                </Typography>
                                            </Stack>

                                            {
                                                activeStep !== 5 && (
                                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                        <Tooltip title="Güncelle" placement="top" arrow>
                                                            <IconButton
                                                                color={addOrUpdateDatePopup.value ? 'inherit' : 'default'}
                                                                onClick={() => handleEditDate(item)}
                                                            >
                                                                <Iconify icon="tabler:edit" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="sil" placement="top" arrow>
                                                            <IconButton
                                                                color="default"
                                                                onClick={() => handleRemoveFromDate(item?.date)}
                                                                sx={{ color: 'error.main' }}
                                                            >
                                                                <Iconify icon="entypo:cross" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                )
                                            }
                                        </Stack>
                                    </Card>
                                );
                            })}
                            {activeStep !== 5 ? (
                                <ButtonBase
                                    onClick={handleAddDate}
                                    sx={{
                                        border: '1px dashed #ccc',
                                        borderRadius: '12px',
                                        p: 2,
                                        mt: 2,
                                        '&:active': {
                                            border: 'none',
                                            boxShadow: '1px 1px 10px 0px #ccc',
                                        },
                                        '& svg': {
                                            transform: 'scale(1.2)',
                                        },
                                        '&:active svg': {
                                            transform: 'scale(1.3)',
                                        },
                                    }}
                                >
                                    <Iconify icon="mingcute:add-fill" />
                                </ButtonBase>
                            ) : null}
                        </Stack>
                    ) : (
                        <Skeleton
                            height={50}
                            sx={{
                                mt: 3,
                            }}
                        />
                    )}

                    {
                        activeStep !== 5 && (
                            <AddAndUpdateNewDatePopup
                                selectedDate={selectedDate}
                                open={addOrUpdateDatePopup.value}
                                onClose={addOrUpdateDatePopup.onFalse}
                                handleAddOrUpdateDate={handleAddOrUpdateDate}
                            />
                        )
                    }
                </Dialog>

            </Container>

        </>

    );
};

const AddAndUpdateNewDatePopup = ({
    selectedDate,
    open,
    other,
    onClose,
    handleAddOrUpdateDate,
}) => {
    const NewActivitySchema = Yup.object().shape({
        date: null,
        day: null,
        startTime: null,
        endTime: null,
    });

    const defaultValues = useMemo(
        () => ({
            date: selectedDate?.date ? new Date(selectedDate?.date) : null,
            day: selectedDate?.day ?? '',
            startTime: selectedDate?.startTime ?? '',
            endTime: selectedDate?.endTime ?? '',
        }),
        [selectedDate]
    );

    const methods = useForm({
        resolver: yupResolver(NewActivitySchema),
        defaultValues,
        mode: 'all',
    });

    const { watch, handleSubmit, setValue, control, reset } = methods;

    const values = watch();

    useEffect(() => {
        if (values.date) {
            setValue('day', new Date(values?.date).getDay());
        }
    }, [setValue, values.date]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset, selectedDate]);

    const onSubmit = (data) => {
        handleAddOrUpdateDate(data);
    };

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{ pb: 2 }}>{selectedDate ? 'Güncelle' : 'Ekle'}</DialogTitle>

                <DialogContent sx={{ typography: 'body2' }}>
                    <Grid container spacing={3} sx={{ pt: 2 }}>
                        <Grid item xs={12}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        className="date"
                                        label="Aktivite tarihi"
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="startTime" label="Başlama saati">
                                {timeMapping.map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="endTime" label="Bitiş saati">
                                {timeMapping.map((status) => (
                                    <MenuItem
                                        key={status?.value}
                                        value={status?.value}
                                        sx={{ textTransform: 'capitalize' }}
                                    >
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Ekle
                    </Button>

                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        iptal
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};


export default EditActivity;
