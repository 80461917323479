export function paramCase(str) {
  return str
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str) {
  return str
    ?.toLowerCase()
    ?.replace(/\s+/g, '_')
    ?.replace(/[^a-z0-9_]/g, '');
}
