const approveLocation = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_approveLocation($locationId: String!) {
      moderator_approveLocation(locationId: $locationId) {
        _id
      }
    }
            `,
    variables: {
      "locationId": locationId
    }

  })

  return data

}


const rejectLocation = ({ locationId, rejectionDetail }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_rejectLocation($locationId: String!, $rejectionInput: RejectionInput!) {
      moderator_rejectLocation(locationId: $locationId, rejectionInput: $rejectionInput) {
        _id
      }
    }
            `,
    variables: {
      "locationId": locationId,
      "rejectionInput": {
        "rejectionDetail": rejectionDetail
      }
    }
  })

  return data

}


const createLocation = ({ values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_createLocation($locationInput: LocationByModeratorInput!) {
      moderator_createLocation(locationInput: $locationInput) {
        _id
      }
    }
            `,
    variables: {
      "locationInput": {
        "address": {
          "description": values?.description ?? null,
          "title": values?.title ?? null,
        },
        "city": values?.city ?? null,
        "district": values?.district ?? null,
        // "country": values?.country ?? null,

        "lat": Number(values?.lat) ?? null,
        "long": Number(values?.long) ?? null,
        "parking": values?.parking ?? null
      }
    }
  })

  return data

}


const removeLocation = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeLocation($locationId: String!) {
      moderator_removeLocation(locationId: $locationId) {
        success
      }
    }
            `,
    variables: {
      "locationId": locationId
    }
  })

  return data

}


const updateModeratorLocation = ({ locationId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateLocation($locationId: String!, $updateInput: UpdateLocationByModeratorInput) {
      moderator_updateLocation(locationId: $locationId, updateInput: $updateInput) {
        success
      }
    }
            `,
    variables: {
      "locationId": locationId,
      "updateInput": {
        "address": {
          "description": values?.description ?? null,
          "title": values?.title ?? null,
        },
        "city": values?.city ?? null,
        "lat": values?.lat ?? null,
        "long": values?.long ?? null,
        "parking": values?.parking ?? null
      }
    }

  })

  return data

}


const updateOrganizatorLocation = () => {

  const data = JSON.stringify({
    query: `
    
            `,
    variables: {

    }
  })

  return data

}


export const locationsForModerator = {
  approveLocation,
  rejectLocation,
  createLocation,
  removeLocation,
  updateModeratorLocation,
  updateOrganizatorLocation,
}