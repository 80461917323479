import PropTypes from 'prop-types';
import { Avatar, TableRow, TableCell, ListItemText, Button } from '@mui/material';

import Label from 'src/components/label';
import { useRouter } from 'src/routes/hook';
import { activityStatus } from 'src/hophop/utils/_enums';
// import { VisibilityRounded } from '@mui/icons-material';

export default function LocationTableRow({ row, selected }) {

  const router = useRouter()

  return (
    <TableRow hover selected={selected}>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt="{name}" src={row?.listing?.coverImage} sx={{ mr: 2 }} />

        <ListItemText
          primary={row?.listing?.title}
          secondary={`
          ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(row?.schedule?.startDate) - 1)} 
          ${row?.schedule?.startDate !== row?.schedule?.endDate ? ` -  ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(row?.schedule?.endDate) - 1)}` : ""}`}

          primaryTypographyProps={{ typography: 'body2', textTransform: 'capitalize' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled', fontSize: 12, textTransform: 'capitalize' }}
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.location?.address?.title}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', }}>
        <Label
          variant="soft"
          color={
            (row?.participantCount === null && 'default') ||
            (row?.participantCount === 0 && 'default') ||
            (row?.participantCount === row?.quota && 'success') ||
            'warning'
          }
        >
          {row?.participantCount ?? 0}/{row?.quota}

        </Label>
      </TableCell>

      <TableCell>
        <Label
          variant="soft"
          color={
            (row?.status === 'active' && 'success') ||
            (row?.status === 'passive' && 'warning') ||
            (row?.status === 'nonexist' && 'error') ||
            'default'
          }
        >
          {activityStatus.find(item => item.value === row?.status).name}
        </Label>
      </TableCell>

      <TableCell>
        <Button
          disabled={!row?.participantCount}
          variant='contained' onClick={() => router.push(`/rezervasyonlar/${row?._id}`)}
        >
          Görüntüle
        </Button>
      </TableCell>

    </TableRow>
  );
}

LocationTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
