import { Box } from "@mui/material"
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from "react"

import Iconify from "src/components/iconify/iconify"


const MyMiniSlider = ({ list = [1], renderItem, containerStyle, autoStart, itemContainerStyle }) => {

    const [currentIndex, setCurrentIndex] = useState(0)

    const infiniteSctoll = useCallback(() => {
        if (autoStart) {
            if (currentIndex === list.length - 1) {
                return setCurrentIndex(0)
            }
            return setCurrentIndex(currentIndex + 1)
        }
        return null

    }, [autoStart, currentIndex, list.length])

    useEffect(() => {
        const infiniteSctollInterval = setInterval(() => { infiniteSctoll() }, 2000)
        return () => {
            clearInterval(infiniteSctollInterval)
        }
    }, [infiniteSctoll])

    const handleNextPrev = (num) => {

        if (currentIndex === 0 && num === -1) return setCurrentIndex(list.length - 1)
        if (currentIndex === list.length - 1 && num === 1) return setCurrentIndex(0)
        return setCurrentIndex(currentIndex + num)

    }

    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(null);

    const handleDragStart = (e) => {
        setDragging(true);
        setStartX(e.clientX);
    };

    const handleDragEnd = (e) => {
        setDragging(false);
        setStartX(null);

        const offsetX = e.clientX - startX;
        if (offsetX > -50 && offsetX < 50) return
        const newIndex = currentIndex - Math.sign(offsetX);

        if (newIndex > 0 && newIndex < list.length) {
            setCurrentIndex(newIndex);
        } else if (newIndex === 0) {
            setCurrentIndex(list.length - 1)
        } else {
            setCurrentIndex(0)
        }
    };

    const handleDragMove = (e) => {

        if (!dragging) return;

        const offsetX = e.clientX - startX;
        if (offsetX > -50 && offsetX < 50) return
        const newIndex = currentIndex - Math.sign(offsetX);

        if (newIndex > 0 && newIndex < list.length) {
            setCurrentIndex(newIndex);
        } else if (newIndex === 0) {
            setCurrentIndex(list.length - 1)
        } else {
            setCurrentIndex(0)
        }

        setStartX(e.clientX);
    };


    return (
        <Box

            className="custom-carousel"
            // onMouseDown={handleDragStart}
            // onMouseUp={handleDragEnd}
            // onMouseMove={handleDragMove}

            sx={{
                display: 'flex',
                bgcolor: '#ccc',
                height: '100%',

                flexWrap: 'nowrap',
                overflow: 'hidden',
                position: 'relative',
                borderRadius: 2,
                ...containerStyle
            }}
        >
            {
                list?.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            height: '100%',
                            minWidth: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: '1s cubic-bezier(0.39, 0.575, 0.565, 1)',
                            transform: `translateX(-${currentIndex * 100}%)`,

                            // transform: (
                            //     // eslint-disable-next-line no-nested-ternary
                            //     index === currentIndex
                            //         ? "translateX(0%)"
                            //         : index === (currentIndex + 1)
                            //             ? "translateX(-100%)"
                            //             : "translateX(100%)"

                            // )

                            ...itemContainerStyle

                        }}
                    >
                        {renderItem(item)}
                        {/* {index} */}
                    </Box>
                ))
            }

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                    right: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                {
                    list.map((item, index) => (
                        <Box key={index}
                            onClick={() => setCurrentIndex(index)}
                            sx={{
                                bgcolor: currentIndex === index ? '#fff' : '#ccc',
                                p: '5px',
                                borderRadius: '50px',
                                cursor: 'pointer'
                            }}
                        />
                    ))
                }
            </Box>



            <Box
                onClick={() => handleNextPrev(-1)}
                sx={{
                    position: 'absolute',
                    top: 10,
                    bottom: 10,
                    left: 10,
                    // right: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    gap: 2,

                    // bgcolor: '#ccc',
                    p: '5px',
                    borderRadius: '50px',
                    cursor: 'pointer'
                }}
            >
                <Iconify icon="ep:arrow-left-bold" sx={{ color: 'primary.main' }} />
            </Box>

            <Box
                onClick={() => handleNextPrev(1)}
                sx={{
                    position: 'absolute',
                    top: 10,
                    bottom: 10,
                    // left: 10,
                    right: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',

                    // bgcolor: '#ccc',
                    p: '5px',
                    borderRadius: '50px',
                    cursor: 'pointer'
                }}
            >
                <Iconify icon="ep:arrow-right-bold" sx={{ color: 'primary.main' }} />
            </Box>


        </Box>
    )
}

MyMiniSlider.propTypes = {
    list: PropTypes.array,
    renderItem: PropTypes.func,
    containerStyle: PropTypes.object,
    itemContainerStyle: PropTypes.object,
    autoStart: PropTypes.bool
}

export default MyMiniSlider