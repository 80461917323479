/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Pagination, Typography, paginationClasses } from '@mui/material';

import { reduxFunc } from 'src/hophop/redux';
import { useSettingsContext } from 'src/components/settings';
import { PostItemSkeleton } from 'src/sections/blog/post-skeleton';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CommentCard from './_CommentCard';

const ListingComments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const { accessToken } = useSelector((state) => state.auth);
  const { selectedListingForModerator, loading } = useSelector((state) => state.listing);
  const [list, setList] = useState([]);
  const [variables, setVariables] = useState({
    filter: {
      ageInterval: null,
      category: null,
      companionship: null,
      createdByCurrentUser: null,
      description: null,
      format: null,
      status: null,
      title: null,
      type: null,
    },
    pagination: {
      page: 1,
      pageSize: 6,
    },
  });

  useEffect(() => {
    if (accessToken) {
      dispatch(
        reduxFunc.listingFunc.fetchSelectedListingForModeratorFunc({ accessToken, listingId: id })
      );
    }
  }, [accessToken, dispatch, id, variables]);

  useEffect(() => {
    setList(selectedListingForModerator?.reviews);
  }, [selectedListingForModerator?.reviews]);

  const handlePagination = (event, value) => {

    setVariables((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: value,
      },
    }));
  };

  const renderSkeleton = (
    <>
      {[...Array(10)].map((_, index) => (
        <PostItemSkeleton key={index} variant="horizontal" />
      ))}
    </>
  );

  const renderList = (
    <>
      {list?.data?.length ? (
        list?.data?.map((comment, index) => (
          <CommentCard
            key={comment?._id ?? index}
            comment={comment}
          // handleDelete={handleDelete}
          />
        ))
      ) : (
        <Typography>Hiçbir yorum bulunamadı!</Typography>
      )}
    </>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={`${selectedListingForModerator?.title} Yorumları`}
        links={[
          { name: 'Yorumlar', href: '/yorumlar' },
          { name: selectedListingForModerator?.title, href: 'yorumlar' },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
      >
        {loading ? renderSkeleton : renderList}
      </Box>

      {list?.count > variables.pagination.pageSize && (
        <Pagination
          onChange={handlePagination}
          onClick={(e) => null}
          count={Math.ceil(list?.count / variables.pagination.pageSize)}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </Container>
  );
};

export default ListingComments;
