import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Skeleton, Button } from '@mui/material';

import Iconify from 'src/components/iconify';
import { reduxFunc } from 'src/hophop/redux';
import { fCurrency } from 'src/utils/format-number';
import { useDispatch, useSelector } from 'react-redux';
import { MyMiniSlider } from 'src/hophop/components';

// import SimpleImageSlider from "react-simple-image-slider";

export default function CreateActivityCard({ values, onView, onEdit, onDelete }) {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);
  const { selectedListingForModerator } = useSelector((state) => state.listing);
  const { locations } = useSelector((state) => state.parameter);

  // useEffect(() => {
  //   // dispatch(reduxFunc.parameterFunc.fetchCreateActivityParametersForModeratorFunc({ accessToken }));
  // }, [accessToken, dispatch])

  useEffect(() => {
    if (values.listingId) {
      dispatch(
        reduxFunc.listingFunc.fetchSelectedListingForModeratorFunc({
          accessToken,
          listingId: values?.listingId,
        })
      );

    }

  }, [accessToken, dispatch, values.listingId]);

  useEffect(() => {
    if (values.locationId) {
      dispatch(
        reduxFunc.locationFunc.fetchSelectedlocationForModeratorFunc({
          accessToken,
          locationId: values?.locationId,
        })
      )
    }

  }, [accessToken, dispatch, values.locationId]);

  const renderPrice = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        borderRadius: 1,
        bgcolor: 'grey.800',
        position: 'absolute',
        p: '2px 6px 2px 4px',
        color: 'common.white',
        typography: 'subtitle2',
      }}
    >
      {!!values?.priceSale && (
        <Box component="span" sx={{ color: 'grey.500', mr: 0.25, textDecoration: 'line-through' }}>
          {fCurrency(values?.priceSale)}
        </Box>
      )}
      {fCurrency(values?.price)}
    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
        mb: 2,
        position: 'relative',
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
        {values?.price && renderPrice}

        <MyMiniSlider
          list={[
            selectedListingForModerator?.coverImage,
            ...(selectedListingForModerator?.images ?? []),
          ]}
          renderItem={(item) => (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url('${item}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
          containerStyle={{
            height: 250,
          }}
        />
      </Stack>
    </Stack>
  );

  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(2.5, 2.5, 2.5, 2.5),
      }}
    >
      {/* <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 20, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton> */}

      {[
        {
          label: values?.title,
          icon: <Iconify icon="ci:main-component" sx={{ color: 'primary.main' }} />,
        },
        {
          // label: locations?.find(item => item?._id === values?.locationId)?.address?.title,
          label:
            locations?.find((item) => item?._id === values?.locationId)?.address?.title ??
            (selectedListingForModerator?.format &&
              selectedListingForModerator?.format !== 'faceToFace' &&
              ''),

          icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
        },
        {
          label: values?.duration ? `${values?.duration} dk` : '',
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: values?.quota ? `${values?.quota} Kişi (Kişi)` : '',
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
        },
        {
          label:
            values?.hoursBeforeReservationExpires !== ''
              ? `Son başvuru ${values?.hoursBeforeReservationExpires} saat önce`
              : '',
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: values?.fee ? `${values?.price} TL` : '',
          icon: <Iconify icon="ph:money" sx={{ color: 'primary.main' }} />,
        },
        {
          label: values?.additionalDetails ? `${values?.additionalDetails}` : '',
          icon: <Iconify icon="fluent:text-description-20-filled" sx={{ color: 'primary.main' }} />,
        },
      ].map((item, i) => (
        <Stack
          key={`${item?.label}-${i}`}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          {item.label ? (
            <>
              {item.icon}
              {item.label}
            </>
          ) : (
            <Skeleton width={200} height={10} />
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Card sx={{ boxShadow: '1px 1px 10px 0px #ccc', minHeight: 550 }}>
      {renderImages}
      {renderInfo}
    </Card>
  );
}

CreateActivityCard.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  values: PropTypes.object,
};
