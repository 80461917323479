import { activitiesForModerator } from "./_activitiesForModerator"
import { activitiesForOrganizer } from "./_activitiesForOrganizer"
import { listingsForModerator } from "./_listingsForModerator"
import { listingsForOrganizer } from "./_listingsForOrganizer"
import { locationsForModerator } from "./_locationsForModerator"
import { locationsForOrganizer } from "./_locationsForOrganizer"
import { blogsForModerator } from "./_blogsForModerator"

import { reservationsForModerator } from "./_reservationsForModerator"
import { reservationsForOrganizer } from "./_reservationsForOrganizer"
import { messagesForModerator } from './_messagesForModerator'
import { messagesForOrganizer } from './_messagesForOrganizer'


import parameters from './_parameters'
import { customersForModerator } from "./_customersForModerator"
import { customersForOrganizer } from "./_customersForOrganizer"

export const queries = {
    activitiesForModerator,
    activitiesForOrganizer,
    listingsForModerator,
    listingsForOrganizer,
    locationsForModerator,
    locationsForOrganizer,

    reservationsForModerator,
    reservationsForOrganizer,

    messagesForModerator,
    messagesForOrganizer,

    customersForModerator,
    customersForOrganizer,

    blogsForModerator,
    parameters,
}