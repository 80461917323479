const conservations = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_activityConversations($pagination: Pagination, $userActivityConversationsPagination2: Pagination) {


      moderator_activityConversations(pagination: $pagination) {
        count
        data {
          activity {
            activityId {
              _id
            }
            coverImage
            createdBy {
              _id
            }
            exteriorOrganizer {
              _id
              name
              imageUrl
              details
              createdAt
              updatedAt
            }
            title
          }
          lastMessage {
            _id
            sender {
              _id
            }
            receiver {
              _id
            }
            activity {
              _id
            }
            content
            attachments
            senderHasSend
            receiverHasReceived
            receiverHasRead
            createdAt
            updatedAt
          }
          receiver {
            _id
            description
            displayImageUrl
            displayName
            role
          }
        }
      }
      user_activityConversations(pagination: $userActivityConversationsPagination2) {
        count
        data {
          activity {
            activityId {
              _id
            }
            coverImage
            createdBy {
              _id
            }
            exteriorOrganizer {
              _id
              name
              imageUrl
              details
              createdAt
              updatedAt
            }
            title
          }
          lastMessage {
            _id
            sender {
              _id
            }
            receiver {
              _id
            }
            activity {
              _id
            }
            content
            attachments
            senderHasSend
            receiverHasReceived
            receiverHasRead
            createdAt
            updatedAt
          }
          receiver {
            _id
            displayName
            displayImageUrl
            description
            role
          }
        }
      }
    }
        `,
    variables: {
      "pagination": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize
      },
      "userActivityConversationsPagination2": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize,
        "sort": null
      }
    }
  })

  return data

}

const selectedUserMessages = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query User_messages($receiverId: String!, $activityId: String!, $filter: FilterMessageInput, $pagination: Pagination) {

      user_messages(receiverId: $receiverId, activityId: $activityId, filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          activity {
            _id
          }
          attachments
          content
          createdAt
          receiver {
            displayImageUrl
            displayName
          }
          receiverHasRead
          receiverHasReceived
          sender {
            _id
            description
            displayImageUrl
            displayName
          }
          senderHasSend
          updatedAt
        }
      }


        moderator_moderatorMessages(receiverId: $receiverId, activityId: $activityId, filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            sender {
              role
              displayName
              displayImageUrl
              description
              _id
            }
            receiver {
              _id
              displayName
              displayImageUrl
              description
              role
            }
            activity {
              _id
            }
            content
            attachments
            senderHasSend
            receiverHasReceived
            receiverHasRead
            createdAt
            updatedAt
          }
        }
      
      

    }
    `,
    variables: {
      "receiverId": variables?.receiverId,
      "activityId": variables?.activityId,
      "filter": {
        "dateInterval": {
          "from": null,
          "to": null
        }
      },
      "pagination": {
        "page": null,
        "pageSize": null
      }
    }
  })

  return data

}

export const messagesForModerator = {
  conservations,
  selectedUserMessages
}