/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Chip, Grid, MenuItem, Stack, Typography } from '@mui/material';

import { reduxFunc } from 'src/hophop/redux';
import { RHFAutocomplete, RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import { ageIntervalsForListing, companionshipsForListing, formatsForListing } from 'src/hophop/utils/_enums';


const FormTwo = ({ setValue, values }) => {

    const dispatch = useDispatch()
    const [isAddNew, setIsAddNew] = useState(JSON.parse(sessionStorage.getItem("organizerCreateActivityListingAddNew")))
    const { accessToken } = useSelector(state => state.auth)
    const { categories, listings } = useSelector(state => state.parameter)
    const { selectedListingForOrganizer } = useSelector(state => state.listing)
    const { selectedActivityForOrganizer } = useSelector((state) => state?.activity);


    useEffect(() => {
        if (listings?.length === 0) {
            setIsAddNew(true)
            sessionStorage.setItem("organizerCreateActivityListingAddNew", true)
        }

        return (() => {
            dispatch(reduxFunc.activityFunc.resetSelectedActivity())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedActivityForOrganizer) {
            setIsAddNew(true)
            sessionStorage.setItem("organizerCreateActivityListingAddNew", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedActivityForOrganizer])


    useEffect(() => {
        if (isAddNew) {
            setValue("listingId", "")
        } else {
            setValue("listingId", selectedListingForOrganizer?._id)
        }
    }, [isAddNew, selectedListingForOrganizer?._id, setValue])


    useEffect(() => {
        if (values?.listingId) {
            dispatch(reduxFunc.listingFunc.fetchSelectedListingForOrganizerFunc({
                accessToken,
                listingId: values?.listingId
            }))
        }
    }, [accessToken, dispatch, values?.listingId])


    useEffect(() => {

        if (selectedListingForOrganizer) {

            setValue("listing.ageInterval", selectedListingForOrganizer?.ageInterval
                ? selectedListingForOrganizer?.ageInterval?.map((item) =>
                    ageIntervalsForListing.find((age) => age?.value === item)
                )
                : [])
            setValue("listing.companionship", selectedListingForOrganizer?.companionship)
            setValue("listing.coverImage", selectedListingForOrganizer?.coverImage)
            setValue("listing.description", selectedListingForOrganizer?.description)
            setValue("listing.format", selectedListingForOrganizer?.listing?.format)
            setValue("listing.images", selectedListingForOrganizer?.images)
            // setValue("listing.subCategory", selectedListingForOrganizer?.subCategory)
            setValue("listing.title", selectedListingForOrganizer?.title)
            setValue("listing.category", selectedListingForOrganizer?.category)

            if (!isAddNew) {
                setValue("listingId", selectedListingForOrganizer?._id)
            }

        }

    }, [isAddNew, selectedListingForOrganizer, setValue])


    const isFaceToFace = (
        values?.listing?.category === "Atölyeler" ||
        values?.listing?.category === "Kurslar"
    ) && true

    // useEffect(() => {
    //     if (isFaceToFace) {
    //         setValue("listing.format", "")
    //     } else {
    //         setValue("listing.format", "faceToFace")
    //     }

    // }, [setValue, isFaceToFace, values?.listing?.category])





    const handleDropCoverImage = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('listing.coverImage', newFile, { shouldValidate: true, shouldDirty: true });
            }
        },
        [setValue]
    );

    const handleRemoveFileCoverImage = useCallback(() => {
        setValue('listing.coverImage', "");
    }, [setValue]);




    const handleDropImages = useCallback(
        (acceptedFiles) => {
            const files = values?.listing?.images || [];

            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

            setValue('listing.images', [...files, ...newFiles], { shouldValidate: true, shouldDirty: true });
        },
        [setValue, values?.listing?.images]
    );

    const handleRemoveFileImages = useCallback(
        (inputFile) => {
            if (isAddNew) {
                const filtered = values?.listing?.images && values?.listing.images?.filter((file) => file !== inputFile);
                setValue('listing.images', filtered ?? []);
            }
        },
        [isAddNew, setValue, values?.listing.images]
    );

    const handleRemoveAllFilesImages = useCallback(() => {
        setValue('listing.images', []);
    }, [setValue]);



    console.log(selectedListingForOrganizer, "selectedListingForOrganizer?.format")

    return (

        <>
            {
                // (isAddNew || listings?.length === 0) 
                (isAddNew || values?.listingId)
                && (
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6} >
                            <RHFTextField name="listing.title" label="Başlık" disabled={!isAddNew} />
                        </Grid>


                        <Grid item xs={12} md={6} >
                            <RHFSelect name="listing.category" label="Kategori" disabled={!isAddNew}>
                                <MenuItem value="" sx={{ textTransform: 'capitalize' }}>
                                    {/*  */}
                                </MenuItem>
                                {categories?.data?.map((status, i) => (
                                    <MenuItem key={i} value={status?._id ?? values?.listing?.category} sx={{ textTransform: 'capitalize' }}>
                                        {status?._id?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>


                        <Grid item xs={12} md={6} >
                            <RHFSelect name="listing.companionship" label="Veli katılımı" disabled={!isAddNew}>
                                {companionshipsForListing.map((status, i) => (
                                    <MenuItem key={`${status?.value}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <RHFSelect name="listing.format" label="Format" disabled={!isAddNew || !isFaceToFace}>
                                {formatsForListing.map((status, i) => (
                                    <MenuItem key={`${status?.value}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} >
                            <RHFAutocomplete
                                disabled={!isAddNew}
                                name="listing.ageInterval"
                                placeholder="Yaş Aralığı"
                                multiple
                                disableCloseOnSelect
                                options={ageIntervalsForListing}
                                getOptionLabel={(option) => option?.value?.toString()}
                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.value?.toString()}>
                                        {option?.name}
                                    </li>
                                )}
                                renderTags={(selected, getTagProps) =>

                                    selected?.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            key={option?.value?.toString()}
                                            label={option?.name}
                                            size="small"
                                            color="info"
                                            variant="soft"
                                        />
                                    ))

                                }

                            />
                        </Grid>


                        <Grid item xs={12}  >
                            <RHFTextField multiline rows={4} name="listing.description" label="Açıklama" disabled={!isAddNew} />
                        </Grid>


                        <Grid item xs={12}  >
                            <RHFUpload
                                disabled={!isAddNew}
                                text1="Kapak Resmi"
                                name="listing.coverImage"
                                actionButtons={<div>dslf</div>}
                                maxSize={3145728}
                                onDrop={handleDropCoverImage}
                                onDelete={handleRemoveFileCoverImage}
                            />
                        </Grid>

                        <Grid item xs={12}  >
                            <Stack spacing={1.5}>
                                <Typography variant="subtitle2">Resimler</Typography>
                                <RHFUpload
                                    disabled={!isAddNew}
                                    multiple
                                    thumbnail
                                    text1="Aktivite Resimleri"
                                    name="listing.images"
                                    maxSize={3145728}
                                    onDrop={handleDropImages}
                                    onRemove={handleRemoveFileImages}
                                    onRemoveAll={handleRemoveAllFilesImages}
                                    onUpload={() => console.info('ON UPLOAD')}
                                />
                            </Stack>
                        </Grid>

                    </Grid>
                )
            }
        </>

    );
};

export default FormTwo