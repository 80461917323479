import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Pagination, Tab, Tabs, Typography, paginationClasses } from '@mui/material';

import { useRouter } from 'src/routes/hook';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux'
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify/iconify';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ActivityCard from './_ActivityCard';


const Activities = () => {

    const router = useRouter();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const [currentTab, setCurrentTab] = useState('current-activities');
    const { activitiesForOrganizer: activities } = useSelector(state => state.activity)
    const [variables, setVariables] = useState({
        filter: {
            additionalDetails: null,
            createdByCurrentUser: null,
            duration: {
                from: null,
                to: null,
            },
            fee: null,
            listing: {
                title: null,
                format: null,
                description: null,
                companionship: null,
                category: null,
                ageInterval: null,
            },
            location: {
                parking: null,
                district: null,
                city: null,
                byCoordinates: {
                    lat: null,
                    long: null,
                    radius: null,
                },
            },
            price: {
                from: null,
                to: null,
            },
            quota: {
                from: null,
                to: null,
            },
            schedule: {
                finished: false,
                dateInterval: {
                    from: null,
                    to: null,
                },
                days: null,
            },
        },
        pagination: {
            page: 1,
            pageSize: 6,
        },
    });


    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.activityFunc.fetchActivitiesForOrganizatorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])



    const handleEdit = useCallback((id) => {
        router.push(`/aktiviteler/edit/${id}`);
    }, [router]);

    const handleDelete = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForOrganizer.removeActivity({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })


            if (response?.data?.data?.organizer_removeActivity?.success) {
                enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForOrganizatorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite silinemedi!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.error({ error })
        }
    }, [accessToken, dispatch, handleFetchData, variables]);

    const handleMakePassive = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!') 
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForOrganizer.makePassive({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })


            if (response?.data?.data?.moderator_setActivityAsPassive?.success) {
                enqueueSnackbar('Başarılı bir şekilde pasif yapılmıştır!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForOrganizatorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite silinemedi!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.error({ error })
        }
    }, [accessToken, dispatch, handleFetchData, variables]);

    const handleMakeActive = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForOrganizer.makeActive({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })


            if (response?.data?.data?.moderator_setActivityAsActive?.success) {
                enqueueSnackbar('Başarılı bir şekilde aktif edilmiştir!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForOrganizatorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite aktif yapılamadı, tekrar düzenleyiniz!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.error({ error })
        }
    }, [accessToken, dispatch, handleFetchData, variables]);


    const handlePagination = (event, value) => {

        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    };

    const handleFilterToDate = (value) => {
        switch (value) {
            case 'current-activities':
                return setVariables((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        schedule: {
                            ...prevState.filter.schedule,
                            finished: false,
                        },
                    },
                }));

            case 'passed-activities':
                return setVariables((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        schedule: {
                            ...prevState.filter.schedule,
                            finished: true
                        },
                    },
                }));

            default:
                return setVariables((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        schedule: {
                            ...prevState.filter.schedule,
                            finished: false
                        },
                    },
                }));
        }
    };

    const TABS = [
        {
            value: 'current-activities',
            label: 'Güncel Aktiviteler',
            icon: <Iconify icon="lucide:zap" />,
            onClick: handleFilterToDate,
        },
        {
            value: 'passed-activities',
            label: 'Geçmiş Aktiviteler',
            icon: <Iconify icon="lucide:zap-off" />,
            onClick: handleFilterToDate,
        },
    ];


    return (
        <>

            <Helmet>
                <title>Aktivitelerim</title>
            </Helmet>

            <Container maxWidth={settings.themeStretch ? false : 'lg'}>

                <CustomBreadcrumbs
                    heading="Aktivitelerim"
                    action={
                        <Button
                            component={RouterLink}
                            href="/aktiviteler/olustur"
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}
                        >
                            Yeni Aktivite
                        </Button>
                    }
                    sx={{
                        my: { xs: 3, md: 5 },
                    }}
                />

                <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    sx={{ mb: 5 }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            icon={tab.icon}
                            value={tab.value}
                            onClick={() => handleFilterToDate(tab.value)}
                        />
                    ))}
                </Tabs>

                {
                    activities?.count ? (
                        <Box
                            gap={3}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(3, 1fr)',
                            }}
                        >
                            {
                                (activities?.data ?? []).map((item, index) => (
                                    <ActivityCard
                                        key={index}
                                        tour={item}
                                        // onView={() => handleView(item._id)}
                                        onEdit={() => handleEdit(item._id)}
                                        onDelete={() => handleDelete(item._id)}
                                        onMakeActive={() => handleMakeActive(item._id)}
                                        onMakePassive={() => handleMakePassive(item._id)}
                                    />
                                ))
                            }
                        </Box>
                    ) : (
                        <Typography>Hiçbir aktivite bulunamadı!</Typography>
                    )
                }

                {
                    activities?.count > variables.pagination.pageSize && (
                        <Pagination
                            onChange={handlePagination}
                            onClick={(e) => null}
                            count={Math.ceil((activities?.count ?? 0) / variables.pagination.pageSize)}
                            sx={{
                                mt: 8,
                                [`& .${paginationClasses.ul}`]: {
                                    justifyContent: 'center',
                                },
                            }}
                        />
                    )
                }
            </Container>

        </>

    );
}

export default Activities