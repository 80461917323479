/* eslint-disable arrow-body-style */
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';


const LastPage = () => {

    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',

                flexDirection: 'column',
            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 5,
                }}
            >
                <ThumbUpRoundedIcon
                    sx={{
                        width: '100px',
                        height: '100px',
                        color: (theme) => theme.palette.primary.main,
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 40,
                    fontFamily: 'sen !important',
                    color: (theme) => theme.palette.primary.main,
                }}
            >
                Başarılı bir şekilde oluşturulmuştur.
            </Typography>
        </Box>
    );
};

export default LastPage