const allCustomers = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_customers($filter: FilterCustomersInput, $pagination: Pagination) {
      organizer_customers(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          activities {
            _id
            listing {
              title
            }
            schedule {
              startDate
              endDate
            }
            location {
              address {
                title
                description
              }
            }
          }
          description
          displayImageUrl
          displayName
          role
        }
      }
    }
        `,
    variables: {
      "filter": {
        "activityId": null
      },
      "pagination": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize
      }
    }


  })

  return data

}

export const customersForOrganizer = {
  allCustomers,
}