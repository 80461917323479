import PropTypes from 'prop-types';

import React from 'react'
import {
    Card, IconButton, Table, TableBody, TableContainer, Tooltip,
    Avatar, TableRow, TableCell, ListItemText, Stack, Rating,
} from '@mui/material'

import Label from 'src/components/label/label';
import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify/iconify'
import { activityStatus } from 'src/hophop/utils/_enums';
import Scrollbar from 'src/components/scrollbar/scrollbar'
import { TableHeadCustom, TableNoData, TableSelectedAction, useTable } from 'src/components/table'


const TABLE_HEAD = [
    { id: 'name', label: 'Son Aktiviteler' },
    { id: 'createdBy', label: 'Ücret' },
    { id: 'owner', label: 'Kontenjan' },
    { id: 'date', label: 'Durumu' },
];


const ActivitiesTable = ({ list = null }) => {

    const table = useTable();
    const confirm = useBoolean();

    return (
        <Card>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>

                <TableSelectedAction
                    dense={table.dense}
                    numSelected={table?.selected?.length}

                    rowCount={list?.length}
                    onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                            checked,
                            list?.data.map((row) => row.id)
                        )
                    }
                    action={
                        <>
                            <Tooltip title="Mesaj">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bildirim">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                />

                <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} >
                        <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={list?.data?.length}
                            numSelected={table?.selected?.length}
                            onSort={table.onSort}
                        />

                        <TableBody>
                            {
                                list?.data?.map((row) => (
                                    <PaymentTableRow
                                        key={row?._id}
                                        row={row}
                                        selected={table.selected.includes(row?._id)}
                                        onSelectRow={() => table.onSelectRow(row?._id)}
                                    />
                                ))
                            }
                            <TableNoData notFound={list?.count === 0} />
                        </TableBody>
                    </Table>
                </Scrollbar>

            </TableContainer>
        </Card>
    )
}

ActivitiesTable.propTypes = {
    list: PropTypes.object
};

export default ActivitiesTable




function PaymentTableRow({ row, selected }) {


    return (
        <TableRow hover selected={selected} >

            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={row?.title} src={row?.listing?.coverImage} sx={{ mr: 2 }} >
                    {row?.title?.slice(0, 1)}
                </Avatar>

                <ListItemText
                    primary={row?.title}
                    secondary={row?.additionalDetails?.slice(0, 30)}
                    primaryTypographyProps={{ typography: 'body2', textTransform: 'capitalize' }}
                    secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                />
            </TableCell>

            <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.price} ₺</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                <Label
                    variant="soft"
                    color={
                        (row?.participantCount === null && 'default') ||
                        (row?.participantCount === 0 && 'default') ||
                        (row?.participantCount === row?.quota && 'success') ||
                        'warning'
                    }
                >
                    {row?.participantCount ?? 0}/{row?.quota}

                </Label>
            </TableCell>

            {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{(row?.participantCount ?? 0) * (row?.price ?? 0)} ₺</TableCell> */}


            <TableCell>
                <Label
                    variant="soft"
                    color={
                        (row?.status === 'active' && 'success') ||
                        (row?.status === 'passive' && 'warning') ||
                        (row?.status === 'nonexist' && 'error') ||
                        'default'
                    }
                >
                    {activityStatus.find(item => item.value === row?.status).name}
                </Label>
            </TableCell>

            {/* <TableCell sx={{ whiteSpace: 'nowrap', }} >
                <Stack direction="row" gap={1} alignItems="center">
                    <Rating name="half-rating" defaultValue={2.5} value={row?.listing?.rating?.average ?? 0} precision={0.5} />

                    <span>
                        ({row?.listing?.rating?.numberOfReviews ?? 0})
                    </span>
                </Stack>
            </TableCell> */}

        </TableRow>
    );
}

PaymentTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
};
