import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

export default function RHFAutocomplete({ name, label, placeholder, helperText, optionLabelField, ...other }) {
  const { control, setValue, watch } = useFormContext();
  const value = watch(name) || [];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          value={value}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error.message : helperText}
            />
          )}
          getOptionLabel={(option) => option[optionLabelField] ?? ""}
          renderTags={(selected, getTagProps) => (
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option[optionLabelField] ?? ""}
                label={option[optionLabelField] ?? ""}
                size="small"
                color="info"
                variant="soft"
              />
            ))
          )}
          {...other}
        />
      )}
    />
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  optionLabelField: PropTypes.string.isRequired,
};
